.NAP_Van_pCmediaViewInfo {
    --web-view-name: nav admin portal;
    --web-view-id: nav_admin_portal;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: nav_admin_portal;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#NAP_Van_pCnav_admin_portal {
    position: fixed;
    width: 100%;
    height: 85px;
    z-index: 10;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: nav admin portal;
    --web-view-id: nav_admin_portal;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#NAP_Van_pCGroup_3 {
    position: absolute;
    width: 100%;
    height: 82.852px;
    left: 202.714px;
    background-color: cornflowerblue;
    overflow: visible;
}
#NAP_Van_pCPath_3 {
    fill: rgba(100,149,237,1);
}
.NAP_Van_pCPath_3 {
    overflow: visible;
    position: absolute;
    width: 978.106px;
    height: 93.852px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#NAP_Van_pCGroup_4 {
    position: absolute;
    width: 930px;
    height: 24.965px;
    left: 30px;
    text-align:center;
    top: 43.168px;
    overflow: visible;
}

.selectedTab_pc_dashboard {
    background-color:white;
    width:100px;
    text-align: center;
    cursor: pointer;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    transition:1s;
}

.tabs_pc_dashbaord {
    width:100px;
    text-align: center;
    color: white;
    cursor: pointer;
}

#NAP_Van_pCVacancies {
    left: 0px;
    top: 0.061px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#NAP_Van_pCInterview {
    left: 143px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#NAP_Van_pCBookmark {
    left: 283px;
    top: 0.113px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#NAP_Van_pCRounds {
    left: 430px;
    top: 0.825px;
    position: absolute;
    overflow: visible;
    width: 60px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#NAP_Van_pCReassign {
    left: 556px;
    top: 0.965px;
    position: absolute;
    overflow: visible;
    width: 70px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#NAP_Van_pCSettings {
    left: 692px;
    top: 0.825px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#NAP_Van_pCrecruiters {
    left: 823px;
    top: 0.825px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(255,255,255,1);
}
#NAP_Van_pCright_grp_setup_pc {
    position: absolute;
    width: 195.078px;
    height: 84.411px;
    right: 0px;
    top: -1px;
    background-color: white;
    overflow: visible;
}
#NAP_Van_pCright_cornersetup_pc {
    fill: rgba(100,149,237,1);
}
.NAP_Van_pCright_cornersetup_pc {
    overflow: visible;
    position: absolute;
    width: 195.078px;
    height: 84.411px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#NAP_Van_pClogout_setup_pc {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 123px;
    top: 31.411px;
    cursor: pointer;
    overflow: visible;
}
#NAP_Van_pCprofile_setup_pc {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 55px;
    top: 25.411px;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
}
#NAP_Van_pCGroup_2 {
    position: absolute;
    width: 225.078px;
    height: 85.411px;
    left: -20.362px;
    top: -2.31px;
    overflow: visible;
}
#NAP_Van_pCbizibeesjpg {
    position: absolute;
    width: 130px;
    height: 48px;
    left: 47.539px;
    top: 29.342px;
    z-index: 1;
    cursor: pointer;
    overflow: visible;
}
#NAP_Van_pCright_cornersetup_pc_b {
    fill: rgba(100,149,237,1);
}
.NAP_Van_pCright_cornersetup_pc_b {
    overflow: visible;
    position: absolute;
    width: 225.078px;
    height: 85.411px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}