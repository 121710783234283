.Box{
    position: relative;
    margin-top: 20px;
    left:10px;
    
}

.row{
    display: "flex";
}

.Box{
    background-color: white;
} 

/* h4{
    position: relative;
    left:100px;
} */



.BOX{
    position: relative;
    left:30px;
    top:25px;
}

.Box1{
    left:30%;
    bottom: 36px;
}
.Box11{
    left:30%;
     top:25px;
}
.Box16{
    left:30%;
     top:25px;
}
.Box15{
    left:52%;
     bottom:370px;
     color:white;
}
.Box2{
    left: 95%;
}

.content2{
    background-color: white;
}

.btn{
    left:0px;
}

.btn2{
    left: 60px;
}
.content3{

    background-color: rgb(240, 240, 240);
    margin: -13px 0px 10px 0px;
    position: absolute;
    width: 528px;
    height: 484px;
    left: 0%;
    bottom: -9px;
}

.content5{

    background-color: rgb(240, 240, 240);
    margin: auto;
    position: relative;
    min-width: 325px;
    max-width: 380px;
    height: auto;
}

h3{
    position: relative;
}

h2{
    position: relative;
    left:180px;
}
.fnt{
font-family: 'Times New Roman', Times, serif;
color:white;
   background-color:cornflowerblue;
   left:0px;
   padding-left:20vh;
   
}

.fnt2{
    font-family: 'Times New Roman', Times, serif;
    color:white;
       background-color:cornflowerblue;
       text-align: center;
    }

.fnt1{
    font-family: 'Times New Roman', Times, serif;
   left:0px;
   
   color:white;
   background-color:cornflowerblue;
    }

.heading{
    bottom:30px;
}

.addjob{
    float:right;
}