.mediaViewInfo {
  --web-view-name: Company wise pc;
  --web-view-id: Company_wise_pc;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: Company_wise_pc;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#Company_wise_pc {
  position: relative;
  width: 100%;
  height: auto;
  background-color: rgba(255,255,255,1);
  /* overflow-y: scroll; */
  
}
#Vacancies_Border {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Vacancies_Border {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 100%;
  height: 263px;
  left: 105px;
  top: 20px;
}
#Find_job_Pic {
  position: absolute;
  width: 319px;
  height: 226px;
  left: 12%;
  top: 56px;
  overflow: hidden;
}
#Group {
  position: absolute;
  width: 319px;
  height: 319px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#n_1_Success-1-removebg {
  position: absolute;
  width: 319px;
  height: 319px;
  left: 0px;
  bottom: 40px;
  overflow: visible;
}
#Rectangle_52 {
  fill: rgba(255,255,255,1);
}
.Rectangle_52 {
  position: absolute;
  overflow: visible;
  width: 286px;
  height: 51px;
  left: 26px;
  top: 339px;
}
#Company_wise {
  left: 1154px;
  top: 275px;
  position: absolute;
  overflow: visible;
  width: 173px;
  height: 33px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: rgba(46,39,39,1);
}
#Location_wise {
  left: 935px;
  top: 275px;
  position: absolute;
  overflow: visible;
  width: 173px;
  height: 33px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: rgba(46,39,39,1);
}
#Jobs__3_ {
  left: 162px;
  top: 448px;
  position: absolute;
  overflow: visible;
  width: 57px;
  height: 24px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#back_can {
  right: 20px;
  top: 25px;
  position: absolute;
  overflow: visible;
  width: 30px;
  height: 30px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
  cursor: pointer;
}
#Grassroots_BP0 {
  left: 127px;
  top: 420px;
  position: relative;
  overflow: visible;
  width: 133px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(16,15,15,1);
}
#Find_Your_Dream_Job {
  left: 40%;
  top: 59px;
  position: absolute;
  overflow: visible;
  width: auto;
  height: 106px;
  text-align: left;
  font-family: Times New Roman;
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  color: rgba(36,35,35,1);
}
#Rectangle_55 {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_55 {
  filter: drop-shadow(10px 10px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 190px;
  height: 165px;
  left: 302px;
  top: 341px;
}

#Jobs__7_bq {
  left: 763px;
  top: 625px;
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 22px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Allsec_br {
  left: 763px;
  top: 596px;
  position: absolute;
  overflow: visible;
  width: 49px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: rgba(16,15,15,1);
}

#Rectangle_108 {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_108 {
  filter: drop-shadow(10px 10px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 190px;
  height: 165px;
  left: 894px;
  top: 341px;
}
#Jobs__8_bv {
  left: 954px;
  top: 443px;
  position: absolute;
  overflow: visible;
  width: 52px;
  height: 24px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Five-S_bw {
  left: 952px;
  top: 414px;
  position: absolute;
  overflow: visible;
  width: 57px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: rgba(16,15,15,1);
}
#Rectangle_109 {
  opacity: 0.9;
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_109 {
  filter: drop-shadow(10px 10px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 190px;
  height: 165px;
  left: 1092px;
  top: 341px;
}
#Jobs__7_by {
  left: 1154px;
  top: 444px;
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 22px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Allsec_bz {
  left: 1154px;
  top: 415px;
  position: absolute;
  overflow: visible;
  width: 49px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: rgba(16,15,15,1);
}
#Rectangle_110 {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_110 {
  filter: drop-shadow(10px 10px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 190px;
  height: 165px;
  left: 894px;
  top: 522px;
}
#Jobs__8_b {
  left: 954px;
  top: 624px;
  position: absolute;
  overflow: visible;
  width: 52px;
  height: 24px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Five-S_b {
  left: 952px;
  top: 595px;
  position: absolute;
  overflow: visible;
  width: 57px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: rgba(16,15,15,1);
}
#Rectangle_111 {
  opacity: 0.9;
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_111 {
  filter: drop-shadow(10px 10px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 190px;
  height: 165px;
  left: 1092px;
  top: 522px;
}
#Jobs__7_b {
  left: 1154px;
  top: 625px;
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 22px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Allsec_b {
  left: 1154px;
  top: 596px;
  position: absolute;
  overflow: visible;
  width: 49px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: rgba(16,15,15,1);
}

#top_card_dream_pc {
  position: absolute;
  width: 100%;
  height: 179px;
  left: 76px;
  top: 30px;
  overflow: visible;
}
#vacancy_bdr_can {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.vacancy_bdr_can {
  filter: drop-shadow(8px 8px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 100%;
  height: 170px;
  left: 0px;
  top: 0px;
}
#vaccine_page_img_can {
  position: absolute;
  width: 179px;
  height: 179px;
  left: 35px;
  top: 10px;
  overflow: visible;
}
#back_pc_candidate {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  width: 35px;
  height: 35px;
}
.back_pc_candidate {
  position: relative;
  overflow: visible;
  width: 35px;
  height: 35px;
  left: calc(90% - 50px);
  top: 20px;
  cursor: pointer;
}
#Find_Dream_Job_can {
  left: calc(85% - 800px);
  top: 51px;
  position: absolute;
  overflow: visible;
  width: 30%;
  white-space: pre-wrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  color: rgba(36,35,35,1);
  letter-spacing: 0.16px;
}
#Find_Candidates_bl_ {
  left: calc(50% - 600px);
  top: 109px;
  position: absolute;
  overflow: visible;
  width: 80%;
  white-space: pre-wrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(255,0,0,1);
  letter-spacing: 0.15px;
}


.headingTag{
  left:0px;
  top:340px;
}

