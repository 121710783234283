.mediaViewInfo {
    --web-view-name: privacy policy;
    --web-view-id: privacy_policy;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: privacy_policy;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#privacy_policy {
    position: absolute;
    width: 100%;
    height: 95%;
    background-color: rgba(255,255,255,1);
    overflow: scroll;
    --web-view-name: privacy policy;
    --web-view-id: privacy_policy;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#PRIVACY_POLICY__BIZI_BEES_HOLD {
    /* left: 35px; */
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: pre-wrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
}