.mediaViewInfo {
    --web-view-name: iPhone 14 Pro Max – 1;
    --web-view-id: iPhone_14_Pro_Max__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: iPhone_14_Pro_Max__1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#iPhone_14_Pro_Max__1 {
    position: absolute;
    width: 430px;
    height: 2470px;
    top: 90px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: iPhone 14 Pro Max – 1;
    --web-view-id: iPhone_14_Pro_Max__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
@keyframes fadein {

    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}
#BackColor4 {
    fill: rgba(90,98,236,1);
}
.BackColor4 {
    position: absolute;
    overflow: visible;
    width: 240px;
    height: 240px;
    left: 360px;
    top: 1837px;
}
#BackColor2 {
    fill: rgba(90,98,236,1);
}
.BackColor2 {
    width: 240px;
    height: 240px;
    position: absolute;
    overflow: visible;
    transform: matrix(1,0,0,1,-180,1312) rotate(270deg);
    transform-origin: center;
}
#BackColor3 {
    fill: rgba(90,98,236,1);
}
.BackColor3 {
    position: absolute;
    overflow: visible;
    width: 240px;
    height: 240px;
    left: 360px;
    top: 606px;
}
#Register {
    left: 142px;
    top: 27px;
    position: absolute;
    overflow: visible;
    width: 147px;
    height: 53px;
    text-align: center;
    font-family: Reem Kufi;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(255,79,90,1);
}
#BackColor1 {
    fill: rgba(90,98,236,1);
}
.BackColor1 {
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.161));
    width: 522.214px;
    height: 375px;
    position: absolute;
    overflow: visible;
    transform: matrix(1,0,0,1,-46.107,-114) rotate(0deg);
    transform-origin: center;
}
#CandidateMobileUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CandidateMobileUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 62px;
    left: 90px;
    top: 275px;
}
#Upload__Image {
    opacity: 0.66;
    left: 166px;
    top: 197px;
    position: absolute;
    overflow: visible;
    width: 99px;
    height: 60px;
    text-align: center;
    font-family: Reem Kufi;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(252,245,245,1);
}
#CandidateLanguagesUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CandidateLanguagesUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 62px;
    left: 62px;
    top: 1135px;
}
#Languages {
    opacity: 0.4;
    left: 131px;
    top: 1142px;
    position: absolute;
    overflow: visible;
    width: 106px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#CandidateCityUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CandidateCityUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 62px;
    left: 90px;
    top: 816px;
}
#City {
    opacity: 0.4;
    left: 117px;
    top: 823px;
    position: absolute;
    overflow: visible;
    width: 66px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Experience_reg_mob {
    opacity: 0.4;
    left: 157px;
    top: 723px;
    position: absolute;
    overflow: visible;
    width: 118px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#CandidateDOBUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CandidateDOBUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 62px;
    left: 90px;
    top: 666px;
}
#DOB {
    opacity: 0.4;
    left: 90px;
    top: 673px;
    position: absolute;
    overflow: visible;
    width: 78px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#CandidateGenderUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CandidateGenderUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 62px;
    left: 90px;
    top: 500px;
}
#Gender {
    opacity: 0.4;
    left: 122px;
    top: 507px;
    position: absolute;
    overflow: visible;
    width: 99px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#CandidateEmailUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CandidateEmailUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 62px;
    left: 90px;
    top: 425px;
}
#Mobile_Number {
    opacity: 0.4;
    left: 113px;
    top: 282px;
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#CandidateNameUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CandidateNameUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 62px;
    left: 90px;
    top: 350px;
}
#Email {
    opacity: 0.4;
    left: 109px;
    top: 435px;
    position: absolute;
    overflow: visible;
    width: 99px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Polygon_1 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: bevel;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Polygon_1 {
    mix-blend-mode: darken;
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 15px;
    transform: matrix(1,0,0,1,322.8704,514.5) rotate(181deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#calendar {
    position: absolute;
    width: 26px;
    height: 27px;
    left: 315px;
    top: 676px;
    overflow: visible;
}
#ExpYearsUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.ExpYearsUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 102px;
    height: 62px;
    left: 115px;
    top: 753px;
}
#ExpMonthsUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.ExpMonthsUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 103px;
    height: 62px;
    left: 235px;
    top: 753px;
}
#CurrentSalaryUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CurrentSalaryUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 62px;
    left: 91px;
    top: 984px;
}
#Current_Salary {
    opacity: 0.4;
    left: 130px;
    top: 991px;
    position: absolute;
    overflow: visible;
    width: 137px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Polygon_2 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: bevel;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Polygon_2 {
    mix-blend-mode: darken;
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 15px;
    transform: matrix(1,0,0,1,319,830.5) rotate(181deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#Polygon_3 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: bevel;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Polygon_3 {
    mix-blend-mode: darken;
    overflow: visible;
    position: absolute;
    width: 18px;
    height: 15px;
    transform: matrix(1,0,0,1,321,1149.5) rotate(181deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
}
#ExpectedSalaryUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.ExpectedSalaryUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 62px;
    left: 91px;
    top: 1059px;
}
#Expected_Salary {
    opacity: 0.4;
    left: 133px;
    top: 1066px;
    position: absolute;
    overflow: visible;
    width: 149px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#CandidateSkillsUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CandidateSkillsUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 310px;
    height: 62px;
    left: 60px;
    top: 1389px;
}
#Skills {
    opacity: 0.4;
    left: 132px;
    top: 1396px;
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#ResumeUploadMobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: bevel;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.ResumeUploadMobile
 {
    filter: drop-shadow(7px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 53px;
    left: 93px;
    top: 1599px;
}
#Choose_file {
    opacity: 0.4;
    left: 93px;
    top: 1602px;
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Upload_Resume_ {
    opacity: 0.65;
    left: 211px;
    top: 1584px;
    position: absolute;
    overflow: visible;
    width: 160px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(42,14,15,1);
}
#No_file_chosen {
    opacity: 0.65;
    left: 210px;
    top: 1608px;
    position: absolute;
    overflow: visible;
    width: 160px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(42,14,15,1);
}
#WhatsappNumberUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.WhatsappNumberUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 62px;
    left: 90px;
    top: 584px;
}
#PreferencesMobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 83px;
    top: 1655px;
    position: absolute;
    overflow: visible;
    width: 188px;
    height: 47px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(255,79,90,1);
}
#Transportation {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 97px;
    top: 2128px;
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
}
#WhatsApp_Number {
    opacity: 0.4;
    left: 129px;
    top: 591px;
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#mail {
    opacity: 0.5;
    position: absolute;
    width: 26px;
    height: 26px;
    left: 100px;
    top: 437px;
    overflow: visible;
}
#phone {
    opacity: 0.5;
    position: absolute;
    width: 37px;
    height: 37px;
    left: 94px;
    top: 279px;
    overflow: visible;
}
#gender-fluid {
    opacity: 0.5;
    position: absolute;
    width: 31px;
    height: 31px;
    left: 100px;
    top: 506px;
    overflow: visible;
}
#whatsapp {
    opacity: 0.51;
    position: absolute;
    width: 33px;
    height: 33px;
    left: 99px;
    top: 588px;
    overflow: visible;
}
#skyline {
    opacity: 0.6;
    position: absolute;
    width: 24px;
    height: 24px;
    left: 103px;
    top: 826px;
    overflow: visible;
}
#language {
    opacity: 0.52;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 102px;
    top: 1142px;
    overflow: visible;
}
#salary {
    opacity: 0.5;
    position: absolute;
    width: 24px;
    height: 24px;
    left: 105px;
    top: 993px;
    overflow: visible;
}
#salary_ch {
    opacity: 0.5;
    position: absolute;
    width: 24px;
    height: 24px;
    left: 105px;
    top: 1069px;
    overflow: visible;
}
#skill-development {
    opacity: 0.5;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 101px;
    top: 1396px;
    overflow: visible;
}
#imageUpload1 {
    position: absolute;
    width: 91px;
    height: 91px;
    left: 170px;
    top: 101px;
    overflow: visible;
}
#undo {
    position: absolute;
    width: 53px;
    height: 53px;
    left: 12px;
    top: 11px;
    overflow: visible;
    transition: all 0.6000000238418579s wind-up;
    --web-animation: fadein 0.6000000238418579s wind-up;
    --web-action-type: page;
    --web-action-target: iPhone_14_Pro___1.html;
    cursor: pointer;
}
#CandidateAreaUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CandidateAreaUI {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 62px;
    left: 90px;
    top: 899px;
}
#CandidateEducationUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CandidateEducationUI {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 310px;
    height: 62px;
    left: 60px;
    top: 1312px;
}
#mortarboard {
    opacity: 0.57;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 97px;
    top: 1319px;
    overflow: visible;
}
#AddressUI {
    position: absolute;
    width: 257px;
    height: 106px;
    left: 60px;
    top: 1462px;
    overflow: visible;
}
#CandidateAddressUI {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CandidateAddressUI {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 275px;
    height: 124px;
    left: 0px;
    top: 20%;
}
#Address {
    opacity: 0.4;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 33px;
    top: 9px;
    position: absolute;
    overflow: visible;
    width: 76px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#pin {
    opacity: 0.57;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 6px;
    top: 8px;
    overflow: visible;
}
#chkWillingtorelocate_mobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkWillingtorelocate_mobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 103px;
    top: 2254px;
}
#AppWilling_to_Relocate_Mobile_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 125px;
    top: 2252px;
    position: absolute;
    overflow: visible;
    width: 176px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#BtnSubmit_mobile_btn {
    fill: rgba(90,98,236,1);
}
.BtnSubmit_mobile_btn {
    filter: drop-shadow(7px 5px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 280px;
    height: 68px;
    left: 15%;
    top: 2385px;
}
#CandidateReference_mobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.CandidateReference_mobile {
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 310px;
    height: 62px;
    left: 80px;
    top: 2319px;
}
#Reference {
    opacity: 0.4;
    left: 145px;
    top: 2362px;
    position: absolute;
    overflow: visible;
    width: 95px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#research {
    opacity: 0.56;
    position: absolute;
    width: 32px;
    height: 32px;
    left: 114px;
    top: 2360px;
    overflow: visible;
}
#Name {
    opacity: 0.4;
    left: 103px;
    top: 356px;
    position: absolute;
    overflow: visible;
    width: 99px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#people {
    opacity: 0.5;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 96px;
    top: 356px;
    overflow: visible;
}
#Register_dd {
    left: 163px;
    top: 2432px;
    position: absolute;
    overflow: visible;
    width: 140px;
    height: 40px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: rgba(249,242,242,1);
}