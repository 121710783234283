
#chkVoice {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkVoice {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 833px;
    top: 1222px;
}
#chkNonvoice {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkNonvoice {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: relative;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 997px; 
    top: 1200px; 
}

#chkSemiVoice {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkSemiVoice {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 1169px;
    top: 1222px;
}

#chkFullTime {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkFullTime {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 833px;
    top: 1304px;
}

#chkPartTime {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkPartTime {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 997px;
    top: 1304px;
}

#chkTemporary {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkTemporary {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 1169px;
    top: 1304px;
}

#chkWorkfromoffcice {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkWorkfromoffcice {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 833px;
    top: 1384px;
}

#chkWorkfromhome {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkWorkfromhome {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 997px;
    top: 1384px;
}


#chkHybrid {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkHybrid {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 1169px;
    top: 1384px;
}

#chkDayShift {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkDayShift {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 833px;
    top: 1455px;
}

#chkNightShift {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkNightShift {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 997px;
    top: 1455px;
}

#chkRotaionalShift {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkRotaionalShift {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 1169px;
    top: 1455px;
}

#chkOwnTransport {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkOwnTransport {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 833px;
    top: 1535px;
}

#chkCompanyTransportDay {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkCompanyTransportDay {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 997px;
    top: 1535px;
}


#chkCompanyTransportNight {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;

    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkCompanyTransportNight {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 1169px;
    top: 1535px;
}

#WPVoice {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 856px;
    top: 1218px;
    position: absolute;
    overflow: visible;
    width: 64px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#WPNonVoice {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 1020px;
    top: 1218px;
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#WPSemiVoice {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 1195px;
    top: 1218px;
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#WPFull_Time {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 856px;
    top: 1300px;
    position: absolute;
    overflow: visible;
    width: 92px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#WPPart_Time {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 1020px;
    top: 1300px;
    position: absolute;
    overflow: visible;
    width: 107px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#WPWork_Mode {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 832px;
    top: 1337px;
    position: absolute;
    overflow: visible;
    width: 136px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
}
#WPOffice {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 856px;
    top: 1378px;
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#WPHome {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 1027px;
    top: 1378px;
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#WPHybrid {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 1195px;
    top: 1378px;
    position: absolute;
    overflow: visible;
    width: 79px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#WPTransportation {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 833px;
    top: 1488px;
    position: absolute;
    overflow: visible;
    width: 161px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
}
#WPOwn {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 864px;
    top: 1530px;
    position: absolute;
    overflow: visible;
    width: 64px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#WPCompany_Day {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 1028px;
    top: 1530px;
    position: absolute;
    overflow: visible;
    width: 134px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#WPCompany_Night_PC {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 1195px;
    top: 1530px;
    position: absolute;
    overflow: visible;
    width: 146px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#WPEmployment_Type {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 832px;
    top: 1255px;
    position: absolute;
    overflow: visible;
    width: 201px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
}
#WPTemporary {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 1195px;
    top: 1300px;
    position: absolute;
    overflow: visible;
    width: 105px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#WPShifts {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 832px;
    top: 1410px;
    position: absolute;
    overflow: visible;
    width: 71px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
}
#WPDay {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 861px;
    top: 1450px;
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#WPNight {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 1028px;
    top: 1450px;
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#WPRotational {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 1195px;
    top: 1450px;
    position: absolute;
    overflow: visible;
    width: 96px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}