.mediaViewInfo {
    --web-view-name: vacancy page;
    --web-view-id: vacancy_page;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: vacancy_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#vacancy_page {
    position: absolute;
    width: 350px;
    height: 1346px;
    left: 50%;
    transform: translate(-50%, 0%);
    border-radius: 10px; 
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: vacancy page;
    --web-view-id: vacancy_page;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_vacancy {
    mix-blend-mode: multiply;
    position: absolute;
    width: 350px;
    height: 1352px;
    left: 0px;
    top: -6px;
    overflow: visible;
}
#top_design_ {
    fill: rgba(7,101,133,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.top_design_ {
    overflow: visible;
    position: absolute;
    width: 357.774px;
    height: 90.076px;
    left: -4.454px;
    top: -8.673px;
    transform: matrix(1,0,0,1,0,0);
}
#ADD_VACANCY_lbl {
    left: 85px;
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 181px;
    white-space: nowrap;
    text-align: center;
    font-family: Arial Unicode MS;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(255,255,255,1);
}
#Categories_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 33px;
    top: 393px;
    position: absolute;
    overflow: visible;
    width: 143px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: rgba(255,79,90,1);
}
#cat_grp {
    position: absolute;
    width: 280px;
    height: 307px;
    left: 30px;
    top: 455px;
    overflow: visible;
}
#chxbox_voice {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_voice {
    position: absolute;
    overflow: visible;
    width: 20px;
    height: 21px;
    left: 1px;
    top: 0px;
}
#Voice_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 27px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#chxbox_non {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_non {
    position: absolute;
    overflow: visible;
    width: 21px;
    height: 21px;
    left: 73px;
    top: 0px;
}
#Non-Voicelbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 99px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 71px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#chxbox_SV {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_SV {
    position: absolute;
    overflow: visible;
    width: 20px;
    height: 21px;
    left: 179px;
    top: 0px;
}
#Semi-Voice_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 206px;
    top: 1px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#chxbox_pt {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_pt {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 21px;
    left: 99px;
    top: 75px;
}
#chxbox_FT {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_FT {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 21px;
    left: 1px;
    top: 75px;
}
#Full_Time_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 29px;
    top: 76px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#Part_Time_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 126px;
    top: 76px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#chxbox_hybrid {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_hybrid {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 21px;
    left: 156px;
    top: 145px;
}
#chxbox_home {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_home {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 21px;
    left: 79px;
    top: 145px;
}
#chxbox_office {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_office {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 21px;
    left: 1px;
    top: 145px;
}
#Work_Mode_lbl {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 0px;
    top: 111px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: italic;
    font-weight: normal;
    font-size: 20px;
    color: rgba(42,14,15,1);
}
#Office_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 29px;
    top: 146px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#Home_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 106px;
    top: 146px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#Hybrid_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 184px;
    top: 146px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#chxbox_com_day {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_com_day {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 21px;
    left: 113px;
    top: 286px;
}
#chxbox_own {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_own {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 21px;
    left: 212px;
    top: 286px;
}
#Transportation_lbl {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 0px;
    top: 252px;
    position: absolute;
    overflow: visible;
    width: 129px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: italic;
    font-weight: normal;
    font-size: 20px;
    color: rgba(42,14,15,1);
}
#Own_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 239px;
    top: 287px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#Company_Day_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 140px;
    top: 287px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#Employment_Type_lbl {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 0px;
    top: 36px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: italic;
    font-weight: normal;
    font-size: 20px;
    color: rgba(42,14,15,1);
}
#chxbox__com_nght {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox__com_nght {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 21px;
    left: 1px;
    top: 286px;
}
#Company_Night_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 29px;
    top: 287px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#chxbox_rotational {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_rotational {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 21px;
    left: 143px;
    top: 217px;
}
#chxbox_nght {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_nght {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 21px;
    left: 68px;
    top: 217px;
}
#Night_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 95px;
    top: 218px;
    position: absolute;
    overflow: visible;
    width: 39px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#Rotational_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 171px;
    top: 218px;
    position: absolute;
    overflow: visible;
    width: 69px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#Shifts_lbl {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 0px;
    top: 181px;
    position: absolute;
    overflow: visible;
    width: 47px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: italic;
    font-weight: normal;
    font-size: 20px;
    color: rgba(42,14,15,1);
}
#chxbox_day {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chxbox_day {
    position: absolute;
    overflow: visible;
    width: 23px;
    height: 21px;
    left: 1px;
    top: 217px;
}
#Day_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 32px;
    top: 218px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(28,4,5,1);
}
#exp_min_grp {
    position: absolute;
    width: 127px;
    height: 47px;
    left: 30px;
    top: 1050px;
    overflow: visible;
}
#vacancy_ex_max_1 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.vacancy_ex_max_1 {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 47px;
    left: 0px;
    top: 0px;
    font-size: 15px;
    padding: 9px;
    border: 2px solid lightblue;
    border-radius: 10px;
}
#vacancy_ex_max {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.vacancy_ex_max {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 47px;
    left: 67px;
    top: 0px;
    font-size: 15px;
    padding: 3px;
    border: 2px solid lightblue;
    border-radius: 10px;
}
/* #vacancy_min {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.vacancy_min {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 47px;
    left: 100px;
    top: 1163px;
    font-size: 17px;
    padding: 20px;
    border: 2px solid lightblue;
    border-radius: 10px;
}
#vacancy_max {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.vacancy_max {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 47px;
    left: 191px;
    top: 1163px;
    font-size: 17px;
    padding: 20px;
    border: 2px solid lightblue;
    border-radius: 10px;
} */
#vacancy_min {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.vacancy_min {
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 50px;
    left: 60px;
    top: 1163px;
    font-size: 17px;
    padding: 10px;
    border: 2px solid lightblue;
    border-radius: 10px;
}
#vacancy_max {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.vacancy_max {
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 50px;
    left: 191px;
    top: 1163px;
    font-size: 17px;
    padding: 10px;
    border: 2px solid lightblue;
    border-radius: 10px;
}
#Min_Experience_vacancy {
    opacity: 0.7;
    left: 84px;
    top: 1025px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#Max_Experience_vacancy {
    opacity: 0.7;
    left: 240px;
    top: 1025px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#Salary_Range_vacancy {
    opacity: 0.7;
    left: 134px;
    top: 1113px;
    position: absolute;
    overflow: visible;
    width: 87px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
    text-decoration: underline;
}
#btn_vacancy:hover {
    left: 106px;
    top: 1260px;
}
#btn_vacancy {
    position: absolute;
    width: 144px;
    height: 50px;
    left: 100px;
    top: 1253px;
    overflow: visible;
    border-radius: 20px;
    transition: all .3s ease-out;
}
#vacancy_btn {
    fill: rgba(0,180,219,1);
}
.vacancy_btn {
    filter: drop-shadow(10px 10px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 163px;
    height: 69px;
    left: 0px;
    top: 0px;
}
#SUBMIT_vacancy {
    left: 42px;
    top: 14px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,255,255,1);
}
#Experience {
    left: 140px;
    top: 1025px;
    position: absolute;
    overflow: visible;
    width: 72px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
    text-decoration: underline;
}
#exp_max_grp {
    position: absolute;
    width: 127px;
    height: 47px;
    left: 190px;
    top: 1050px;
    overflow: visible;
}
#vacancy_ex_max_1_b {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.vacancy_ex_max_1_b  {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 47px;
    left: 0px;
    top: 0px;
    font-size: 15px;
    padding: 9px;
    border: 2px solid lightblue;
    border-radius: 10px;
}
#vacancy_ex_max_b {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.vacancy_ex_max_b {
    position: absolute;
    overflow: visible;
    width: 60px;
    height: 47px;
    left: 67px;
    top: 0px;
    font-size: 15px;
    padding: 3px;
    border: 2px solid lightblue;
    border-radius: 10px;
}
#Min_Experience_vacancy_b {
    opacity: 0.7;
    left: 117px;
    top: 1141px;
    position: absolute;
    overflow: visible;
    width: 27px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#Max_Experience_vacancy_b {
    opacity: 0.7;
    left: 207px;
    top: 1138px;
    position: absolute;
    overflow: visible;
    width: 29px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#com_name_grp_ {
    position: absolute;
    width: 287px;
    height: 61px;
    left: 30px;
    top: 99px;
    overflow: visible;
}
#comopany_name_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.comopany_name_ {
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 40px;
    left: 0px;
    top: 21px;
    font-size: 17px;
    /* padding: 20px; */
    border: 2px solid lightblue;
    border-radius: 10px;
}
#job_lbl_ {
    opacity: 0.7;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 107px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#off_name_grp {
    position: absolute;
    width: 287px;
    height: 61px;
    left: 30px;
    top: 170px;
    overflow: visible;
}
#office_name_lbl {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.office_name_lbl {
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 40px;
    left: 0px;
    top: 21px;
    font-size: 17px;
   
    border: 2px solid lightblue;
    border-radius: 10px;
}
#job_lbl_b {
    opacity: 0.7;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 83px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#spoc_name_gro {
    position: absolute;
    width: 287px;
    height: 61px;
    left: 30px;
    top: 312px;
    overflow: visible;
}
#spoc_name_lbl {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.spoc_name_lbl {
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 40px;
    left: 0px;
    top: 21px;
    font-size: 17px;
    /* padding: 20px; */
    border: 2px solid lightblue;
    border-radius: 10px;
}
#job_lbl_cc {
    opacity: 0.7;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#job_title_grp {
    position: absolute;
    width: 287px;
    height: 61px;
    left: 30px;
    top: 241px;
    overflow: visible;
}
#job_title_lbl {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.job_title_lbl {
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 40px;
    left: 0px;
    top: 21px;
    font-size: 17px;
    padding: 20px;
    border: 2px solid lightblue;
    border-radius: 10px;
}
#job_lbl_cf {
    opacity: 0.7;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 57px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#jd_grp {
    position: absolute;
    width: 287px;
    height: 115px;
    left: 30px;
    top: 792px;
    overflow: visible;
}
#vacancy_job {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.vacancy_job {
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 94px;
    left: 0px;
    top: 21px;
    font-size: 17px;
    padding: 20px;
    border: 2px solid lightblue;
    border-radius: 10px;
}
#job_decs_lbl {
    opacity: 0.7;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 103px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#ks_grp {
    position: absolute;
    width: 287px;
    height: 98px;
    left: 30px;
    top: 917px;
    overflow: visible;
}
#vacancy_req_skill {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.vacancy_req_skill {
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 77px;
    left: 0px;
    top: 21px;
    font-size: 17px;
    padding: 20px;
    border: 2px solid lightblue;
    border-radius: 10px;
}
#ks_lbl {
    opacity: 0.7;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(5,5,5,1);
}
#close_vacancy_pc {
    cursor: pointer;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 317px;
    top: 7px;
    overflow: visible;
}