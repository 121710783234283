	.UDP_mediaViewInfo {
		--web-view-name: user detail pop;
		--web-view-id: user_detail_pop;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: user_detail_pop;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#UDP_user_detail_pop {
		position: absolute;
		width: 300px;
		height: 695px;
        top: 50%;
        left: 50%;
        border-radius: 15px;
        transform: translate(-50% , -50%);
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		--web-view-name: user detail pop;
		--web-view-id: user_detail_pop;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#UDP_Rectangle_55_r {
		fill: url(#UDP_Rectangle_55_r);
	}
	.UDP_Rectangle_55_r {
		filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 308px;
		height: 702px;
		left: 0px;
		top: 0px;
	}
	#UDP_update_profile_grp:hover {
		left: 88px;
		top: 643px;
	}
	#UDP_update_profile_grp {
		position: absolute;
		width: 136px;
		height: 39px;
		left: 84px;
		top: 638px;
		overflow: visible;
		transition: all .3s ease-out;
	}
	#UDP_Rectangle_34_w {
		fill: rgba(170,207,255,1);
	}
	.UDP_Rectangle_34_w {
		position: absolute;
		overflow: visible;
		width: 136px;
		height: 39px;
		left: 0px;
		top: 0px;
	}
	#UDP_Add_User_x {
		left: 33px;
		top: 8px;
		position: absolute;
		overflow: visible;
		width: 70px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#UDP_User_Details {
		left: 96px;
		top: 5px;
		position: absolute;
		overflow: visible;
		width: 113px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#UDP_close {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 270px;
		top: 9px;
        cursor: pointer;
		overflow: visible;
	}
	#UDP_Scroll_Group_1 {
		mix-blend-mode: normal;
		position: absolute;
		width: 301px;
		height: 572.031px;
		left: 2px;
		top: 46.969px;
		overflow-x: hidden;
		overflow-y: scroll;
	}

    #UDP_Scroll_Group_1 ::-webkit-scrollbar {
        display: none;
    }
	#UDP_name_grp_prof {
		position: absolute;
		width: 224.5px;
		height: 63px;
		left: 40px;
		top: 0px;
		overflow: visible;
	}
	#UDP_name_lbl_pfp {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 46px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#UDP_name_input_pfp {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_name_input_pfp {
		position: absolute;
		overflow: visible;
		width: 224.5px;
		height: 40px;
		left: 0px;
		top: 23px;
		font-size: 15px;
		border: 2px solid lightblue;
		border-radius: 10px;
		padding: 5px;
	}
	#UDP_mobile_grp_prof {
		position: absolute;
		width: 224.5px;
		height: 63px;
		left: 40px;
		top: 146px;
		overflow: visible;
	}
	#UDP_Mobile_Number_lbl_pfp {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 120px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#UDP_mob_input_pfp {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_mob_input_pfp {
		position: absolute;
		overflow: visible;
		width: 224.5px;
		height: 40px;
		left: 0px;
		top: 23px;
		font-size: 15px;
		border: 2px solid lightblue;
		border-radius: 10px;
		padding: 5px;
	}
	#UDP_gender_grp_proof {
		position: absolute;
		width: 104.5px;
		height: 63px;
		left: 40px;
		top: 73px;
		overflow: visible;
	}
	#UDP_Gender_lbl_pfp {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 56px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#UDP_gen_input_pfp {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_gen_input_pfp {
		position: absolute;
		overflow: visible;
		width: 104.5px;
		height: 40px;
		left: 0px;
		top: 23px;
		font-size: 15px;
		border: 2px solid lightblue;
		border-radius: 10px;
		padding: 5px;
	}
	#UDP_dob_grp_proof {
		position: absolute;
		width: 224.5px;
		height: 63px;
		left: 40px;
		top: 365px;
		overflow: visible;
	}
	#UDP_DOB_lbl_pfp {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 91px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#UDP_dob_input_pfp {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_dob_input_pfp {
		position: absolute;
		overflow: visible;
		width: 224.5px;
		height: 40px;
		left: 0px;
		top: 23px;
		font-size: 15px;
		border: 2px solid lightblue;
		border-radius: 10px;
		padding: 5px;
	}
	#UDP_emmail_grp_proof {
		position: absolute;
		width: 224.5px;
		height: 63px;
		left: 40px;
		top: 219px;
		overflow: visible;
	}
	#UDP_Email_lbl {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 41px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#UDP_email_input {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_email_input {
		position: absolute;
		overflow: visible;
		width: 224.5px;
		height: 40px;
		left: 0px;
		top: 23px;
		font-size: 15px;
		border: 2px solid lightblue;
		border-radius: 10px;
		padding: 5px;
	}
	#UDP_mobile_grp_prof_bg {
		position: absolute;
		width: 224.5px;
		height: 63px;
		left: 40px;
		top: 438px;
		overflow: visible;
	}
	#UDP_Mobile_Number_lbl_pfp_bh {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 131px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#UDP_mob_input_pfp_bi {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_mob_input_pfp_bi {
		position: absolute;
		overflow: visible;
		width: 224.5px;
		height: 40px;
		left: 0px;
		top: 23px;
		font-size: 15px;
		border: 2px solid lightblue;
		border-radius: 10px;
		padding: 5px;
	}
	#UDP_emmail_grp_proof_bj {
		position: absolute;
		width: 224.5px;
		height: 63px;
		left: 40px;
		top: 292px;
		overflow: visible;
	}
	#UDP_Email_lbl_bk {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 97px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#UDP_email_input_bl {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_email_input_bl {
		position: absolute;
		overflow: visible;
		width: 224.5px;
		height: 40px;
		left: 0px;
		top: 23px;
		font-size: 15px;
		border: 2px solid lightblue;
		border-radius: 10px;
		padding: 5px;
	}
	#UDP_mobile_grp_prof_bm {
		position: absolute;
		width: 104.5px;
		height: 63px;
		left: 160px;
		top: 73px;
		overflow: visible;
	}
	#UDP_Mobile_Number_lbl_pfp_bn {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 35px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#UDP_mob_input_pfp_bo {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_mob_input_pfp_bo {
		position: absolute;
		overflow: visible;
		width: 110px;
		height: 40px;
		left: -2px;
		top: 23px;
		font-size: 15px;
		border: 2px solid lightblue;
		border-radius: 10px;
		padding: 5px;
	}
	#UDP_Group_43 {
		position: absolute;
		width: 79px;
		height: 27px;
		left: 40px;
		top: 611.031px;
		overflow: visible;
	}
	#UDP_Is_HR_lbl {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#UDP_Rectangle_49 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_Rectangle_49 {
		position: absolute;
		overflow: visible;
		width: 20px;
		height: 20px;
		left: 59px;
		top: 3.031px;
	}
	#UDP_Group_42 {
		position: absolute;
		width: 79px;
		height: 27px;
		left: 175.5px;
		top: 610.562px;
		overflow: visible;
	}
	#UDP_Is_HR_lbl_bt {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(0,0,0,1);
	}
	#UDP_Rectangle_50 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_Rectangle_50 {
		position: absolute;
		overflow: visible;
		width: 20px;
		height: 20px;
		left: 59px;
		top: 3.5px;
	}
	#UDP_Group_46 {
		position: absolute;
		width: 222px;
		height: 40px;
		left: 40px;
		top: 511.031px;
		overflow: visible;
	}
	#UDP_Rectangle_59 {
		fill: rgba(255,255,255,1);
	}
	.UDP_Rectangle_59 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 240px;
		height: 58px;
		left: 0px;
		top: 0px;
	}
	#UDP_Group_44 {
		position: absolute;
		width: 120px;
		height: 25px;
		left: 83px;
		top: 8px;
		overflow: visible;
	}
	#UDP_Rectangle_53 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_Rectangle_53 {
		position: absolute;
		overflow: visible;
		width: 20px;
		height: 20px;
		left: 29.874px;
		top: 2.5px;
	}
	#UDP_telephone {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#UDP_whatsapp_dashboard {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#UDP_Rectangle_56 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_Rectangle_56 {
		position: absolute;
		overflow: visible;
		width: 20px;
		height: 20px;
		left: 100px;
		top: 2px;
	}
	#UDP_whatsapp_dashboard_b {
		position: absolute;
		width: 24px;
		height: 25px;
		left: 19px;
		top: 8px;
		overflow: visible;
	}
	#UDP_Group_47 {
		position: absolute;
		width: 222px;
		height: 40px;
		left: 39px;
		top: 561.031px;
		overflow: visible;
	}
	#UDP_Rectangle_59_b {
		fill: rgba(255,255,255,1);
	}
	.UDP_Rectangle_59_b {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 240px;
		height: 58px;
		left: 0px;
		top: 0px;
	}
	#UDP_Group_44_b {
		position: absolute;
		width: 120px;
		height: 25px;
		left: 83px;
		top: 8px;
		overflow: visible;
	}
	#UDP_Rectangle_53_b {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_Rectangle_53_b {
		position: absolute;
		overflow: visible;
		width: 20px;
		height: 20px;
		left: 29.874px;
		top: 2.5px;
	}
	#UDP_telephone_b {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#UDP_whatsapp_dashboard_ca {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 70px;
		top: 0px;
		overflow: visible;
	}
	#UDP_Rectangle_56_b {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.UDP_Rectangle_56_b {
		position: absolute;
		overflow: visible;
		width: 20px;
		height: 20px;
		left: 100px;
		top: 2px;
	}
	#UDP_whatsapp_dashboard_cb {
		position: absolute;
		width: 24px;
		height: 25px;
		left: 19px;
		top: 8px;
		overflow: visible;
	}
