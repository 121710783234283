  /* .mediaViewInfo {
    --web-view-name: Pop-up Indo mobile;
    --web-view-id: Pop-up_Indo_mobile;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Pop-up_Indo_mobile;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#Pop-up_Indo_mobile {
    position: relative;
    width: 430px;
   
    height: 1050px; 
    top: 341px;
    filter: drop-shadow(10px 10px 6px rgba(0, 0, 0, 0.161));
    background-color: rgba(255,255,255,1);
    overflow: hidden;
   
}
#Ellipse_4 {
    fill: rgba(134,127,238,1);
}
.Ellipse_4 {
    position: absolute;
    overflow: visible;
    width: 594px;
    height: 577px;
    left: 384px;
    top: -23px;
}
#Ellipse_3 {
    fill: rgba(134,127,238,1);
}
.Ellipse_3 {
    position: absolute;
    overflow: visible;
    width: 594px;
    height: 577px;
    left: -550px;
    top: 438px;
}
#JobSummary_Company_Name {
    left: 80px;
    top: 28px;
    position: absolute;
    overflow: visible;
    width: 258px;
    height: 40px;
   
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(112,112,112,1);
    text-decoration: underline;
}
#Job_Description__ {
    left: 54px;
    top: 283px;
    position: absolute;
    overflow-y: scroll;
    width: 349px;
    height: 405px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#Office_Names_  {
    left: 160px;
    top: 96px;
    position: absolute;
    overflow: visible;
    width: 153px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Office_Name_ {
    left: 29px;
    top: 96px;
    position: absolute;
    overflow: visible;
    width: 159px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Job_Title_ {
    left: 15%;
    top: 123px;
    position: absolute;
    overflow: visible;
    width: 110px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Job_Title__ {
    left: 160px;
    top: 125px;
    position: absolute;
    overflow: visible;
    width: 274px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(112,112,112,1);
}
#Experience_ {
    left: 45px;
    top: 154px;
    position: absolute;
    overflow: visible;
    width: 120px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Experince_Required {
    left: 160px;
    top: 154px;
    position: absolute;
    overflow: visible;
    width: 243px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Job_Description_ {
    left: 128px;
    top: 252px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Key_Skills_ {
    left: 156px;
    top: 718px;
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Key_Skills__ {
    left: 54px;
    top: 745px;
    position: relative;
    overflow-y: scroll;
    width: 349px;
    height: 150px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#Salary_Range_ {
    left: 26px;
    top: 188px;
    position: absolute;
    overflow: visible;
    width: 140px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Salary_Range__ {
    left: 160px;
    top: 188px;
    position: absolute;
    overflow: visible;
    width: 181px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Rectangle_97 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_97 {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 142px;
    height: 72px;
    left: 218px;
    top: 1050px;
}
#Rectangle_98 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_98 {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 142px;
    height: 72px;
    left: 59px;
    top: 1050px;
}
#linkedin {
    position: absolute;
    width: 35px;
    height: 35px;
    left: 71px;
    top: 1058px;
    overflow: visible;
}
#Post {
    left: 117px;
    top: 1058px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(87,70,70,1);
}
#Share {
    left: 273px;
    top: 1058px;
    position: absolute;
    overflow: visible;
    width: 63px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(87,70,70,1);
}
#whatsapp1 {
    position: absolute;
    width: 35px;
    height: 35px;
    left: 229px;
    top: 1058px;
    overflow: visible;
}
#Rectangle_95 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_95 {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 301px;
    height: 72px;
    left: 59px;
    top: 950px;
}
#Apply {
    left: 174px;
    top: 955px;
    position: absolute;
    overflow: visible;
    width: 65px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(87,70,70,1);
}
#success {
    position: absolute;
    width: 35px;
    height: 35px;
    left: 129px;
    top: 957px;
    overflow: visible;
}
.ExpandDescription{
position: absolute;
    left: 40%;
    top: 10%;
   
} */ 

#overall_card_grp_candi {
    position: relative;
    width: 349px;
    height: 276px;
    /* left: 20px; */
    top: 190px;
    overflow: visible;
}
#caard_bg_lbl_candi {
    fill: rgba(255,255,255,1);
    stroke: rgba(67,161,255,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.caard_bg_lbl_candi {
    filter: drop-shadow(8px 6px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 361.5px;
    height: 286.5px;
    left: 0px;
    top: 0px;
}
#Diamond_District_lbl_candi {
    left: 140px;
    top: 84px;
    position: absolute;
    overflow: visible;
    width: 127px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#Customer_Care_candi {
    left: 140px;
    top: 106px;
    position: absolute;
    overflow: visible;
    width: 186px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#exp_yr_llbl_vac_candi {
    left: 140px;
    top: 128px;
    position: absolute;
    overflow: visible;
    width: 164px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#n_2000_lbl_candi {
    left: 140px;
    top: 150px;
    position: absolute;
    overflow: visible;
    width: 150px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#Office_Name_lbl_vac_candi {
    left: 28px;
    top: 84px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#company_name_lbl_candi {
    left: 28px;
    top: 106px;
    position: absolute;
    overflow: visible;
    width: 74px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#lbl_vac_exp_candi {
    left: 28px;
    top: 128px;
    position: absolute;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#Salary_Range_lbl_candi {
    left: 28px;
    top: 150px;
    position: absolute;
    overflow: visible;
    width: 103px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#share_grp_btn_candi:hover {
    left: 289px;
    top: 211px;
}
#share_grp_btn_candi {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 285px;
    top: 208px;
    overflow: visible;
    border-radius: 50px;
    cursor: pointer;
    transition: all .3s ease-out;
}
#image_bdr_ba {
    fill: rgba(255,255,255,1);
}
.image_bdr_ba {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 51.5px;
    height: 49.5px;
    left: 0px;
    top: 0px;
}
#share_img_bb {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 8px;
    top: 8px;
    overflow: visible;
}
#view_more_JD_candi:hover {
    left: 28px;
    top: 214px;
}
#view_more_JD_candi {
    position: absolute;
    width: 59px;
    height: 39px;
    left: 21px;
    top: 209px;
    overflow: visible;
    cursor: pointer;
    transition: all .3s ease-out;
}
#jd_bdr {
    fill: rgba(255,255,255,1);
}
.jd_bdr {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 70.5px;
    height: 48.5px;
    left: 0px;
    top: 0px;
    cursor: pointer;
}
#JDKS_lbl_cus {
    left: 11px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 39px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(64,112,147,1);
}
#apply_btn_grp_candi:hover {
    left: 107px;
    top: 214px;
}
#apply_btn_grp_candi {
    position: absolute;
    width: 156px;
    height: 39px;
    left: 100px;
    top: 209px;
    overflow: visible;
    cursor: pointer;
    transition: all .3s ease-out;
}
#Rectangle_114_bs {
    fill: rgba(255,255,255,1);
}
.Rectangle_114_bs {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 167.5px;
    height: 48.5px;
    left: 0px;
    top: 0px;
}
#Group_apply_button {
    position: absolute;
    width: 76px;
    height: 30px;
    left: 40px;
    top: 5px;
    overflow: visible;
}
#success_bu_can {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Apply_bv {
    left: 33px;
    top: 4px;
    position: absolute;
    overflow: visible;
    width: 44px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Grassroots_BPO_lbl_candi {
    left: 28px;
    top: 23px;
    position: absolute;
    overflow: visible;
    width: 198px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#n_candi_line {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.n_candi_line {
    overflow: visible;
    position: absolute;
    width: 295px;
    height: 1px;
    left: 27.5px;
    top: 58.5px;
    transform: matrix(1,0,0,1,0,0);
}
#report_btn_grp_candi:hover {
    left: 285px;
    top: 12px;
}
#report_btn_grp_candi {
    position: absolute;
    width: 52px;
    height: 39px;
    left: 282px;
    top: 9px;
    overflow: visible;
    cursor: pointer;
    transition: all .3s ease-out;
}
#report_btn_rec {
    fill: rgba(255,255,255,1);
}
.report_btn_rec {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 63.5px;
    height: 48.5px;
    left: 0px;
    top: 0px;
}
#warning_report {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 13px;
    top: 7px;
    overflow: visible;
}

#city_name_state {
    left: 140px;
    top: 172px;
    position: absolute;
    overflow: visible;
    width: 154px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#city_lbl_cce {
    left: 28px;
    top: 172px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}