.mediaViewInfo {
    --web-view-name: applications pc candi header;
    --web-view-id: applications_pc_candi_header;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: applications_pc_candi_header;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#applications_pc_candi_header {
    position: relative;
    width: 100%;
    height: 83px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: applications pc candi header;
    --web-view-id: applications_pc_candi_header;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#left_grp_pplication {
    position: absolute;
    width: 545.423px;
    height: 76px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#left_corner_application {
    fill: rgba(100,149,237,1);
}
.left_corner_application {
    overflow: visible;
    position: absolute;
    width: 545.423px;
    height: 70.996px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#bizibeesjpg_application {
    position: absolute;
    width: 130px;
    height: 48px;
    left: 18px;
    top: 28px;
    cursor: pointer;
    overflow: visible;
}
#vacancies_application {
    left: 248px;
    top: 32.887px;
    position: absolute;
    overflow: visible;
    width: 81px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;
    color: rgba(255,255,255,1);
}
#Applications_lbl {
    left: 407px;
    top: 32.939px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(0,0,0,1);
}
#right_grp_application {
    position: absolute;
    width: 175.078px;
    height: 77.411px;
    right: 0px;
    z-index: 100;
    top: -1.411px;
    background-color: white;
    overflow: visible;
}
#right_corner_application {
    fill: rgba(100,149,237,1);
}
.right_corner_application {
    overflow: visible;
    position: absolute;
    width: 175.078px;
    height: 71.411px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#logout_application {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 123px;
    top: 31.411px;
    cursor: pointer;
    overflow: visible;
}
#profile_application {
    position: absolute;
    width: 48px;
    height: 48px;
    left: 55px;
    top: 29.411px;
    cursor: pointer;
    overflow: visible;
}
#centre_header_pc_grp_app {
    position: absolute;
    width: 100%;
    height: 70px;
    left: 545px;
    top: 0px;
    background-color: rgba(100,149,237,1);
    overflow: visible;
}
#centre_header_pc_app {
    fill: rgba(100,149,237,1);
}
.centre_header_pc_app {
    position: absolute;
    overflow: visible;
    width: 630px;
    height: 70px;
    left: 0px;
    top: 0px;
}