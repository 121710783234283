.mediaViewInfo {
    --web-view-name: Company wise pc;
    --web-view-id: Company_wise_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Company_wise_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#Company_wise_pc {
    position: relative;
    width: 100%;
    height: 80%;
    background-color: rgba(255,255,255,1);
    --web-view-name: Company wise pc;
    --web-view-id: Company_wise_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}

#top_grp_overall {
    position: absolute;
    width: 100%;
    height: 179px;
    left: 76px;
    top: 54px;
    overflow: visible;
}
#vacancy_bdr_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 0.5px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.vacancy_bdr_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 170px;
    left: 0px;
    top: 0px;
}
#vaccine_page_img {
    position: absolute;
    width: 179px;
    height: 179px;
    left: 73px;
    top: 0px;
    overflow: visible;
}

#Find_Right_Candidates_bl {
    left: calc(90% - 850px);
    top: 47px;
    position: absolute;
    overflow: visible;
    width: 320px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    color: rgba(36,35,35,1);
    letter-spacing: 0.15px;
}
#vacancy_btn_grp {
    position: absolute;
    width: 137px;
    height: 46px;
    left: calc(90% - 180px);
    top: 12px;
    cursor: pointer;
    overflow: visible;
}
#lbl_vac_img_btn_cx {
    fill: url(#lbl_vac_img_btn_cx);
}
.lbl_vac_img_btn_cx {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 155px;
    height: 64px;
    left: 0px;
    top: 0px;
}
#add_vac_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 16px;
    top: 8px;
    overflow: visible;
}
#Vacancy_lbl {
    left: 53px;
    top: 14px;
    position: absolute;
    overflow: visible;
    width: 59px;
    white-space: nowrap;
    text-align: left;
    font-family: Times New Roman;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#Find_Right_Candidates_portal_restriction {
    left: calc(80% - 900px);
    top: 109px;
    position: absolute;
    overflow: visible;
    width: 846px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(255,0,0,1);
    letter-spacing: 0.15px;
}
#overall_card_grp {
    position: relative;
    width: 349px;
    height: 276px;
    left: 106px;
    top: 236px;
    overflow: visible;
}
#caard_bg_lbl_vac {
    fill: rgba(255,255,255,1);
    stroke: rgba(67,161,255,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.caard_bg_lbl_vac {
    filter: drop-shadow(8px 6px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 361.5px;
    height: 286.5px;
    left: 0px;
    top: 0px;
}
#Grassroots_BPO_lbl_vac {
    left: 30px;
    top: 21px;
    position: absolute;
    overflow: visible;
    width: 198px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#Diamond_District_lbl_vac {
    left: 140px;
    top: 82px;
    position: absolute;
    overflow: visible;
    width: 127px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#Office_Name_lbl_vac {
    left: 23px;
    top: 82px;
    position: absolute;
    overflow: visible;
    width: 98px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#company_name_lbl {
    left: 23px;
    top: 104px;
    position: absolute;
    overflow: visible;
    width: 74px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#Customer_Care_Executive_lbl {
    left: 140px;
    top: 104px;
    position: absolute;
    overflow: visible;
    width: 80px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#lbl_vac_exp {
    left: 23px;
    top: 126px;
    position: absolute;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#exp_yr_llbl_vac {
    left: 140px;
    top: 126px;
    position: absolute;
    overflow: visible;
    width: 164px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#Salary_Range_lbl_vac {
    left: 23px;
    top: 148px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#n_2000_lbl {
    left: 140px;
    top: 148px;
    position: absolute;
    overflow: visible;
    width: 150px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#share_grp_btn:hover {
    left: 282px;
    top: 217px;
}
#share_grp_btn {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 279px;
    top: 213px;
    overflow: visible;
    border-radius: 50px;
    cursor: pointer;
    transition: all .3s ease-out;
}
#image_bdr_bb {
    fill: rgba(255,255,255,1);
}
.image_bdr_bb {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 51.5px;
    height: 49.5px;
    left: 0px;
    top: 0px;
}
#share_img_bc {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 8px;
    top: 8px;
    overflow: visible;
}
#view_grp:hover {
    left: 28px;
    top: 218px;
}
#view_grp {
    position: absolute;
    width: 69px;
    height: 39px;
    left: 23px;
    top: 213px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#bdr_eye {
    fill: rgba(255,255,255,1);
}
.bdr_eye {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 80.5px;
    height: 48.5px;
    left: 0px;
    top: 0px;
}
#eye_img {
    position: absolute;
    width: 33px;
    height: 33px;
    left: 8px;
    top: 3px;
    overflow: visible;
}
#n_22_bk {
    left: 43px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 25px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(64,112,147,1);
}
#edit_grp:hover {
    left: 122px;
    top: 218px;
}
#edit_grp {
    position: absolute;
    width: 58px;
    height: 39px;
    left: 115px;
    top: 213px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#bdr_ {
    fill: rgba(255,255,255,1);
}
.bdr_ {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 69.5px;
    height: 48.5px;
    left: 0px;
    top: 0px;
}
#edit3_img {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 14px;
    top: 5px;
    overflow: visible;
}
#del_grp:hover {
    left: 287px;
    top: 12px;
}
#del_grp {
    position: absolute;
    width: 50px;
    height: 31px;
    left: 284px;
    top: 9px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#del_bdr {
    fill: rgba(255,255,255,1);
}
.del_bdr {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 61.5px;
    height: 40.5px;
    left: 0px;
    top: 0px;
}
#delete_img {
    position: absolute;
    width: 25.424px;
    height: 25.424px;
    left: 12.712px;
    top: 3.974px;
    overflow: visible;
}
#view_more_JD:hover {
    left: 204px;
    top: 218px;
}
#view_more_JD {
    position: absolute;
    width: 59px;
    height: 39px;
    left: 197px;
    top: 213px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#jdks_bdr {
    fill: rgba(255,255,255,1);
}
.jdks_bdr {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 70.5px;
    height: 48.5px;
    left: 0px;
    top: 0px;
}
#JDKS_lbl {
    left: 11px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 39px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(64,112,147,1);
}
#margine_line {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.margine_line {
    overflow: visible;
    position: absolute;
    width: 295px;
    height: 1px;
    left: 27.5px;
    top: 56.5px;
    transform: matrix(1,0,0,1,0,0);
}

#status_lbl_com_rec {
    left: 23px;
    top: 170px;
    position: absolute;
    overflow: visible;
    width: 48px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(112,112,112,1);
}
#approved_lbl_rec {
    left: 140px;
    top: 170px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(112,112,112,1);
}