.mediaViewInfo {
    --web-view-name: pop up jdks;
    --web-view-id: pop_up_jdks;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: pop_up_jdks;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#pop_up_jdks {
    position: absolute;
    width: 828px;
    height: 490px;
    left: 50%;
    transform: translate(-50%, 30%);
     border-radius: 10px; 
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: pop up jdks;
    --web-view-id: pop_up_jdks;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#overall_bg_pc {
    opacity: 0.5;
    position: absolute;
    width: 828px;
    height: 490px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#card_2_bg_color {
    fill: rgba(255,122,122,1);
}
.card_2_bg_color {
    position: absolute;
    overflow: visible;
    width: 355px;
    height: 15px;
    left: 27px;
    top: 428px;
}
#card_2_bdr {
    fill: rgba(255,255,255,1);
}
.card_2_bdr {
    position: absolute;
    overflow: visible;
    width: 355px;
    height: 321px;
    left: 28px;
    top: 117px;
}
#card_1_bg_color {
    fill: rgba(130,255,221,1);
}
.card_1_bg_color {
    position: absolute;
    overflow: visible;
    width: 355px;
    height: 15px;
    left: 429px;
    top: 428px;
}
#card_1_bdr {
    fill: rgba(255,255,255,1);
}
.card_1_bdr {
    position: absolute;
    overflow: visible;
    width: 355px;
    height: 321px;
    left: 429px;
    top: 117px;
}
#Grassroots_BPO_lbl {
    /* left: 342px; */
    top: 20px;
    position: absolute;
    overflow: visible;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(0,0,0,1);
    text-decoration: underline;
}
#Group_overall_jd {
    position: absolute;
    width: 328px;
    height: 355px;
    left: 42px;
    top: 70px;
    overflow: visible;
}
#Group_Js_desandName {
    position: absolute;
    width: 328px;
    height: 341px;
    left: 0px;
    top: 14px;
    overflow: visible;
}
#JD_desc_grp_pc {
    position: absolute;
    width: 328px;
    height: 308px;
    left: 0px;
    top: 33px;
    overflow-y: scroll;
}
/* #JD_desc_grp_pc {
    position: absolute;
    width: 328px;
    height: 308px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#blablabla_js_pc_ {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 329px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
} */
#Job_Description_lbl_pc {
    left: 79px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 152px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#Group_copy_jd_pc {
    position: absolute;
    width: 46px;
    height: 39px;
    left: 241px;
    top: 0px;
    cursor: pointer;
    overflow: visible;
}
#btn_jd_pc {
    fill: rgba(255,255,255,1);
}
.btn_jd_pc {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 57.5px;
    height: 48.5px;
    left: 0px;
    top: 0px;
}
#file_jd_img_pc {
    position: absolute;
    width: 34px;
    height: 34px;
    left: 6px;
    top: 3px;
    overflow: visible;
}
#close1_btn_pop_art {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 790px;
    top: 12px;
    cursor: pointer;
    overflow: visible;
}
#ks_full_copy {
    position: absolute;
    width: 346px;
    height: 250px;
    left: 439px;
    top: 70px;
    overflow: visible;
}
#ks_and_desc {
    position: absolute;
    width: 346px;
    height: 236px;
    left: 0px;
    top: 14px;
    overflow: visible;
}
#Key_Skills_pc_lbl {
    left: 106px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 95px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#ks_desc {
    position: absolute;
    width: 346px;
    height: 308px;
    left: 0px;
    top: 38px;
    font-family: Segoe UI;
    font-style: normal;
    /* font-weight: normal;
    font-size: 17px; */
    overflow-y: scroll;
}
/* #KS_info_pop {
    position: absolute;
    width: 346px;
    height: 198px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#blblabla_ks_bl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 347px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(37,37,37,1);
} */
#copy_ks_grp {
    position: absolute;
    width: 46px;
    height: 39px;
    left: 219px;
    top: 0px;
    cursor: pointer;
    overflow: visible;
}
#copy_btn_ks {
    fill: rgba(255,255,255,1);
}
.copy_btn_ks {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 57.5px;
    height: 48.5px;
    left: 0px;
    top: 0px;
}
#copy_img_pc {
    position: absolute;
    width: 34px;
    height: 34px;
    left: 6px;
    top: 3px;
    overflow: visible;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffff; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #6495ED; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  } 