.mediaViewInfo {
    --web-view-name: applicant details;
    --web-view-id: applicant_details;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: applicant_details;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#applicant_details {
    position: absolute;
    width: 430px;
    height: 2301px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    left: 50%;
    transform: translate(-50%, 0%);
    --web-view-name: applicant details;
    --web-view-id: applicant_details;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
@keyframes fadein {

    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}
#n_291450_dv {
    position: absolute;
    width: 1476px;
    height: 2213px;
    left: 0px;
    top: 85px;
    overflow: visible;
}
#n_7973908_dw {
    position: absolute;
    width: 393.995px;
    height: 2101.163px;
    left: 18.971px;
    top: 173.375px;
    overflow: visible;
}
#Applied_Job_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}

.Applied_Job_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 71px;
    left: 86px;
    top: 223px;
}
#applicant_name_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_name_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 275px;
    height: 71px;
    left: 88px;
    top: 305px;
}
#Applied_Job {
    opacity: 0.75;
    left: 170px;
    top: 240px;
    position: absolute;
    overflow: visible;
    width: 87px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_12 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_12 {
    overflow: visible;
    position: absolute;
    width: 80px;
    height: 1px;
    left: 170.5px;
    top: 263.5px;
    transform: matrix(1,0,0,1,0,0);
}
#Name {
    opacity: 0.75;
    left: 194px;
    top: 317px;
    position: absolute;
    overflow: visible;
    width: 69px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_13 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_13 {
    overflow: visible;
    position: absolute;
    width: 39px;
    height: 1px;
    left: 195px;
    top: 338px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_exp_months {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_exp_months {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 137px;
    height: 63px;
    left: 231px;
    top: 729px;
}
#Experience_app {
    opacity: 0.75;
    left: 183.75px;
    top: 695.786px;
    position: absolute;
    overflow: visible;
    width: 72px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_14 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_14 {
    overflow: visible;
    position: absolute;
    width: 71.007px;
    height: 1.5px;
    left: 183.25px;
    top: 719.286px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_language_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_language_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 330px;
    height: 56px;
    left: 60px;
    top: 795px;
}
#Languages_d {
    opacity: 0.75;
    left: 176.5px;
    top: 803.39px;
    position: absolute;
    overflow: visible;
    width: 137px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_15 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_15 {
    overflow: visible;
    position: absolute;
    width: 72px;
    height: 1px;
    left: 176.5px;
    top: 822.716px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_Mobile_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_Mobile_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 277px;
    height: 71px;
    left: 86px;
    top: 378px;
}
#Mobile_No {
    opacity: 0.75;
    left: 183px;
    top: 393px;
    position: absolute;
    overflow: visible;
    width: 84px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_16 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_16 {
    overflow: visible;
    position: absolute;
    width: 68px;
    height: 1px;
    left: 185px;
    top: 414px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_gender_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_gender_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 163px;
    height: 51px;
    left: 80px;
    top: 464px;
}
#Gender_ea {
    opacity: 0.75;
    left: 117px;
    top: 480px;
    position: absolute;
    overflow: visible;
    width: 69px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_17 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_17 {
    overflow: visible;
    position: absolute;
    width: 48px;
    height: 1px;
    left: 118px;
    top: 501px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_email_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_email_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 273px;
    height: 71px;
    left: 90px;
    top: 622px;
}
#Email {
    opacity: 0.75;
    left: 199px;
    top: 637px;
    position: absolute;
    overflow: visible;
    width: 47px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_18 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_18 {
    overflow: visible;
    position: absolute;
    width: 37px;
    height: 1px;
    left: 198px;
    top: 658px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_wht_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_wht_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 275px;
    height: 71px;
    left: 88px;
    top: 543px;
}
#WhatsApp_No {
    opacity: 0.75;
    left: 163px;
    top: 559px;
    position: absolute;
    overflow: visible;
    width: 97px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_19 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_19 {
    overflow: visible;
    position: absolute;
    width: 93px;
    height: 1px;
    left: 163px;
    top: 580px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_DOB_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_DOB_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 143px;
    height: 71px;
    left: 230px;
    top: 464px;
}
#DOB {
    opacity: 0.75;
    left: 250px;
    top: 481px;
    position: absolute;
    overflow: visible;
    width: 84px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_20 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_20 {
    overflow: visible;
    position: absolute;
    width: 29px;
    height: 1px;
    left: 251px;
    top: 502px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_exp_years {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_exp_years {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 143px;
    height: 63px;
    left: 90px;
    top: 729px;
}
#Years {
    opacity: 0.75;
    left: 125px;
    top: 742px;
    position: absolute;
    overflow: visible;
    width: 97px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Months {
    opacity: 0.75;
    left: 248px;
    top: 742px;
    position: absolute;
    overflow: visible;
    width: 97px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_21 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_21 {
    overflow: visible;
    position: absolute;
    width: 34px;
    height: 1px;
    left: 125px;
    top: 761px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_22 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_22 {
    overflow: visible;
    position: absolute;
    width: 51px;
    height: 1px;
    left: 248px;
    top: 761px;
    transform: matrix(1,0,0,1,0,0);
}
#Applicant_path {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Applicant_path {
    overflow: visible;
    position: absolute;
    z-index: -1;
    width: 395.998px;
    height: 2109.766px;
    left: 18.971px;
    top: 170.48px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_expsalary_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_expsalary_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 143px;
    height: 71px;
    left: 235px;
    top: 1005px;
}
#applicant_current {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_current {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 143px;
    height: 71px;
    left: 90px;
    top: 1005px;
}
#Currently_Salary {
    opacity: 0.75;
    left: 105px;
    top: 1022px;
    position: absolute;
    overflow: visible;
    width: 114px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Expected_Salary {
    opacity: 0.75;
    left: 247px;
    top: 1022px;
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_23 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_23 {
    overflow: visible;
    position: absolute;
    width: 104px;
    height: 1px;
    left: 105px;
    top: 1044px;
    transform: matrix(1,0,0,1,0,0);
}
#Line_24 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_24 {
    overflow: visible;
    position: absolute;
    width: 103px;
    height: 1px;
    left: 248px;
    top: 1044px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_address_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_address_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 281px;
    height: 79px;
    left: 90px;
    top: 1228px;
}
#Address {
    opacity: 0.75;
    left: 200px;
    top: 1248.284px;
    position: absolute;
    overflow: visible;
    width: 87px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_25 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_25 {
    overflow: visible;
    position: absolute;
    width: 54px;
    height: 1px;
    left: 200.5px;
    top: 1268.475px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_skill_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_skill_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 281px;
    height: 79px;
    left: 90px;
    top: 1303px;
}
#Skills {
    opacity: 0.75;
    left: 210px;
    top: 1318.5px;
    position: absolute;
    overflow: visible;
    width: 87px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_26 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_26 {
    overflow: visible;
    position: absolute;
    width: 33px;
    height: 1px;
    left: 210.5px;
    top: 1338.5px;
    transform: matrix(1,0,0,1,0,0);
}
#Preferences_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 85px;
    top: 1388px;
    position: absolute;
    overflow: visible;
    width: 188px;
    height: 47px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(255,79,90,1);
}

#Willing_to_Relocate_Preference_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Willing_to_Relocate_Preference_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 105px;
    top: 1987px;
}
#Willing_to_Relocate_lbl_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 134px;
    top: 1985px;
    position: absolute;
    overflow: visible;
    width: 176px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#Shifts_f_app {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 98px;
    top: 1751px;
    position: absolute;
    overflow: visible;
    width: 71px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
}

#schedule_path_Pointer {
    fill: rgba(100, 149, 237);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
    transition: all 0.30000001192092896s ease-out;
    --web-animation: fadein 0.30000001192092896s ease-out;
    --web-action-type: page;
    --web-action-target: schedule_interviews.html;
    cursor: pointer;
}
.schedule_path_Pointer {
    position: absolute;
    overflow: visible;
    width: 14px;
    height: 14px;
    left: 81px;
    top: 152px;
}
#Line_27 {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_27 {
    overflow: visible;
    position: absolute;
    width: 234px;
    height: 1px;
    left: 101.5px;
    top: 159px;
    transform: matrix(1,0,0,1,0,0);
}
#Applicant_Path_Pointer {
    fill: rgba(100, 149, 237);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Applicant_Path_Pointer {
    position: absolute;
    overflow: visible;
    width: 14px;
    height: 14px;
    left: 345px;
    top: 152px;
}
#scheduleInterview_Page {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 73px;
    top: 117px;
    overflow: visible;
    transition: all 0.6000000238418579s ease-in-out;
    --web-animation: fadein 0.6000000238418579s ease-in-out;
    --web-action-type: page;
    --web-action-target: schedule_interviews.html;
    cursor: pointer;
}
#user4_f {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 337px;
    top: 117px;
    overflow: visible;
}
#Border_ApplicantPopup {
    fill: rgb(100, 149, 237);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Border_ApplicantPopup {
    position: absolute;
    overflow: visible;
    width: 539px;
    height: 85px;
    left: -54px;
    top: 0px;
}
#Name_Border {
    fill: url(#Name_Border);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Name_Border {
    filter: drop-shadow(7px 7px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 400px;
    height: 83px;
    left: 16px;
    top: 42px;
}
#Username_App_gc {
    left: 23px;
    top: 61px;
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
   
}
#contact_Number_App {
    left: 280px;
    top: 61px;
    position: absolute;
    overflow: visible;
    width: 124px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Applicant_CancelBtn {
    fill: rgba(255,255,255,1);
}
.Applicant_CancelBtn {
    position: absolute;
    overflow: visible;
    width: 29px;
    height: 29px;
    left: 398px;
    top: 3px;
}
#cancel1_gf {
    position: absolute;
    width: 35px;
    height: 35px;
    left: 395px;
    top: 0px;
    overflow: visible;
}
#applicant_city_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_city_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 282px;
    height: 79px;
    z-index: 10;
    left: 90px;
    top: 1078px;
}
#City_gh {
    opacity: 0.75;
    left: 207px;
    top: 1098.284px;
    position: absolute;
    overflow: visible;
    width: 34px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_28 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_28 {
    overflow: visible;
    position: absolute;
    width: 26px;
    height: 1px;
    left: 207.5px;
    top: 1119.5px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_area_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_area_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 281px;
    height: 79px;
    left: 90px;
    top: 1153px;
}
#Area_gk {
    opacity: 0.75;
    left: 205px;
    top: 1168.5px;
    position: absolute;
    overflow: visible;
    width: 35px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_29 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_29 {
    overflow: visible;
    position: absolute;
    width: 33px;
    height: 1px;
    left: 205.5px;
    top: 1188.5px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_reference_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_reference_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 281px;
    height: 70px;
    left: 92px;
    top: 2032px;
}
#Reference {
    opacity: 0.75;
    left: 190px;
    top: 2047.5px;
    position: absolute;
    overflow: visible;
    width: 68px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_30 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_30 {
    overflow: visible;
    position: absolute;
    width: 66px;
    height: 1px;
    left: 191.5px;
    top: 2067.5px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_education_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_education_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 300px;
    height: 71px;
    left: 85px;
    top: 935px;
}
#City_gq {
    opacity: 0.75;
    left: 209.043px;
    top: 951.149px;
    position: absolute;
    overflow: visible;
    width: 34px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(112,112,112,1);
}
#Line_31 {
    opacity: 0.75;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_31 {
    overflow: visible;
    position: absolute;
    width: 26px;
    height: 1px;
    left: 209.592px;
    top: 970.814px;
    transform: matrix(1,0,0,1,0,0);
}
#applicant_check_schedule_intw {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.applicant_check_schedule_intw {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
  
    width: 20px;
    height: 20px;
    left: 127px;
    top: 2120px;
}
#Schedule_Interview_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 150px;
    top: 2125px;
    position: absolute;
    overflow: visible;
    width: 200px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Edit_btn_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Edit_btn_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 137px;
    height: 71px;
    left: 156px;
    top: 2210px;
}
#EDIT {
    left: 194px;
    top: 2223px;
    position: absolute;
    overflow: visible;
    width: 43px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
