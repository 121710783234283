#chkVoice_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkVoice_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 104px;
    top: 1455px;
}
#WPVoice_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 127px;
    top: 1451px;
    position: absolute;
    overflow: visible;
    width: 64px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Non_VoicePreference_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Non_VoicePreference_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 228px;
    top: 1455px;
}
#Non-Voice_lbl_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 251px;
    top: 1451px;
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#Semi-VoicePreference_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Semi-VoicePreference_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 104px;
    top: 1490px;
}
#Semi-Voice_candidate_Lbl_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 127px;
    top: 1486px;
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Employment_Type_fs_app {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 95px;
    top: 1526px;
    position: absolute;
    overflow: visible;
    width: 201px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
}
#FullTime_Preference_app_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.FullTime_Preference_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 102px;
    top: 1570px;
}
#Full_Time_Interview_Lbl_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 125px;
    top: 1566px;
    position: absolute;
    overflow: visible;
    width: 92px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#Part_Time_Preference_app_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Part_Time_Preference_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 226px;
    top: 1570px;
}

#Part_Time_Candidate_lbl_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 249px;
    top: 1566px;
    position: absolute;
    overflow: visible;
    width: 107px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#Temporary_Preference_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Temporary_Preference_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 101px;
    top: 1602px;
}
#Temporary_Preference_Lbl_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 124px;
    top: 1598px;
    position: absolute;
    overflow: visible;
    width: 105px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Work_Mode_fh_app {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 95px;
    top: 1630px;
    position: absolute;
    overflow: visible;
    width: 132px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
}
#Office_Preferences_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Office_Preferences_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 104px;
    top: 1675px;
}

#Office_Preference_lbl_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 127px;
    top: 1670px;
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Home_Preferences_app_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Home_Preferences_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 228px;
    top: 1675px;
}

#Home_Preferences_lbl_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 254px;
    top: 1670px;
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#Hybrid_Preference_app_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Hybrid_Preference_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 104px;
    top: 1711px;
}

#Hybrid_Preference_lbl_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 127px;
    top: 1706px;
    position: absolute;
    overflow: visible;
    width: 79px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Day_Preference_app_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Day_Preference_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 105px;
    top: 1793px;
}
#Day_Preference_lbl_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 128px;
    top: 1788px;
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Night_preference_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Night_preference_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 232px;
    top: 1792px;
}
#Night_preference_lbl_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 258px;
    top: 1787px;
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Rotational_Preference_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rotational_Preference_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 105px;
    top: 1830px;
}

#Rotational_Preference_lbl_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 133px;
    top: 1825px;
    position: absolute;
    overflow: visible;
    width: 96px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Transportation_fn_app {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 99px;
    top: 1868px;
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
}

#Own_Transporation_Preference_app {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Own_Transporation_Preference_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 105px;
    top: 1917px;
}

#Own_Transporation_Preference_lbl_app {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 128px;
    top: 1913px;
    position: absolute;
    overflow: visible;
    width: 64px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
#Company_Day_Preference_app_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Company_Day_Preference_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 229px;
    top: 1918px;
}
#Company_Day_Preference_lbl_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 252px;
    top: 1914px;
    position: absolute;
    overflow: visible;
    width: 134px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}


#Company_Night_Preference_app_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Company_Night_Preference_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 105px;
    top: 1952px;
}
#Company_Night_Preference_lbl_app_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 131px;
    top: 1948px;
    position: absolute;
    overflow: visible;
    width: 176px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}
