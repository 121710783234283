.content6{

    background-color: rgb(240, 240, 240);
    margin: auto;
    position: relative;
    min-width: 375px;
    max-width: 495px;
    height: auto;
    top:0;
    left:0;
}

.fnt2{
    font-family: 'Times New Roman', Times, serif;
    color:white;
    background-color:cornflowerblue;
       
    }