.mediaViewInfo {
    --web-view-name: filter;
    --web-view-id: filter;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: filter;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#filter {
    position: absolute;
    width: 413px;
    height: 903px;
    left: 50%;
    transform: translate(-50%, -2%);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(4,51,67,1) 100%);
    overflow: hidden;
    --web-view-name: filter;
    --web-view-id: filter;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-enable-scale-up: true;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
@keyframes fadein {

    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}
#Welcome_ftr {
    left: 373px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 154px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(37,37,37,1);
}
#user_name_lbl_ftr {
    left: 373px;
    top: 65px;
    position: absolute;
    overflow: visible;
    width: 376px;
    height: 47px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(37,37,37,1);
}
#Group_fltr {
    position: absolute;
    width: 369px;
    height: 741px;
    left: 377px;
    top: 127px;
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: employee_dashboard.html;
    cursor: pointer;
}
#dashboard_profile_outer_line_fltr {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.dashboard_profile_outer_line_fltr {
    position: absolute;
    overflow: visible;
    width: 369px;
    height: 163px;
    left: 0px;
    top: 0px;
}
#call_time_outer_line_fltr {
    fill: rgba(255,255,255,1);
    stroke: rgba(146,105,239,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.call_time_outer_line_fltr {
    position: absolute;
    overflow: visible;
    width: 163px;
    height: 163px;
    left: 0px;
    top: 578px;
}
#CallIdle_Time_lbl_fltr {
    left: 9px;
    top: 646px;
    position: absolute;
    overflow: visible;
    width: 155px;
    height: 28px;
    text-align: left;
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(112,112,112,1);
}
#Hr_Screening_fltr {
    fill: rgba(255,255,255,1);
    stroke: rgba(146,105,239,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Hr_Screening_fltr {
    position: absolute;
    overflow: visible;
    width: 163px;
    height: 163px;
    left: 0px;
    top: 194px;
}
#passed_img_fltr {
    position: absolute;
    width: 49px;
    height: 49px;
    left: 18px;
    top: 244px;
    overflow: visible;
}
#Called_fltr {
    fill: rgba(255,255,255,1);
    stroke: rgba(146,105,239,1);
    stroke-width: 3px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Called_fltr {
    position: absolute;
    overflow: visible;
    width: 163px;
    height: 163px;
    left: 0px;
    top: 385px;
}
#Called {
    left: 45px;
    top: 453px;
    position: absolute;
    overflow: visible;
    width: 70px;
    height: 28px;
    text-align: left;
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(112,112,112,1);
}
#customer-service1 {
    position: absolute;
    width: 49px;
    height: 49px;
    left: 39px;
    top: 401px;
    overflow: visible;
}
#time-call_img {
    position: absolute;
    width: 49px;
    height: 49px;
    left: 39px;
    top: 589px;
    overflow: visible;
}
#Current_Candidate_lbl_fltr {
    left: 14px;
    top: 26px;
    position: absolute;
    overflow: visible;
    width: 119px;
    height: 27px;
    text-align: left;
    font-family: Times New Roman;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(37,37,37,1);
}
#call_time_val_fltr {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.call_time_val_fltr {
    position: absolute;
    overflow: visible;
    width: 117px;
    height: 37px;
    left: 24px;
    top: 685px;
}
#called_val_fltr {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.called_val_fltr {
    position: absolute;
    overflow: visible;
    width: 117px;
    height: 37px;
    left: 24px;
    top: 492px;
}
#n_ {
    left: 76px;
    top: 687px;
    position: absolute;
    overflow: visible;
    width: 14px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(37,37,37,1);
}
#Group_1 {
    position: absolute;
    width: 52px;
    height: 37px;
    left: 17px;
    top: 300px;
    overflow: visible;
}
#passed_val_fltr {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.passed_val_fltr {
    position: absolute;
    overflow: visible;
    width: 52px;
    height: 37px;
    left: 0px;
    top: 0px;
}
#n__ {
    left: 20px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 14px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(37,37,37,1);
}
#n__ba {
    left: 73px;
    top: 494px;
    position: absolute;
    overflow: visible;
    width: 14px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(37,37,37,1);
}
#setting {
    position: absolute;
    width: 52px;
    height: 52px;
    left: 51.75px;
    top: 83.75px;
    overflow: visible;
    transition: all 0.4000000059604645s ease-in-out;
    --web-animation: fadein 0.4000000059604645s ease-in-out;
    --web-action-type: page;
    --web-action-target: employee_dashboard.html;
    cursor: pointer;
}
#HR_Screening_fltr {
    left: 26px;
    top: 209px;
    position: absolute;
    overflow: visible;
    width: 125px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#FilterBtn_fltr {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.FilterBtn_fltr {
    position: absolute;
    overflow: visible;
    width: 130px;
    height: 43px;
    left: 138px;
    top: 755px;
}
#Filter_ {
    left: 158px;
    top: 765px;
    position: absolute;
    overflow: visible;
    width: 42px;
    height: 24px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: rgba(37,37,37,1);
}
#Cityfltr_txt {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Cityfltr_txt {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 47px;
    left: 74px;
    top: 129px;
}
#Areafltr_txt {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Areafltr_txt {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 47px;
    left: 74px;
    top: 206px;
}
#Educationfltr_txt {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Educationfltr_txt {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 47px;
    left: 74px;
    top: 287px;
}
#City_fltr {
    left: 85.884px;
    top: 139px;
    position: absolute;
    overflow: visible;
    width: 35px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#Education_fltr {
    left: 85.6px;
    top: 294.5px;
    position: absolute;
    overflow: visible;
    width: 88px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#Languagefltr_txt {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Languagefltr_txt {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 47px;
    left: 74px;
    top: 365px;
}
#Salaryfltr_txt {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Salaryfltr_txt {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 47px;
    left: 74px;
    top: 441px;
}
#Experiencefltr_txt {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Experiencefltr_txt {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 47px;
    left: 74px;
    top: 522px;
}
#Area_fltr {
    left: 85.884px;
    top: 216px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#Salary_not_exceeding {
    left: 86px;
    top: 451px;
    position: absolute;
    overflow: visible;
    width: 184px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#Experience_greater_than {
    left: 85.6px;
    top: 532.5px;
    position: absolute;
    overflow: visible;
    width: 210px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#NoticePeriodfltr_txt {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.NoticePeriodfltr_txt {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 47px;
    left: 74px;
    top: 597px;
}
#Refferedbyfltr_txt {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Refferedbyfltr_txt {
    position: absolute;
    overflow: visible;
    width: 250px;
    height: 47px;
    left: 74px;
    top: 678px;
}
#Notice_period_below {
    left: 88px;
    top: 607px;
    position: absolute;
    overflow: visible;
    width: 181px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#Referred_by {
    left: 87.6px;
    top: 685.5px;
    position: absolute;
    overflow: visible;
    width: 103px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#Language_fltr {
    left: 86px;
    top: 374px;
    position: absolute;
    overflow: visible;
    width: 87px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}
#CityFltr_chck {
    fill: rgba(255,255,255,1);
}
.CityFltr_chck {
    position: absolute;
    overflow: visible;
    transform: scale(3.0);
    width: 25px;
    height: 25px;
    left: 25px;
    top: 138px;
}
#AreaFltr_chck {
    fill: rgba(255,255,255,1);
}
.AreaFltr_chck {
    position: absolute;
    overflow: visible;
    transform: scale(3.0);
    width: 25px;
    height: 25px;
    left: 25px;
    top: 215px;
}
#EducationFltr_chck {
    fill: rgba(255,255,255,1);
}
.EducationFltr_chck {
    position: absolute;
    overflow: visible;
    transform: scale(3.0);
    width: 25px;
    height: 25px;
    left: 25px;
    top: 296px;
}
#Languagefltr_chck {
    fill: rgba(255,255,255,1);
}
.Languagefltr_chck {
    position: absolute;
    overflow: visible;
    transform: scale(3.0);
    width: 25px;
    height: 25px;
    left: 25px;
    top: 373px;
}
#Salaryfltr_chck {
    fill: rgba(255,255,255,1);
}
.Salaryfltr_chck {
    position: absolute;
    overflow: visible;
    transform: scale(3.0);
    width: 25px;
    height: 25px;
    left: 25px;
    top: 449px;
}
#Experiencechck_fltr {
    fill: rgba(255,255,255,1);
}
.Experiencechck_fltr {
    position: absolute;
    overflow: visible;
    transform: scale(3.0);
    width: 25px;
    height: 25px;
    left: 25px;
    top: 531px;
}
#NoticePeriodfltr_chck {
    fill: rgba(255,255,255,1);
}
.NoticePeriodfltr_chck {
    position: absolute;
    overflow: visible;
    transform: scale(3.0);
    width: 25px;
    height: 25px;
    left: 25px;
    top: 606px;
}
#Refferedby_fltrchck {
    fill: rgba(255,255,255,1);
}
.Refferedby_fltrchck {
    position: absolute;
    overflow: visible;
    transform: scale(3.0);
    width: 25px;
    height: 25px;
    left: 25px;
    top: 684px;
}
#Rectangle_42_fltr {
    fill: url(#Rectangle_42_bw);
}
.Rectangle_42_fltr {
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 903px;
    left: 324px;
    top: 0px;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: employee_dashboard.html;
    cursor: pointer;
}