	.Empoyee_Dashi_MobmediaViewInfo {
		--web-view-name: employee dashboard;
		--web-view-id: employee_dashboard;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-enable-scale-up: true;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: employee_dashboard;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#Empoyee_Dashi_Mobemployee_dashboard {
		position: absolute;
		width: 430px;
		height: 821px;
    left: 50%;
    transform: translate(-50%, 0%);
		background: linear-gradient(180.6deg, rgba(255,255,255,1) 0%, rgba(4,51,67,1) 100%);
		/* overflow-x: hidden;
		overflow-y: auto; */
		--web-view-name: employee dashboard;
		--web-view-id: employee_dashboard;
		--web-scale-to-fit: true;
		--web-scale-to-fit-type: width;
		--web-enable-scale-up: true;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}

  #Empoyee_Dashi_Mobemployee_dashboard ::-webkit-scrollbar {
    display: none;
  }
	#Empoyee_Dashi_MobGroup_8 {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 364px;
		top: 16px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobchat_btn {
		fill: rgba(255,255,255,1);
	}
	.Empoyee_Dashi_Mobchat_btn {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 58px;
		height: 58px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_Mobchat_lbl {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 9px;
		top: 9px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobGroup_11 {
		position: absolute;
		width: 370px;
		height: 27px;
		left: 30px;
		top: 76px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobWelcome_1 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#Empoyee_Dashi_Mobuser_name_lbl {
		left: 87px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 284px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#Empoyee_Dashi_MobGroup_17 {
		position: absolute;
		width: 163px;
		height: 163px;
		left: 33px;
		top: 643px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobcall_time_outer_line {
		fill: rgba(255,255,255,1);
	}
	.Empoyee_Dashi_Mobcall_time_outer_line {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 171px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_MobCallIdle_Time_lbl {
		left: 15px;
		top: 68px;
		position: absolute;
		overflow: visible;
		width: 134px;
		white-space: nowrap;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
	}
	#Empoyee_Dashi_Mobtime-call_img {
		position: absolute;
		width: 49px;
		height: 49px;
		left: 57px;
		top: 11px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobGroup_22 {
		position: absolute;
		width: 117px;
		height: 37px;
		left: 23px;
		top: 107px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobcall_time_val {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Empoyee_Dashi_Mobcall_time_val {
		position: absolute;
		overflow: visible;
		width: 117px;
		height: 37px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_Mobn_ {
		/* left: 52px; */
		top: 2px;
		position: absolute;
		overflow: hidden;
		width: 100%;
    border-radius: 15px;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(37,37,37,1);
	}
	#Empoyee_Dashi_MobGroup_18 {
		position: absolute;
		width: 163px;
		height: 163px;
		left: 241px;
		top: 643px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobverified_outer_line {
		fill: rgba(255,255,255,1);
	}
	.Empoyee_Dashi_Mobverified_outer_line {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 171px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_MobVerified_lbl {
		left: 10px;
		top: 72px;
		position: absolute;
		overflow: visible;
		width: 148px;
		white-space: nowrap;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		color: rgba(112,112,112,1);
	}
	#Empoyee_Dashi_Mobverified_img {
		position: absolute;
		width: 49px;
		height: 49px;
		left: 58px;
		top: 11px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobGroup_23 {
		position: absolute;
		width: 117px;
		height: 37px;
		left: 25px;
		top: 107px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobverified_val {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Empoyee_Dashi_Mobverified_val {
		position: absolute;
		overflow: visible;
		width: 117px;
		height: 37px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_Mobn__ {
		/* left: 52px; */
		top: 2px;
		position: absolute;
		overflow: hidden;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(37,37,37,1);
	}
	#Empoyee_Dashi_MobGroup_16 {
		position: absolute;
		width: 163px;
		height: 163px;
		left: 33px;
		top: 450px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobRectangle_10 {
		fill: rgba(255,255,255,1);
	}
	.Empoyee_Dashi_MobRectangle_10 {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 171px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_MobCalled {
		left: 52px;
		top: 74px;
		position: absolute;
		overflow: visible;
		width: 61px;
		white-space: nowrap;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
	}
	#Empoyee_Dashi_Mobcustomer-service1 {
		position: absolute;
		width: 49px;
		height: 49px;
		left: 54px;
		top: 16px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobGroup_20 {
		position: absolute;
		width: 117px;
		height: 37px;
		left: 23px;
		top: 107px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobcalled_val {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Empoyee_Dashi_Mobcalled_val {
		position: absolute;
		overflow: visible;
		width: 117px;
		height: 37px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_Mobn__bb {
		/* left: 49px; */
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(37,37,37,1);
	}
	#Empoyee_Dashi_MobGroup_15 {
		position: absolute;
		width: 163px;
		height: 163px;
		left: 241px;
		top: 450px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobRectangle_11 {
		fill: rgba(255,255,255,1);
	}
	.Empoyee_Dashi_MobRectangle_11 {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 171px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_MobAvailable {
		left: 42px;
		top: 74px;
		position: absolute;
		overflow: visible;
		width: 85px;
		white-space: nowrap;
		text-align: left;
		font-family: Raleway;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
	}
	#Empoyee_Dashi_Mobavailable {
		position: absolute;
		width: 49px;
		height: 49px;
		left: 59px;
		top: 16px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobGroup_21 {
		position: absolute;
		width: 117px;
		height: 37px;
		left: 25px;
		top: 107px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobavailable_val {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Empoyee_Dashi_Mobavailable_val {
		position: absolute;
		overflow: visible;
		width: 117px;
		height: 37px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_Mobn__bi {
		/* left: 50px; */
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 22px;
		color: rgba(37,37,37,1);
	}
	#Empoyee_Dashi_MobGroup_14 {
		position: absolute;
		width: 163px;
		height: 163px;
		left: 33px;
		top: 259px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobRectangle_8 {
		fill: rgba(255,255,255,1);
	}
	.Empoyee_Dashi_MobRectangle_8 {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 171px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_Mobfailed_img {
		position: absolute;
		width: 49px;
		height: 49px;
		left: 91px;
		top: 50px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobpassed_img {
		position: absolute;
		width: 49px;
		height: 49px;
		left: 28px;
		top: 50px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobGroup_1 {
		position: absolute;
		width: 52px;
		height: 37px;
		left: 23px;
		top: 106px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobpassed_val {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Empoyee_Dashi_Mobpassed_val {
		position: absolute;
		overflow: visible;
		width: 52px;
		height: 37px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_Mobn__bp {
		/* left: 20px; */
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(37,37,37,1);
	}
	#Empoyee_Dashi_MobGroup_2 {
		position: absolute;
		width: 52px;
		height: 37px;
		left: 88px;
		top: 106px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobpassed_val_br {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Empoyee_Dashi_Mobpassed_val_br {
		position: absolute;
		overflow: visible;
		width: 52px;
		height: 37px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_Mobn__bs {
		/* left: 20px; */
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(37,37,37,1);
	}
	#Empoyee_Dashi_MobHR_Screening_ {
		left: 26px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 125px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
	}
	#Empoyee_Dashi_MobGroup_13 {
		position: absolute;
		width: 163px;
		height: 163px;
		left: 241px;
		top: 259px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobRectangle_9 {
		fill: rgba(255,255,255,1);
	}
	.Empoyee_Dashi_MobRectangle_9 {
		filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 171px;
		height: 171px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_MobVerification_ {
		left: 34px;
		top: 15px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(112,112,112,1);
	}
	#Empoyee_Dashi_Mobverification_failed_img {
		position: absolute;
		width: 49px;
		height: 49px;
		left: 25px;
		top: 50px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobverification_pending_img {
		position: absolute;
		width: 49px;
		height: 49px;
		left: 93px;
		top: 50px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobGroup_3 {
		position: absolute;
		width: 52px;
		height: 37px;
		left: 24px;
		top: 107px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobpassed_val_b {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Empoyee_Dashi_Mobpassed_val_b {
		position: absolute;
		overflow: visible;
		width: 52px;
		height: 37px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_Mobn__b {
		/* left: 20px; */
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(37,37,37,1);
	}
	#Empoyee_Dashi_MobGroup_4 {
		position: absolute;
		width: 52px;
		height: 37px;
		left: 90px;
		top: 107px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobpassed_val_ca {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Empoyee_Dashi_Mobpassed_val_ca {
		position: absolute;
		overflow: visible;
		width: 52px;
		height: 37px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_Mobn__ca {
		/* left: 20px; */
		top: 2px;
		position: absolute;
		overflow: visible;
		width: 100%;
		white-space: nowrap;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 25px;
		color: rgba(37,37,37,1);
	}
	#Empoyee_Dashi_MobGroup_19 {
		position: absolute;
		width: 379px;
		height: 103px;
		left: 30px;
		top: 128px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobdashboard_profile_outer_line {
		fill: rgba(255,255,255,1);
	}
	.Empoyee_Dashi_Mobdashboard_profile_outer_line {
		filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 384px;
		height: 108px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_MobGroup_7 {
		position: absolute;
		width: 205px;
		height: 37px;
		left: 88px;
		top: 55px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobtelephone {
		position: absolute;
		width: 35px;
		height: 35px;
		left: 86.751px;
		top: 0px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobwhatsapp_dashboard {
		position: absolute;
		width: 35px;
		height: 35px;
		left: 170px;
		top: 2px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobsetting {
		position: absolute;
		width: 35px;
		height: 35px;
		left: 0px;
		top: 1.75px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobGroup_12 {
		position: absolute;
		width: 330px;
		height: 27px;
		left: 10px;
		top: 11px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobCurrent_Candidate_lbl {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 330px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#Empoyee_Dashi_MobCurrent_Candidate_val {
		left: 100px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 230px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(37,37,37,1);
}

.stopComments_mob {
  position: absolute;
    width: 100%;
    height: 40px;
    left: 55px;
    top: 0px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: red;
    color: white;
    font-size: x-large;
    overflow: visible;
}
	#Empoyee_Dashi_Mobwhatsapp_dashboard_cd {
		position: absolute;
		width: 25px;
		height: 25px;
		left: 334px;
		top: 1px;
    cursor: pointer;
		overflow: visible;
	}
	#Empoyee_Dashi_MobGroup_10 {
		position: absolute;
		width: 40px;
		height: 40px;
		left: 25px;
		top: 16px;
		overflow: visible;
	}
	#Empoyee_Dashi_Mobchat_btn_cf {
		fill: rgba(255,255,255,1);
	}
	.Empoyee_Dashi_Mobchat_btn_cf {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 40px;
		height: 40px;
		left: 0px;
		top: 0px;
	}
	#Empoyee_Dashi_Mobchat_lbl_cg {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 9px;
		top: 9px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobGroup_26 {
		position: absolute;
		width: 283px;
		height: 34px;
		left: 72px;
		top: 20px;
		overflow: visible;
	}
	/* #Empoyee_Dashi_MobRectangle_43 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	} */
	.Empoyee_Dashi_MobRectangle_43 {
		position: absolute;
		overflow: visible;
		width: 92px;
		height: 34px;
		left: 0px;
		top: 0px;
		font-size: 15px;
		/* padding: 15px; */
   
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#Empoyee_Dashi_MobGroup_25 {
		position: absolute;
		width: 186px;
		height: 34px;
		left: 97px;
		top: 0px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobRectangle_44 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Empoyee_Dashi_MobRectangle_44 {
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 34px;
		left: 0px;
		top: 0px;
		font-size: 15px;
		/* padding: 15px; */
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#Empoyee_Dashi_Mobchat_lbl_cl {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 158px;
		top: 6px;
    cursor: pointer;
		overflow: visible;
	}
	#Empoyee_Dashi_MobGroup_24 {
		position: absolute;
		width: 95px;
		height: 20px;
		left: 292px;
		top: 105px;
		overflow: visible;
	}
	#Empoyee_Dashi_MobRectangle_45 {
		fill: rgba(255,255,255,1);
	}
	.Empoyee_Dashi_MobRectangle_45 {
		position: absolute;
		overflow: visible;
		width: 46px;
		height: 16px;
		left: 75px;
		top: 13px;
    rotate: 180deg;
	}
	#Empoyee_Dashi_MobAdmin {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		color: rgba(37,37,37,1);
	}
