.mediaViewInfo {
  --web-view-name: Web 1366 – 2;
  --web-view-id: Web_1366__2;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: Web_1366__2;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#BizibeesLogin {
  position: absolute;
  width: 1366px;
  height: 768px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: 'flex';
  flex-direction: "row";
  justify-content: 'center';
  background-color: rgba(255,255,255,1);
  overflow: hidden;

  --web-view-name: Web 1366 – 2;
  --web-view-id: Web_1366__2;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
@keyframes fadein {

  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

}
#LoginOuterBox {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.LoginOuterBox {
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  display:"inline-block";
  padding:"5px";
  width: 521px;
  height: 573px;
  left: 824px;
  top: 109px;
}

#LoginButton {
  fill: rgba(90,98,236,1);
}

.LoginButton {
  position: absolute;
  overflow: visible;
  display:"inline-block";
  padding:"5px";
  width: 313.975px;
  height: 52px;
  left: 925.512px;
  top: 476.5px;
}

#LOGIN {
  left: 1046px;
  top: 491px;
  position: absolute;
  overflow: visible;
  width: 50px;
  white-space: nowrap;
  line-height: 17px;
  margin-top: -0.5px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: rgba(255,255,255,1);
}
#Password {
  opacity: 1;
  left: 913.512px;
  top: 380.023px;
  position: absolute;
  overflow: visible;
  width: 59px;
  white-space: nowrap;
  line-height: 17px;
  margin-top: -1.5px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(29,34,38,1);
}

#LoginPassword {
  opacity: 1;
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.LoginPassword {
  overflow: visible;
  position: absolute;
  width: 313.975px;
  height: 1px;
  left: 913.012px;
  top: 393.971px;
  transform: matrix(1,0,0,1,0,0);
}
#Email_Address {
  opacity: 0.5;
  left: 913.512px;
  top: 303.527px;
  position: absolute;
  overflow: visible;
  width: 87px;
  white-space: nowrap;
  line-height: 17px;
  margin-top: -1.5px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(29,34,38,1);
}
#LoginUserName {
  opacity: 1;
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.LoginUserName {
  overflow: visible;
  position: absolute;
  width: 313.975px;
  height: 1px;
  left: 913.012px;
  top: 287.774px;
  transform: matrix(1,0,0,1,0,0);
}

#Please_login_to_your_account_bizibees {
  opacity: 0.8;
  left: 913.512px;
  top: 245.783px;
  position: absolute;
  overflow: visible;
  width: 255px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(29,34,38,1);
}
#REGISTER_BiziBees {
  opacity: 0.5;
		left: 1017.5px;
		top: 552.5px;
		position: absolute;
		overflow: visible;
		width: 105px;
		white-space: nowrap;
		transition: all 0.6000000238418579s ease-out;
		--web-animation: fadein 0.6000000238418579s ease-out;
		--web-action-type: page;
		--web-action-target: Web_1366___1.html;
		cursor: pointer;
		line-height: 17px;
		margin-top: -1.5px;
		text-align: center;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
		color: rgba(29,34,38,1);
}
#FORGET_PASSWORD_BiziBees {
  opacity: 0.5;
  left: 1100px;
  top: 435px;
  position: absolute;
  overflow: visible;
  width: 105px;
  white-space: nowrap;
  transition: all 0.6000000238418579s ease-out;
  --web-animation: fadein 0.6000000238418579s ease-out;
  --web-action-type: page;
  --web-action-target: Web_1366___1.html;
  cursor: pointer;
  line-height: 17px;
  margin-top: -1.5px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(29,34,38,1);
}
#bizibeesLogin {
  position: absolute;
  width: 214px;
  height: 78px;
  left: 965px;
  top: 138px;
  overflow: visible;
}
#kisspng-recruiter-human-resources {
  position: absolute;
  display:"inline-block";
  padding:"5px";
  width: 726px;
  height: 819px;
  left: 50px;
  /* top: -44px; */
  overflow: visible;
}
#Rectangle_32 {
  fill: rgba(255,255,255,1);
}
.Rectangle_32 {
  position: absolute;
  overflow: visible;
  width: 404px;
  height: 73px;
  left: 211px;
  top: -57px;
}

#This_email_is_not_registered {
  left: 1046px;
  top: 313px;
  position: absolute;
  overflow: visible;
  width: 183px;
  height: 20px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(232,33,36,1);
}
#Invalid_password {
  left: 1114px;
  top: 390px;
  position: absolute;
  overflow: visible;
  width: 118px;
  height: 20px;
  text-align: center;
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(232,33,36,1);
}
