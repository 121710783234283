#VoicePreference {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  
  .VoicePreference {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 66px;
    top: 1108px;
  }
  #Voice_candidate_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 89px;
    top: 1104px;
    position: absolute;
    overflow: visible;
    width: 64px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }
  
#Non_VoicePreference {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Non_VoicePreference {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 190px;
    top: 1108px;
  }
  #Non-Voice_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 213px;
    top: 1104px;
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }

  
#Semi-VoicePreference {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Semi-VoicePreference {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 66px;
    top: 1144px;
  }
  #Semi-Voice_candidate_Lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 89px;
    top: 1140px;
    position: absolute;
    overflow: visible;
    width: 113px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }
  #Candidate_Employment_Type {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 57px;
    top: 1182px;
    position: absolute;
    overflow: visible;
    width: 201px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
  }
  #FullTime_Preference {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .FullTime_Preference {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 64px;
    top: 1227px;
  }
  #Full_Time_Interview_Lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 87px;
    top: 1223px;
    position: absolute;
    overflow: visible;
    width: 92px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }
  #Part_Time_Preference {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Part_Time_Preference {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 188px;
    top: 1227px;
  }
  
  #Part_Time_Candidate_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 211px;
    top: 1223px;
    position: absolute;
    overflow: visible;
    width: 107px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }
  #Temporary_Preference {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Temporary_Preference {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 63px;
    top: 1260px;
  }
  #Temporary_Preference_Lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 86px;
    top: 1256px;
    position: absolute;
    overflow: visible;
    width: 105px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }
  #Work_Mode_Title {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 57px;
    top: 1297px;
    position: absolute;
    overflow: visible;
    width: 132px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
  }
  #Office_Preferences {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Office_Preferences {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 66px;
    top: 1343px;
  }
  #Office_Preference_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 89px;
    top: 1338px;
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }
  #Home_Preferences {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Home_Preferences {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 190px;
    top: 1343px;
  }
  
#Home_Preferences_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 216px;
    top: 1338px;
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }
  #Hybrid_Preference {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Hybrid_Preference {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 66px;
    top: 1380px;
  }
  #Hybrid_Preference_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 89px;
    top: 1375px;
    position: absolute;
    overflow: visible;
    width: 79px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }
  #Shifts_Preference {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 61px;
    top: 1418px;
    position: absolute;
    overflow: visible;
    width: 71px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
  }
  #Day_Preference {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Day_Preference {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 68px;
    top: 1461px;
  }
  #Day_Preference_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 91px;
    top: 1456px;
    position: absolute;
    overflow: visible;
    width: 59px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }
  #Night_preference_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Night_preference_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 195px;
    top: 1460px;
  }
  #Night_preference_lbl_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 221px;
    top: 1455px;
    position: absolute;
    overflow: visible;
    width: 73px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }

  #Rotational_Preference_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Rotational_Preference_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 68px;
    top: 1499px;
  }
  
  #Rotational_Preference_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 96px;
    top: 1494px;
    position: absolute;
    overflow: visible;
    width: 96px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }

  #Candiates_Transportation {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 61px;
    top: 1534px;
    position: absolute;
    overflow: visible;
    width: 174px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
  }
  #Own_Transporation_Preference {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Own_Transporation_Preference {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 67px;
    top: 1584px;
  }
  
  #Own_Transporation_Preference_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 90px;
    top: 1580px;
    position: absolute;
    overflow: visible;
    width: 64px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }
  #Company_Day_Preference_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Company_Day_Preference_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 191px;
    top: 1585px;
  }
  
  #Company_Day_Preference_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 214px;
    top: 1581px;
    position: absolute;
    overflow: visible;
    width: 134px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }
  #Company_Night_Preference_ {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
  }
  .Company_Night_Preference_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 67px;
    top: 1620px;
  }
  #Company_Night_Preference_lbl_ {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 93px;
    top: 1616px;
    position: absolute;
    overflow: visible;
    width: 156px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
  }
  


  