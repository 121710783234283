.mediaViewInfo {
  --web-view-name: Web 1366 – 1;
  --web-view-id: Web_1366__1;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: Web_1366__1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#Web_1366__1 {
  position: absolute;
  width: 1366px;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  /* background-color: rgba(255,255,255,1); */
   overflow: visible; 
  --web-view-name: Web 1366 – 1;
  --web-view-id: Web_1366__1;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#Mobile_1366__1 {
  position: absolute;
  width: 555px;
  height: 100%;
  top: 50%;
  left: 0%;
  top:60%;
  transform: translate(-100%, -50%);
  background: transparent;
  /* background-color: rgba(255,255,255,1); */
   overflow: visible; 
  /* --web-view-name: Web 1366 – 1;
  --web-view-id: Web_1366__1;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true; */
}

#CandidateOuterBorder {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateOuterBorder {
  filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow-y: scroll;
  width: 585px;
  height: 1736px;
  left: 796px;
  top: 30px;
}
#CandidateMobile {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateMobile {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 237px;
  height: 62px;
  left: 835px;
  top: 251px;
}
#CandidateName {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateName {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 237px;
  height: 62px;
  left: 1090px;
  top: 251px;
}
#CandidateGender {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateGender {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 237px;
  height: 62px;
  left: 1090px;
  top: 330px;
}
#CandidateEmail {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateEmail {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 237px;
  height: 62px;
  left: 835px;
  top: 330px;
}
#WhatsappNumber {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.WhatsappNumber {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 237px;
  height: 62px;
  left: 835px;
  top: 409px;
}
#CandidateDOB {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateDOB {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 237px;
  height: 62px;
  left: 1090px;
  top: 409px;
}
#ExpYears {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.ExpYears {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 102px;
  height: 62px;
  left: 970px;
  top: 488px;
}
#ExpMonths {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.ExpMonths {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 103px;
  height: 62px;
  left: 1090px;
  top: 488px;
}
#AppExperience {
  opacity: 0.65;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 835px;
  top: 488px;
  position: absolute;
  overflow: visible;
  width: 126px;
  height: 33px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: rgba(42,14,15,1);
}
#CandidateCity {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateCity {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 237px;
  height: 62px;
  left: 835px;
  top: 565px;
}
#Polygon_2 {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 2px;
  stroke-linejoin: bevel;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Polygon_2 {
  mix-blend-mode: darken;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  overflow: visible;
  position: absolute;
  width: 18px;
  height: 15px;
  transform: matrix(1,0,0,1,1029.8704,579.5) rotate(181deg);
  transform-origin: center;
  left: 0px;
  top: 0px;
}
#CandidateSkills {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateSkills {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 490px;
  height: 62px;
  left: 837px;
  top: 900px;
}
#CandidateLanguages {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateLanguages {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 495px;
  height: 62px;
  left: 835px;
  top: 714px;
}
#ExpectedSalary {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.ExpectedSalary {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 237px;
  height: 62px;
  left: 1093px;
  top: 640px;
}
#CurrentSalary {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CurrentSalary {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 237px;
  height: 62px;
  left: 835px;
  top: 640px;
}
#DOB {
  opacity: 0.4;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 1077px;
  top: 418px;
  position: absolute;
  overflow: visible;
  width: 99px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#Years {
  opacity: 0.4;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 961px;
  top: 495px;
  position: absolute;
  overflow: visible;
  width: 99px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#Months {
  opacity: 0.4;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 1081px;
  top: 495px;
  position: absolute;
  overflow: visible;
  width: 99px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#Languages {
  opacity: 0.4;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 876px;
  top: 723px;
  position: absolute;
  overflow: visible;
  width: 111px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#Expected_Salary {
  opacity: 0.4;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 1123px;
  top: 649px;
  position: absolute;
  overflow: visible;
  width: 155px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#APCandidateArea {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateArea {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 237px;
  height: 62px;
  left: 1093px;
  top: 565px;
}
#ResumeUpload {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 2px;
  stroke-linejoin: bevel;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.ResumeUpload {
  filter: drop-shadow(5px 5px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 470px;
  height: 53px;
  left: 835px;
  top: 1080px;
  font-size: 20px;
  color:"red";
}
#Upload_Resume_ {
  opacity: 0.65;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 953px;
  top: 1041px;
  position: absolute;
  overflow: visible;
  width: 160px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(42,14,15,1);
}
#No_file_chosen {
  opacity: 0.65;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 952px;
  top: 1065px;
  position: absolute;
  overflow: visible;
  width: 160px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(42,14,15,1);
}
#Preferences {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 822px;
  top: 1151px;
  position: absolute;
  overflow: visible;
  width: 188px;
  height: 47px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  color: rgba(255,79,90,1);
}
#chkWillingtorelocate {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.chkWillingtorelocate {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  transform: scale(1.7);
  width: 20px;
  height: 20px;
  left: 833px;
  top: 1590px;
}
#AppWilling_to_Relocate {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 868px;
  top: 1585px;
  position: absolute;
  overflow: visible;
  width: 176px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#Email {
  opacity: 0.4;
  left: 855px;
  top: 337px;
  position: absolute;
  overflow: visible;
  width: 99px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#mail {
  opacity: 0.5;
  position: absolute;
  width: 26px;
  height: 26px;
  left: 846px;
  top: 339px;
  overflow: visible;
}
#Gender {
  opacity: 0.4;
  left: 1117px;
  top: 337px;
  position: absolute;
  overflow: visible;
  width: 99px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#Mobile_Number {
  opacity: 0.4;
  left: 858px;
  top: 258px;
  position: absolute;
  overflow: visible;
  width: 175px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#phone {
  opacity: 0.5;
  position: absolute;
  width: 37px;
  height: 37px;
  left: 839px;
  top: 255px;
  overflow: visible;
}
#gender-fluid {
  opacity: 0.5;
  position: absolute;
  width: 31px;
  height: 31px;
  left: 1095px;
  top: 336px;
  overflow: visible;
}
#WhatsApp_Number {
  opacity: 0.4;
  left: 867px;
  top: 418px;
  position: absolute;
  overflow: visible;
  width: 175px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#whatsapp {
  opacity: 0.51;
  position: absolute;
  width: 33px;
  height: 33px;
  left: 837px;
  top: 415px;
  overflow: visible;
}
#City {
  opacity: 0.4;
  left: 854px;
  top: 572px;
  position: absolute;
  overflow: visible;
  width: 66px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#Current_Salary {
  opacity: 0.4;
  left: 866px;
  top: 650px;
  position: absolute;
  overflow: visible;
  width: 137px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#skyline {
  opacity: 0.6;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 840px;
  top: 575px;
  overflow: visible;
}
#salary {
  opacity: 0.5;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 841px;
  top: 652px;
  overflow: visible;
}
#language {
  opacity: 0.52;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 845px;
  top: 721px;
  overflow: visible;
}
#salary_cf {
  opacity: 0.5;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 1102px;
  top: 649px;
  overflow: visible;
}

#Upload_Image {
  left: 1009px;
  top: 171px;
  position: absolute;
  overflow: visible;
  width: 130px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#Appundo {
  left: 809px;
  top: 50px;
  position: absolute;
  overflow: visible;
  width: 53px;
  height: 53px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}

#Appuser3 {
  position: absolute;
  width: 122px;
  height: 122px;
  left: 1012px;
  top: 48px;
  border-radius:"75px";
  border:"1px solid black";
  overflow: visible;
}
#BtnSubmit {
  fill: rgba(90,98,236,1);
}
.BtnSubmit_PC {
  filter: drop-shadow(7px 5px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 210px;
  height: 68px;
  text-align: center;
  left: 920px;
  top: 1672px;
}
#Register {
  left: 996px;
  top: 1703px;
  position: absolute;
  overflow: visible;
  width: 140px;
  height: 40px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: rgba(255,255,255,1);
}
#CandidateEducation {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateEducation {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 492px;
  height: 62px;
  left: 835px;
  top: 830px;
}
#CandidateReference {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateReference {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 489px;
  height: 62px;
  left: 838px;
  top: 1639px;
}
#Education {
  opacity: 0.4;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 873px;
  top: 798px;
  position: absolute;
  overflow: visible;
  width: 94px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#Area {
  opacity: 0.4;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 1136px;
  top: 575px;
  position: absolute;
  overflow: visible;
  width: 50px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#mortarboard {
  opacity: 0.57;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 845px;
  top: 796px;
  overflow: visible;
}
#plot {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 1107px;
  top: 572px;
  overflow: visible;
}
#Reference {
  opacity: 0.4;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 883px;
  top: 1606px;
  position: absolute;
  overflow: visible;
  width: 94px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#research {
  opacity: 0.56;
  position: absolute;
  width: 32px;
  height: 32px;
  left: 851px;
  top: 1604px;
  overflow: visible;
}
#CandidateAddress {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.CandidateAddress {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 490px;
  height: 109px;
  left: 836px;
  top: 991px;
}
#Address {
  opacity: 0.4;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 869px;
  top: 959px;
  position: absolute;
  overflow: visible;
  width: 76px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#Name {
  opacity: 0.4;
  left: 1104px;
  top: 257px;
  position: absolute;
  overflow: visible;
  width: 99px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#people {
  opacity: 0.5;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 1097px;
  top: 257px;
  overflow: visible;
}
#pin {
  opacity: 0.57;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 842px;
  top: 958px;
  overflow: visible;
}
#Skills {
  opacity: 0.4;
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 873px;
  top: 876px;
  position: absolute;
  overflow: visible;
  width: 62px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}
#skill-development {
  opacity: 0.5;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 848px;
  top: 876px;
  overflow: visible;
}