.mediaViewInfo {
  --web-view-name: mid bar rec;
  --web-view-id: mid_bar_rec;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: mid_bar_rec;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#mid_bar_rec {
  position: absolute;
  width: 100%;
  height: 100%;
  top:92px;
  z-index: -1;
  overflow-x: scroll;
  background-color: rgba(255,255,255,1);
  overflow: hidden;
  --web-view-name: mid bar rec;
  --web-view-id: mid_bar_rec;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#mid_deisgn_can_mob {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 0.20000000298023224px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.mid_deisgn_can_mob {
  filter: drop-shadow(5px 3px 3px rgba(0, 0, 0, 0.161));
  overflow: visible;
  position: absolute;
  width: 385.577px;
  height: 100%;
  left: 0px;
  text-align: center;
  top: -0.157px;
  transform: matrix(1,0,0,1,0,0);
}
#Company_wise_mob {
  position: relative;
  width: 100%;
  height: 92%;
  background-color: rgba(255,255,255,1);
 overflow-x: scroll; 
  
}

#dream_job_grp {
  position: absolute;
  width: 100%;
  height: 150px;
  top: 20px;
  overflow: visible;
}
#dream_job_bd {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.dream_job_bd {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 100%;
  height: 168px;
  left: 0px;
  top: 0px;
}
#dream_job_im {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 5px;
  top: 0px;
  overflow: visible;
}
#Find_Your_Dream_Job_lbl_ {
  left: 167px;
  top: 62px;
  position: absolute;
  overflow: visible;
  width: 184px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(36,35,35,1);
}
#dream_job_back {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  width: 30px;
  height: 30px;
}
.dream_job_back {
  position: absolute;
  overflow: visible;
  width: 30px;
  height: 30px;
  left: 90%;
  top: 7px;
  cursor: pointer;
}
#Find_bl_marqu {
  left: 16px;
  top: 121px;
  position: absolute;
  overflow: hidden;
  width: 347px;
  height: 24px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(255,0,0,1);
  letter-spacing: 0.15px;
}