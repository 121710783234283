.applicant1 {
  background-color: rgb(240, 240, 240);
  margin: auto;
  position: relative;
  min-width: 375px;
  max-width: 450px;
  height: auto;
}

/* #root {
    width: 100%;
    height: 100%;
    background-image: url("/public/bluebackground.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
} */
/* #backgroundpic{
    width: 100%;
    height: 100%;
    background-image: url("/public/bluebackground.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
} */
/* body{
    width: 100%;
    background-image: url("/public/background.jpg");
    background-repeat: no-repeat;
    overflow: hidden;
} */
