.mediaViewInfo {
    --web-view-name: JD mob;
    --web-view-id: JD_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: JD_mob;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#JD_mob {
    position: absolute;
    width: 314px;
    height: 685px;
    left: 50%;
    top:60%;
    transform: translate(-50%, -50%);
     border-radius: 10px; 
    overflow: hidden;
    --web-view-name: JD mob;
    --web-view-id: JD_mob;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_overall_mob {
    position: absolute;
    width: 314px;
    height: 685px;
    left: 2px;
    top: 0px;
    overflow: visible;
}
#card_2_bottom_mob {
    fill: rgba(26,255,110,1);
}
.card_2_bottom_mob {
    position: absolute;
    overflow: visible;
    width: 278px;
    height: 15px;
    left: 20px;
    top: 646px;
}
#card_2_top_mob {
    fill: rgba(255,255,255,1);
}
.card_2_top_mob {
    position: absolute;
    overflow: visible;
    width: 278px;
    height: 257px;
    left: 20px;
    top: 398px;
}
#card_1_bottom_mob {
    fill: rgba(255,122,122,1);
}
.card_1_bottom_mob {
    position: absolute;
    overflow: visible;
    width: 278px;
    height: 15px;
    left: 18px;
    top: 335px;
}
#card_1_top_mob {
    fill: rgba(255,255,255,1);
}
.card_1_top_mob {
    position: absolute;
    overflow: visible;
    width: 278px;
    height: 257px;
    left: 18px;
    top: 86px;
}
#Job_Over_grp_mob {
    position: absolute;
    width: 251px;
    height: 269px;
    left: 25px;
    top: 59px;
    overflow: visible;
}
#JD_desc_grp_1_mob {
    position: absolute;
    width: 270px;
    height: 254px;
    left: 0px;
    top: 31px;
    overflow-y: scroll;
}
#blabla_jd_mob {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 263px;
    height: 100%;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#Job_Description_lbl_mob {
    left: 65px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#ksovergrp_mob {
    position: absolute;
    width: 272px;
    height: 280px;
    left: 25px;
    top: 373px;
    overflow: visible;
}
#Key_Skills_lbl_mob {
    left: 96px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 76px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: rgba(0,0,0,1);
}
#ks_grp_mob {
    position: absolute;
    width: 272px;
    height: 253px;
    left: 0px;
    top: 26px;
    overflow-y: scroll;
}
#blablabla_mob {
    left: 0px;
    top: 0px;
    height: 100%;
    position: absolute;
    overflow: visible;
    width: 273px;
    white-space: pre-wrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#jd_copy_btn_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 223px;
    top: 48px;
    overflow: visible;
}
#bdr_btn_jd_mob {
    fill: rgba(255,255,255,1);
}
.bdr_btn_jd_mob {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 41.5px;
    height: 39.5px;
    left: 0px;
    top: 0px;
}
#file_jd_img_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
    overflow: visible;
}
#ks_copy_btn_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 223px;
    top: 363px;
    overflow: visible;
}
#btn_mob_ks {
    fill: rgba(255,255,255,1);
}
.btn_mob_ks {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 41.5px;
    height: 39.5px;
    left: 0px;
    top: 0px;
}
#file_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 3px;
    top: 3px;
    overflow: visible;
}
#close1_pop_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 277px;
    top: 12px;
    overflow: visible;
}
#Grassroots_BPO_lbl_mob {
    left: 15px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 85%;
    white-space: pre-wrap;
    text-align: cneter;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    color: rgba(0,0,0,1);
    text-decoration: underline;
}