.mediaViewInfo {
    --web-view-name: reg page;
    --web-view-id: reg_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: reg_page;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#reg_page {
    position: absolute;
    width: 375px;
    height: 840px;
    top: 14%;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: reg page;
    --web-view-id: reg_page;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
@keyframes fadein {

    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

}
#bg_design_reg {
    opacity: 0.65;
    position: absolute;
    width: 375px;
    height: 812px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#top_design {
    fill: rgba(133,194,255,1);
}
.top_design {
    position: absolute;
    overflow: visible;
    width: 459px;
    height: 129px;
    left: -42px;
    top: -61px;
}
#registration_img_mob {
    position: absolute;
    width: 96px;
    height: 100px;
    left: 139.5px;
    top: 14px;
    border-radius: 50%;
    overflow: visible;
}
#reg_page_1_details {
    position: absolute;
    width: 375px;
    height: 636.095px;
    left: 0px;
    top: 135.095px;
    overflow: visible;
}
#rec_page_scrool_mob {
    mix-blend-mode: normal;
    position: absolute;
    width: 375px;
    height: 560px;
    left: 0px;
    top: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
}
#name_grp {
    position: absolute;
    width: 205px;
    height: 63px;
    left: 85px;
    top: 15px;
    overflow: visible;
}
#name_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.name_input {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Name_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#mob_no_grp {
    position: absolute;
    width: 205px;
    height: 63px;
    left: 85px;
    top: 90px;
    overflow: visible;
}
#mob_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mob_input {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Mobile_Number_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#com_name_grp_mob {
    position: absolute;
    width: 205px;
    height: 63px;
    left: 85px;
    top: 165px;
    overflow: visible;
}
#com_name_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_name_input {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Company_Name_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 108px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#com_state_grp {
    position: absolute;
    width: 205px;
    height: 63px;
    left: 85px;
    top: 315px;
    overflow: visible;
}
#com_state_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_state_input {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Company_State_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 101px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#com_city_grp {
    position: absolute;
    width: 205px;
    height: 63px;
    left: 85px;
    top: 240px;
    overflow: visible;
}
#com_city_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_city_input {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Company_City_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 94px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#email_grp {
    position: absolute;
    width: 205px;
    height: 63px;
    left: 85px;
    top: 390px;
    overflow: visible;
}
#email_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.email_input {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Email_ID_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#gen_grp {
    position: absolute;
    width: 205px;
    height: 63px;
    left: 85px;
    top: 465px;
    overflow: visible;
}
#geninput {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.geninput {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Gender_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 50px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#com_add_grp {
    position: absolute;
    width: 205px;
    height: 64px;
    left: 85px;
    top: 614px;
    overflow: visible;
}
#com_add_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_add_input {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 21px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Company_Address_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 121px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#com_type_grp_mob {
    position: absolute;
    width: 205px;
    height: 63px;
    left: 85px;
    top: 540px;
    overflow: visible;
}
#com_typ_lbl_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_typ_lbl_rec {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Company_Type_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 99px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#pin_grp {
    position: absolute;
    width: 205px;
    height: 63px;
    left: 85px;
    top: 690px;
    overflow: visible;
}
#Pin_lbl_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Pin_lbl_rec {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Company_PIN_code_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 128px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#next_grp_btn {
    position: absolute;
    width: 142px;
    height: 49px;
    left: 117px;
    top: 587.095px;
    overflow: visible;
    transition: all 0.30000001192092896s wind-up;
    --web-animation: fadein 0.30000001192092896s wind-up;
    --web-action-type: page;
    --web-action-target: reg_page_2.html;
    cursor: pointer;
}
#btn_bdr_reg_bj {
    fill: url(#btn_bdr_reg_bj);
}
.btn_bdr_reg_bj {
    position: absolute;
    overflow: visible;
    width: 142px;
    height: 49px;
    left: 0px;
    top: 0px;
}
#Next_lbl {
    left: 55px;
    top: 14.5px;
    position: absolute;
    overflow: visible;
    width: 32px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}

/* Page 2 */

#reg_page_2_uploads {
    position: absolute;
    width: 323.117px;
    height: 473.095px;
    left: 35.577px;
    top: 150.095px;
    overflow: visible;
}
#register_btn_mob {
    position: absolute;
    width: 142px;
    height: 49px;
    left: 81.423px;
    top: 424.095px;
    overflow: visible;
}
#reg_btn_mob_bt {
    fill: url(#reg_btn_mob_bt);
}
.reg_btn_mob_bt {
    position: absolute;
    overflow: visible;
    width: 142px;
    height: 49px;
    left: 0px;
    top: 0px;
}
#Register_mob {
    left: 45px;
    top: 14.5px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#over_upload_grp_mob {
    position: absolute;
    width: 323.117px;
    height: 370px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#gst_grp_mob {
    position: absolute;
    width: 233.117px;
    height: 64px;
    left: 0px;
    top: 77px;
    overflow: visible;
}
#GST_No_lbl_rec_mob {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 54px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#gst_pan_input_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.gst_pan_input_mob {
    position: absolute;
    overflow: visible;
    width: 233.117px;
    height: 43px;
    left: 0px;
    top: 21px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#com_pan_grp_mob {
    position: absolute;
    width: 233.117px;
    height: 64px;
    left: 0px;
    top: 154px;
    overflow: visible;
}
#Company_PAN_Number_mob {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 155px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#com_pan_input_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_pan_input_mob {
    position: absolute;
    overflow: visible;
    width: 233.117px;
    height: 43px;
    left: 0px;
    top: 21px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#pan_grp_mob {
    position: absolute;
    width: 233.117px;
    height: 64px;
    left: 0px;
    top: 231px;
    overflow: visible;
}
#User_PAN_Number_mob {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#pan_number_input_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.pan_number_input_mob {
    position: absolute;
    overflow: visible;
    width: 233.117px;
    height: 43px;
    left: 0px;
    top: 21px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#aadhar_grp_mob {
    position: absolute;
    width: 233.117px;
    height: 63px;
    left: 0px;
    top: 307px;
    overflow: visible;
}
#User_Aadhaar_Number_mob {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 149px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#aadhar_lbl_rec_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.aadhar_lbl_rec_mob {
    position: absolute;
    overflow: visible;
    width: 233.117px;
    height: 43px;
    left: 0px;
    top: 20px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#com_reg_grp_mob {
    position: absolute;
    width: 232px;
    height: 64px;
    left: 1.117px;
    top: 0px;
    overflow: visible;
}
#Company_Registration_mob {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 206px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#com_reg_imput_mob {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_reg_imput_mob {
    position: absolute;
    overflow: visible;
    width: 232px;
    height: 43px;
    left: 0px;
    top: 21px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#reg_no_upload1_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 253.117px;
    top: 22.544px;
    overflow: visible;
}
#reg_no_down_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 298.117px;
    top: 22.544px;
    overflow: visible;
}
#gst_up_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 253.117px;
    top: 99.634px;
    overflow: visible;
}
#gst_down_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 298.117px;
    top: 99.634px;
    overflow: visible;
}
#com_pan_up_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 253.117px;
    top: 176.724px;
    overflow: visible;
}
#com_pan_down_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 298.117px;
    top: 176.724px;
    overflow: visible;
}
#pan_up_img_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 253.117px;
    top: 253.815px;
    overflow: visible;
}
#pan_img_rec_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 298.117px;
    top: 253.815px;
    overflow: visible;
}
#upload1_img_rec_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 253.117px;
    top: 330.905px;
    overflow: visible;
}
#download1_img_rec_mob {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 298.117px;
    top: 330.905px;
    overflow: visible;
}
#previous_lbl_img_mob {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 36px;
    top: 14px;
    overflow: visible;
    --web-animation: fadein undefineds undefined;
    --web-action-type: page;
    --web-action-target: reg_page.html;
    cursor: pointer;
}