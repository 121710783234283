.mediaViewInfo {
    --web-view-name: footer bar can – 1;
    --web-view-id: footer_bar_can__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: footer_bar_can__1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#footer_bar_can__1 {
    position: fixed;
    width: 100%;
    height: 64px;
    z-index: 10;
    bottom: 0px;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: footer bar can – 1;
    --web-view-id: footer_bar_can__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#footer_can_mob_1 {
    fill: rgba(255,75,83,1);
}
.footer_can_mob_1 {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 64px;
    left: 0px;
    top: 0px;
}
#foot_rec_bar_1 {
    position: absolute;
    width: 327px;
    height: 48px;
    left: 32px;
    top: 10px;
    overflow: visible;
}
#All_rights_reserved__2022_Oper_mob {
    left: 0px;
    top: 32px;
    position: absolute;
    overflow: visible;
    width: 328px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(255,255,255,1);
}
#linkedin_lbl_vac_can_mob_1 {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 253px;
    top: 0px;
    overflow: visible;
}
#Terms_and_Conditions_lbl_vac_mob {
    left: 0px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 199px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(255,255,255,1);
}
#privacy_policy_mob {
    left: 130px;
    top: 6px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: rgba(255,255,255,1);
}
#whatapp_btn_img_can_mob_1 {
    position: absolute;
    width: 28px;
    height: 28px;
    left: 297px;
    top: 0px;
    overflow: visible;
}