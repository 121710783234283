.mediaViewInfo {
    --web-view-name: share popup;
    --web-view-id: share_popup;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: share_popup;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#share_popup {
    position: absolute;
    width: 317px;
    height: 158px;
    left:50%;
    top:50%;
    transform: translate(-50%,-60%);
    border-radius :10px ;
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: share popup;
    --web-view-id: share_popup;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bg_img_share_ {
    opacity: 0.53;
    position: absolute;
    width: 317px;
    height: 158px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#Share_lbl {
    left: 125px;
    top: 7px;
    position: absolute;
    overflow: visible;
    width: 51px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: rgba(0,0,0,1);
    text-decoration: underline;
}
#whatsapp_grp_share {
    position: absolute;
    width: 78px;
    height: 88px;
    left: 74px;
    top: 49px;
    overflow: visible;
}
#WhatsApp_lbl {
    left: 0px;
    top: 66px;
    position: absolute;
    overflow: visible;
    width: 79px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#bdr {
    fill: rgba(255,255,255,1);
    stroke: rgba(63,194,80,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.bdr {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 71.5px;
    height: 69.5px;
    left: 3px;
    top: 0px;
    border-radius: 50px;
}
#whatsapp_img {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 11px;
    top: 8px;
    cursor: pointer;
    overflow: visible;
    border-radius: 50px;
}
#grp_copy {
    position: absolute;
    width: 60px;
    height: 88px;
    left: 175px;
    top: 49px;
    overflow: visible;
    border-radius: 50px;
}
#Copy_lbl {
    left: 11px;
    top: 66px;
    position: absolute;
    overflow: visible;
    width: 40px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#btn_copy {
    fill: rgba(255,255,255,1);
    stroke: rgba(197,218,252,1);
    stroke-width: 2px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.btn_copy {
    filter: drop-shadow(7px 5px 3px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 71.5px;
    height: 69.5px;
    left: 0px;
    top: 0px;
    cursor: pointer;
}
#copy {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 8px;
    top: 8px;
    cursor: pointer;
    overflow: visible;
}
#close1_btn_pop_art_share {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 286px;
    top: 7px;
    cursor: pointer;
    overflow: visible;
}