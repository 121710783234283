#card_city_grp {
  position: relative;
  width: 171px;
  height: 194px;
  /* left: 20px; */
  top: 170px;
  text-align: center;
  overflow: visible;

}
#bg_bdr_new {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bg_bdr_new {
  filter: drop-shadow(10px 10px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 190px;
  height: 148px;
  left: 0px;
  top: 21px;
  /* z-index: 1; */
}
.img_city {
  position: relative;
  /* width: 128px; */
  height: 128px;
  /* left: 22px; */
  top: 35px;
  
}
#Jobs__7__lbl {
  left: 59px;
  top: 128px;
  position: absolute;
  overflow: visible;
  width: 57px;
  height: 24px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Bangalore_lbl_city {
  /* left: 40px; */
  /* top: 100px; */
   top: 45px;
  position: relative;
  overflow: visible;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: rgba(16,15,15,1);
}