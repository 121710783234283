	.mediaViewInfo {
		--web-view-name: bizibees mob admin;
		--web-view-id: bizibees_mob_admin;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: bizibees_mob_admin;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#bizibees_mob_admin {
		position: absolute;
    width: 100vw;
    height: calc(100vh - 15px);
    top: 15px;
    /* background-color: rgba(242,242,242,1); */
    overflow: hidden;
    --web-view-name: bizibees mob admin;
    --web-view-id: bizibees_mob_admin;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
	}
	#screen_and_menu_grp_BBL {
		position: absolute;
		width: 100%;
		height: 100%;
		/* left: -30px; */
		top: 58px;
		overflow: visible;
	}
	#discplay_grp_BBl {
		position: absolute;
        width: calc(100% - 40px);
        height: calc(100% - 60px);
        left: 0px;
        top: 0px;
        overflow: visible;
	}
	#display_BBL {
		fill: rgba(255,255,255,1);
		stroke: rgba(63,71,204,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.display_BBL {
		position: absolute;
		overflow: visible;
		width: 100%;
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#ELOUUD_BBL {
		position: absolute;
		width: 20px;
		height:calc(100vh - 75px);
		left: calc(100vw - 70px);
		/* top: 25.235px; */
		overflow-x: hidden;
        overflow-y: scroll;
	}
	#lcoation_BBL {
		transform: translate(-345.345px, -62.235px) matrix(1,0,0,1,329.3451,141.7415) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 59px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#OFL_BBL {
		transform: translate(-345.345px, -62.235px) matrix(1,0,0,1,311.8453,253.1299) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 94px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#upload_BBL {
		transform: translate(-345.345px, -62.235px) matrix(1,0,0,1,336.3451,351.7195) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#users_BBL {
		transform: translate(-345.345px, -62.235px) matrix(1,0,0,1,341.8451,416.6404) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 34px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#devices_BBL {
		transform: translate(-345.345px, -62.235px) matrix(1,0,0,1,335.3453,483.1621) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 47px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(4,4,4,1);
	}
	#unline_and_email_grp {
		position: absolute;
		width: 21.5px;
		height: 39.265px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#email_lbl_BBL {
		transform: translate(-345.345px, -62.235px) matrix(1,0,0,1,338.8452,73.2349) rotate(90deg);
		transform-origin: center;
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 40px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		color: rgba(100,149,237,1);
	}
	#email_underline {
		fill: transparent;
		stroke: rgba(100,149,237,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.email_underline {
		overflow: visible;
		position: absolute;
		width: 1px;
		height: 39px;
		left: 0px;
		top: 0.265px;
		transform: matrix(1,0,0,1,0,0);
	}
	#bizibeesjpgtoday_BBL {
		position: absolute;
		width: 114.574px;
		height: 42px;
		left: 222.426px;
		top: 10.5px;
		overflow: visible;
	}
	#list3_today_menu_BBL {
		position: absolute;
		width: 33px;
		height: 33px;
		left: 22px;
		top: 15px;
		overflow: visible;
	}

    .selectedInterviewTab_pc {
            width:100px;
            color: rgba(100,149,237,1);
            text-align: center;
            cursor: pointer;
            text-decoration: underline;
            transition: 1s;

    }

    .selectedInterviewTab_pc div {
        rotate:90deg;
        text-align:center;
    }


    .interviewtabs_pc {
        width:100px;
		text-align: center;
        cursor: pointer;
    }

    .interviewtabs_pc div {
        rotate:90deg;
        text-align:center;
    }