
#kisspng-recruiter-human-resour {
    /* background-attachment: fixed; */
    position: relative;
    width: 100%;
    /* height: 819px; */
    /* left: 508px;
    top: 100px; */
    margin: 0px auto;
    overflow: visible;
}
#bizibees {
    position: fixed;
    width: 214px;
    height: 78px;
    left: 2%;
    top: 12%;
    overflow: visible;
}