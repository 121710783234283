.mediaViewInfo {
    --web-view-name: reset password;
    --web-view-id: reset_password_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: fit;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: reset_password_pc;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#reset_password_pc {
    position: absolute;
    width: 435px;
    height: 412px;
    background-color: rgba(255,255,255,1);
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    overflow: hidden;
    --web-view-name: reset password;
    --web-view-id: reset_password_pc;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: fit;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#overall_grp {
    position: absolute;
    width: 418px;
    height: 393px;
    left: -5px;
    top: 0px;
    overflow: visible;
}
#bg_design_reset_pas {
    opacity: 0.7;
    filter: drop-shadow(9px 9px 2px rgba(0, 0, 0, 0.161));
    position: absolute;
    width: 413px;
    height: 393px;
    left: 5px;
    top: 0px;
    overflow: visible;
}
#top_design_reset_j {
    fill: url(#top_design_reset_j);
}
.top_design_reset_j {
    position: absolute;
    overflow: visible;
    width: 409px;
    height: 56px;
    left: 0px;
    top: 0px;
}
#Reset_Password_lbl_res {
    left: 144px;
    top: 15px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#field_1_new {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.field_1_new {
    position: absolute;
    overflow: visible;
    width: 316px;
    height: 42px;
    left: 49px;
    top: 114px;
    border-radius: 10px;
    font-size: 15px;
    border: 2px solid darkcyan;
    padding: 5px;
}

.field_1_new_error {
    position: relative;
    overflow: hidden;
    width: 316px;
    height: 32px;
    left: 49px;
    top: 54px;
    color: red;
    font-size: 15px;
}
.field_2_new_error {
    position: relative;
    overflow: hidden;
    width: 316px;
    height: 32px;
    left: 49px;
    top: 200px;
    color: red;
    font-size: 15px;
}
#field_2_confirm {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.field_2_confirm {
    position: absolute;
    overflow: visible;
    width: 316px;
    height: 42px;
    left: 49px;
    top: 186px;
    border-radius: 10px;
    font-size: 15px;
    border: 2px solid darkcyan;
    padding: 5px;
}
#set_pass_btn_reset:hover {
    left: 53px;
    top: 286px;
}
#set_pass_btn_reset {
    position: absolute;
    width: 151px;
    height: 42px;
    left: 49px;
    top: 280px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#set_pass_reset_t {
    fill: url(#set_pass_reset_t);
}
.set_pass_reset_t {
    position: absolute;
    overflow: visible;
    width: 151px;
    height: 42px;
    left: 0px;
    top: 0px;
}
#Set_Password_reset {
    left: 26px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 100px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#back_btn_reset:hover {
    left: 218px;
    top: 286px;
}
#back_btn_reset {
    position: absolute;
    width: 151px;
    height: 42px;
    left: 214px;
    top: 280px;
    cursor: pointer;
    overflow: visible;
    transition: all .3s ease-out;
}
#back_reset_btn_ {
    fill: url(#back_reset_btn_);
}
.back_reset_btn_ {
    position: absolute;
    overflow: visible;
    width: 151px;
    height: 42px;
    left: 0px;
    top: 0px;
}
#Back_reset {
    left: 58px;
    top: 10px;
    position: absolute;
    overflow: visible;
    width: 36px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
