.mediaViewInfo {
    --web-view-name: terms and conditions;
    --web-view-id: terms_and_conditions;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: terms_and_conditions;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#terms_and_conditions {
    position: absolute;
    width: 100%;
    height: 90%;
    /* text-align: center; */
    background-color: rgba(255,255,255,1);
    overflow: scroll;
    --web-view-name: terms and conditions;
    --web-view-id: terms_and_conditions;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#Terms_and_conditions_These_ter {
    /* left: 33px; */
    top: 10px;
    position: relative;
    overflow: visible;
    width: 100%;
    white-space: pre-wrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
}