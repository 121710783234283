#card_company_name_grp {
  position: relative;
  width: 171px;
  height: 194px;
  /* left: 28px; */
  top: 190px;
  overflow: visible;
}
#bg_esign_card_lbl_com {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1.5px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.bg_esign_card_lbl_com {
  filter: drop-shadow(10px 10px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 190px;
  height: 213px;
  left: 0px;
  top: 0px;
}
.index-companyProfile_lbl_com {
  position: relative;
  width: 128px;
  height: 128px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#Jobs__3_lbl_com {
  left: 55px;
  top: 156px;
  position: absolute;
  overflow: visible;
  width: 57px;
  height: 24px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Grassroots_BP0_lbl_com {
  left: 20px;
  top: 128px;
  position: absolute;
  overflow: visible;
  width: 133px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(16,15,15,1);
}