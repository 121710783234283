	.topPartAdminmediaViewInfo {
		--web-view-name: activity – 1;
		--web-view-id: activity__1;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	:root {
		--web-view-ids: activity__1;
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		border: none;
	}
	#topPartAdminactivity__1 {
		position: absolute;
		width: 100vw;
		height: calc(100vh - 60px);
		background-color: rgba(246,246,246,1);
		overflow: hidden;
		--web-view-name: activity – 1;
		--web-view-id: activity__1;
		--web-scale-on-resize: true;
		--web-enable-deep-linking: true;
	}
	#topPartAdminGroup_41 {
		position: absolute;
		width: 370px;
		height: 56px;
		left: 5px;
		top: 74px;
		overflow: visible;
	}
	#topPartAdminGroup_24 {
		position: absolute;
		width: 73px;
		height: 17px;
		left: 272px;
		top: 35px;
		overflow: visible;
	}
	#topPartAdminRectangle_45 {
		fill: rgba(255,255,255,1);
	}
	.topPartAdminRectangle_45 {
		position: absolute;
		overflow: visible;
		width: 46px;
		height: 16px;
		left: 35px;
		top: 13px;
    rotate: 180deg;
	}
	#topPartAdminHR {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 18px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(37,37,37,1);
	}
	#topPartAdminGroup_11 {
		position: absolute;
		width: 370px;
		height: 27px;
		left: 0px;
		top: 0px;
		overflow: visible;
	}
	#topPartAdminWelcome_1 {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#topPartAdminuser_name_lbl {
		left: 87px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 284px;
		height: 27px;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		color: rgba(37,37,37,1);
	}
	#topPartAdminGroup_40 {
		position: absolute;
		width: 104px;
		height: 17px;
		left: 0px;
		top: 34px;
		overflow: visible;
	}
	#topPartAdminRectangle_47 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.topPartAdminRectangle_47 {
		position: absolute;
		overflow: visible;
		width: 17px;
		height: 17px;
		left: 87px;
		top: 0px;
	}
	#topPartAdminShow_Only_HR {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 83px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 13px;
		color: rgba(0,0,0,1);
	}
	#topPartAdminGroup_31 {
		position: absolute;
		width: 27px;
		height: 27px;
		left: 119px;
		top: 29px;
		overflow: visible;
	}
	#topPartAdminEllipse_1 {
		fill: rgba(255,255,255,1);
	}
	.topPartAdminEllipse_1 {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 45px;
		height: 45px;
		left: 0px;
		top: 0px;
	}
	#topPartAdminIcon_material-refresh {
		fill: rgba(0,0,0,1);
	}
	.topPartAdminIcon_material-refresh {
		overflow: visible;
		position: absolute;
		width: 17.735px;
		height: 17.746px;
		left: 5.069px;
		top: 5.029px;
		transform: matrix(1,0,0,1,0,0);
	}
	#topPartAdminGroup_8 {
		position: absolute;
		width: 35px;
		height: 35px;
		right: 6px;
		top: 16px;
		overflow: visible;
	}
	#topPartAdminchat_btn {
		fill: rgba(255,255,255,1);
	}
	.topPartAdminchat_btn {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 53px;
		height: 53px;
		left: 0px;
		top: 0px;
	}
	#topPartAdminchat_lbl {
		position: absolute;
		width: 17px;
		height: 17px;
		left: 9px;
		top: 9px;
		overflow: visible;
	}
	#topPartAdminGroup_10 {
		position: absolute;
		width: 35px;
		height: 35px;
		left: 5px;
		top: 16px;
		overflow: visible;
	}
	#topPartAdminchat_btn_ {
		fill: rgba(255,255,255,1);
	}
	.topPartAdminchat_btn_ {
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 53px;
		height: 53px;
		left: 0px;
		top: 0px;
	}
	#topPartAdminchat_lbl_ {
		position: absolute;
		width: 20px;
		height: 20px;
		left: 8px;
		top: 8px;
		overflow: visible;
	}
	#topPartAdminGroup_26 {
		position: absolute;
		width: 283px;
		height: 34px;
		left: calc((100% - 290px)/2);
		top: 15px;
		overflow: visible;
	}
	#topPartAdminRectangle_43 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.topPartAdminRectangle_43 {
		position: absolute;
		overflow: visible;
		width: 92px;
		height: 34px;
		left: 0px;
		top: 0px;
		font-size: 15px;
		/* padding: 15px; */
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#topPartAdminGroup_25 {
		position: absolute;
		width: 186px;
		height: 34px;
		left: 97px;
		top: 0px;
		overflow: visible;
	}
	#topPartAdminRectangle_44 {
		fill: rgba(255,255,255,1);
		stroke: rgba(112,112,112,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.topPartAdminRectangle_44 {
		position: absolute;
		overflow: visible;
		width: 186px;
		height: 34px;
		left: 0px;
		top: 0px;
		font-size: 15px;
		/* padding: 15px; */
		border: 2px solid darkcyan;
		border-radius:  5px;
	}
	#topPartAdminchat_lbl_bd {
		position: absolute;
		width: 22px;
		height: 22px;
		left: 158px;
		top: 6px;
		overflow: visible;
	}
