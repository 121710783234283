	#Interviews_mainPctoday {
		position: absolute;
		width: 100vw;
		height: calc(100vh - 120px);
		top: 120px;
		background-color: rgba(255,255,255,1);
		overflow: hidden;
		
	}
	@keyframes fadein {
	
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	
	}
	#Interviews_mainPcGroup_12 {
		position: absolute;
		width: calc(100% - 160px);
        height: 100%;
		left: 148px;
		top: 0px;
		overflow: visible;
	}
	#Interviews_mainPcRectangle_4 {
		fill: rgba(255,255,255,1);
	}
	.Interviews_mainPcRectangle_4 {
		filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 100%;
		height: calc(100% - 5px);
		left: 0px;
		top: 0px;
		/* z-index: -1; */
	}
	#Interviews_mainPcGroup_7 {
		position: absolute;
		width: 128px;
		height: calc(100% - 5px);
		left: 8px;
		top: 0px;
		overflow: visible;
	}
	#Interviews_mainPcRectangle_5 {
		fill: rgba(255,255,255,1);
	}
	.Interviews_mainPcRectangle_5 {
		filter: drop-shadow(5px 5px 0px rgba(0, 0, 0, 0.161));
		position: absolute;
		overflow: visible;
		width: 133px;
		height: 100%;
		left: 0px;
		top: 0px;
	}
	#Interviews_mainPcGroup_6 {
	position: absolute;
    width: 120px;
    height: calc(100% - 40px);
    
    top: 22px;
    overflow: visible;
	}
	#Interviews_mainPcTommorow {
		left: 0px;
		top: 66px;
		position: absolute;
		overflow: visible;
		width: 86px;
		white-space: nowrap;
		transition: all 0.30000001192092896s ease-out;
		--web-animation: fadein 0.30000001192092896s ease-out;
		--web-action-type: page;
		--web-action-target: tommorow.html;
		cursor: pointer;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Interviews_mainPcSelected {
		left: 13px;
		top: 135px;
		position: absolute;
		overflow: visible;
		width: 64px;
		white-space: nowrap;
		transition: all 0.30000001192092896s ease-out;
		--web-animation: fadein 0.30000001192092896s ease-out;
		--web-action-type: page;
		--web-action-target: selected.html;
		cursor: pointer;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Interviews_mainPcJoined {
		left: 22px;
		top: 204px;
		position: absolute;
		overflow: visible;
		width: 50px;
		white-space: nowrap;
		transition: all 0.30000001192092896s ease-out;
		--web-animation: fadein 0.30000001192092896s ease-out;
		--web-action-type: page;
		--web-action-target: joined.html;
		cursor: pointer;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Interviews_mainPcEligible {
		left: 19px;
		top: 275px;
		position: absolute;
		overflow: visible;
		width: 55px;
		white-space: nowrap;
		transition: all 0.30000001192092896s ease-out;
		--web-animation: fadein 0.30000001192092896s ease-out;
		--web-action-type: page;
		--web-action-target: eligible.html;
		cursor: pointer;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Interviews_mainPcInvoice {
		left: 19px;
		top: 344px;
		position: absolute;
		overflow: visible;
		width: 54px;
		white-space: nowrap;
		transition: all 0.30000001192092896s ease-out;
		--web-animation: fadein 0.30000001192092896s ease-out;
		--web-action-type: page;
		--web-action-target: invoice.html;
		cursor: pointer;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(0,0,0,1);
	}
	#Interviews_mainPcGroup_5 {
		position: absolute;
		width: 44.5px;
		height: 25.5px;
		left: 24px;
		top: 0px;
		overflow: visible;
	}
	#Interviews_mainPcToday_bv {
		left: 0px;
		top: 0px;
		position: absolute;
		overflow: visible;
		width: 45px;
		white-space: nowrap;
		text-align: left;
		font-family: Segoe UI;
		font-style: normal;
		font-weight: normal;
		font-size: 17px;
		color: rgba(100,149,237,1);
	}
	#Interviews_mainPcLine_2 {
		fill: transparent;
		stroke: rgba(100,149,237,1);
		stroke-width: 1px;
		stroke-linejoin: miter;
		stroke-linecap: butt;
		stroke-miterlimit: 4;
		shape-rendering: auto;
	}
	.Interviews_mainPcLine_2 {
		overflow: visible;
		position: absolute;
		width: 44px;
		height: 1px;
		left: 0.5px;
		top: 25.5px;
		transform: matrix(1,0,0,1,0,0);
	}

	.selectedInterviewTab_pc {
		width:100px;
		color: rgba(100,149,237,1);
		text-align: center;
        cursor: pointer;
		text-decoration: underline;
		transition: 1s;
	}
	/* .selectedInterviewTab_pc:hover {
		text-decoration:underline;
		transition: ease-in linear 2s;
	} */

	.interviewtabs_pc {
		width:100px;
		text-align: center;
        cursor: pointer;
	}