.mediaViewInfo {
  --web-view-name: chat box;
  --web-view-id: chat_box_new;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: chat_box_new;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#chat_box_new {
  position: absolute;
  width: 430px;
  height: 903px;
  left:50%;
  transform: translate(-50%, -2%);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(4,51,67,1) 100%);
  overflow: hidden;
  --web-view-name: chat_box_new;
  --web-view-id: chat_box_new;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
@keyframes fadein {

  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

}


.chat-window .chat-body .message {
  height: 600;
  padding: 10px;
  display: flex;
  
}

.chat-window .chat-body .message .message-content {
  width: auto;
  height: auto;
  min-height: 40px;
  background-color: #43a047;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  overflow-wrap: break-word;
  word-break: break-word;
}

#you {
  justify-content: flex-end;
  width: "100%";
}
#you .message{
  width: "100%";
}
#you .message-body{
  width: "100%";
}
#you .message-content {

  justify-content: flex-end; 
  margin: 2px; 
  width: "80%"; 
  padding:0px 10px 0px 10px;
  background-color: #14c871;

}

#you .message-meta {
 
  justify-content: flex-end;
  margin-left: 5px;
 
}

#other {
  justify-content: flex-start;
  width: "100%";
}

#other .message{
  width: "100%";
}
#other .message-body{
  width: "100%";
}
#other .message-content {
  justify-content: flex-start;
  width: "80%";
  margin: 2px;
  padding:0px 10px 0px 10px;
  background-color: cornflowerblue;
}

#other .message-meta {
  justify-content: flex-start;
  margin-right: 5px;
}

.message-meta #author {
  margin-left: 10px;
  font-weight: bold;
}

.chat-window .chat-body .message .message-meta {
  display: flex;
  font-size: 12px;
}


#Group_5_new {
  position: absolute;
  width: 406.521px;
  height: 774.64px;
  left: 12.479px;
  top: 82.052px;
  overflow: visible;
}

#ChatsPath_new {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.ChatsPath_new {
  overflow: visible;
  position: absolute;
  width: 408.528px;
  height: 777.708px;
  left: 0px;
  top: 10%;
  transform: matrix(1,0,0,1,0,0);
}
#Rectangle_22 {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_22 {
  position: absolute;
  overflow: visible;
  width: 361px;
  height: 78px;
  left: 35.521px;
  top: 677.948px;
}
#Icon_feather-send {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 341.521px;
  top: 703.763px;
  overflow: visible;
}
#Path_5 {
  fill: transparent;
  stroke: rgba(0,0,0,1);
  stroke-width: 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_5 {
  overflow: visible;
  position: absolute;
  width: 20.743px;
  height: 20.743px;
  left: 13.5px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
}
#Path_6 {
  fill: transparent;
  stroke: rgba(0,0,0,1);
  stroke-width: 3px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_6 {
  overflow: visible;
  position: absolute;
  width: 33px;
  height: 33px;
  left: 0px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
}
#user_name_lbl_b {
  left: 28px;
  top: 66px;
  position: absolute;
  overflow: visible;
  width: 376px;
  height: 47px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  color: rgba(37,37,37,1);
}
#Welcome_1_b {
  left: 28px;
  top: 16px;
  position: absolute;
  overflow: visible;
  width: 154px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  color: rgba(37,37,37,1);
}
#chat_btn_b {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  --web-animation: fadein undefineds undefined;
  --web-action-type: page;
  --web-action-target: employee_dashboard.html;
  cursor: pointer;
}
.chat_btn_b {
  position: absolute;
  overflow: visible;
  width: 62px;
  height: 62px;
  left: 357px;
  top: 15px;
}
#chat_lbl_b {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 371px;
  top: 29px;
  overflow: visible;
  transition: all 0.30000001192092896s ease-out;
  --web-animation: fadein 0.30000001192092896s ease-out;
  --web-action-type: page;
  --web-action-target: employee_dashboard.html;
  cursor: pointer;
}
