.menu{
    display: inline-block;
    margin-left:30px;
    color:white;
    font-size: 22px;
    margin-top: 10px;
   font-family: Georgia, 'Times New Roman', Times, serif;
}
.Nav{
    background-color: cornflowerblue;
    height:50px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.active,Nav:hover{
    background:#0137cd;
    transition:.5s;
    font-size: 20px;
    padding:5px;
    width: 100px;
    border-radius: 12px;
}