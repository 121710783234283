#chkVoiceMobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkVoiceMobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 102px;
    top: 1722px;
}

#WPVoiceMobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 125px;
    top: 1718px;
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#chkNonvoiceMobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkNonvoiceMobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 226px;
    top: 1722px;
}

#Non-Voice-mobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 249px;
    top: 1718px;
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#chkSemiVoiceMobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkSemiVoiceMobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 102px;
    top: 1757px;
}

#WPSemiVoiceMobilelbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 125px;
    top: 1753px;
    position: absolute;
    overflow: visible;
    width: 103px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#Employment_Type_Mobile_lbl {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 93px;
    top: 1793px;
    position: absolute;
    overflow: visible;
    width: 201px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
}

#chkFullTimeMobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkFullTimeMobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 100px;
    top: 1837px;
}

#WPFull_TimeMobile_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 123px;
    top: 1833px;
    position: absolute;
    overflow: visible;
    width: 88px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}


#chkPartTimeMobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkPartTimeMobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 224px;
    top: 1837px;
}

#WPPart_Time_Mobile_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 247px;
    top: 1833px;
    position: absolute;
    overflow: visible;
    width: 90px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#chkTemporaryMobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkTemporaryMobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 99px;
    top: 1869px;
}
#WPTemporaryMobilelbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 122px;
    top: 1865px;
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}



#Work_Mode_Mobile_Lbl {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 93px;
    top: 1897px;
    position: absolute;
    overflow: visible;
    width: 132px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
}
#chkWorkfromofficeMobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkWorkfromofficeMobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 102px;
    top: 1942px;
}
#WPOfficeMobileLbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 125px;
    top: 1937px;
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#chkWorkfromhomeMobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkWorkfromhomeMobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 226px;
    top: 1942px;
}

#WPHomeMobilelbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 252px;
    top: 1937px;
    position: absolute;
    overflow: visible;
    width: 53px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#chkHybridMobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkHybridMobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 102px;
    top: 1978px;
}

#WPHybridMobileLbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 125px;
    top: 1973px;
    position: absolute;
    overflow: visible;
    width: 69px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#Shifts_Mobile_lbl {
    opacity: 0.68;
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 96px;
    top: 2018px;
    position: absolute;
    overflow: visible;
    width: 71px;
    height: 33px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    color: rgba(42,14,15,1);
}

#chkDayShift_Mobile_lbl {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkDayShift_Mobile_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 103px;
    top: 2060px;
}

#WPDay_mobile_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 125px;
    top: 2055px;
    position: absolute;
    overflow: visible;
    width: 49px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#chkNightShift_Mobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkNightShift_Mobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 230px;
    top: 2059px;
}

#WPNight_Mobile_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 256px;
    top: 2054px;
    position: absolute;
    overflow: visible;
    width: 5px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#chkRotaionalShift_mobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkRotaionalShift_mobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 103px;
    top: 2097px;
}
#WPRotational_Mobile_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 125px;
    top: 2096px;
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#chkOwnTransport_mobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkOwnTransport_mobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 103px;
    top: 2184px;
}

#WPOwn_Mobile_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 125px;
    top: 2180px;
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}


#chkCompanyTransportDay_mobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkCompanyTransportDay_mobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 227px;
    top: 2185px;
}

#WPCompany_Day_mobile_lbl {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 250px;
    top: 2181px;
    position: absolute;
    overflow: visible;
    width: 134px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}

#chkCompanyTransportNight_Mobile {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.chkCompanyTransportNight_Mobile {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    transform: scale(1.7);
    width: 20px;
    height: 20px;
    left: 103px;
    top: 2219px;
}

#WPCompany_Night {
    filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
    left: 125px;
    top: 2215px;
    position: absolute;
    overflow: visible;
    width: 148px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(28,4,5,1);
}