.content4{

    background-color: rgb(240, 240, 240);
    margin: auto;
    position: relative;
    min-width: 375px;
    max-width: 400px;
    height: auto;
    top:auto;
    left:auto;
}


.content1{

    background-color: rgb(240, 240, 240);
    margin: auto;
    position: relative;
    min-width: 375px;
    max-width: 450px;
    height: auto;
}

.Box12{
    left:30%;
     top: 30px;
}

.BOX1{
    background-color: white;
    left:3%;
} 
.BOX15{
    background-color: white;
    float: right;
} 


.Box14{
    left:0%;
     top: 30px;
}

.Box13{
    bottom: 575px;
    left: 230px;
    height: 30px;
}

.BOXLEFT{
    position: relative;
    left:50px;
    top:0px;
}

.fnt3{
    font-family: 'Times New Roman', Times, serif;
    color:white;
       background-color:cornflowerblue;
       text-align: center;
       
    }

    .div1{
        left: 10px;
    }