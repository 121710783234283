#pop_up.show {
    opacity: 1;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;

    display: none;
    z-index: 0; 
  }
  
  .overlay.show {
    display: block;
  }
  .hidden{
    display: none;
  }
  
  
  /* pop up style */
  #pop_up {
          width: 377px;
          height: 549px;
          background-color: rgba(255,255,255,1);
          overflow: hidden;
          --web-view-name: pop up;
          --web-view-id: pop_up;
          --web-scale-on-resize: true;
          --web-enable-deep-linking: true;
      position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        border-radius: 15px;
        opacity: 0;
        transition: opacity 1s ease-in-out;
        z-index: 1;
      }
      #background_qr_pic {
          position: absolute;
          width: 377px;
          height: 549px;
          left: 0px;
          top: 0px;
          overflow: visible;
          border-radius:  9px;
          background:  #e0e0e0;
          box-shadow:   8px 8px 16px #9f9f9f,-8px -8px 16px #ffffff;
      }
      #Group_Qr_details {
          position: absolute;
          width: 296px;
          height: 245px;
          left: 41px;
          top: 132px;
          overflow: visible;
      }
      #qr_name_ {
          fill: rgba(255,255,255,1);
          stroke: rgba(112,112,112,1);
          stroke-width: 1px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
      }
      .qr_name_ {
          position: absolute;
          overflow: visible;
          width: 296px;
          height: 45px;
          left: 0px;
          top: 0px;
          border: 2px solid darkcyan;
          border-radius: 10px;
          padding: 5px;
      }
      #qr_email_ {
          fill: rgba(255,255,255,1);
          stroke: rgba(112,112,112,1);
          stroke-width: 1px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
      }
      .qr_email_ {
          position: absolute;
          overflow: visible;
          width: 296px;
          height: 45px;
          left: 0px;
          top: 67px;
          border: 2px solid darkcyan;
          border-radius: 10px;
          padding: 5px;
      }
      #phone_number_qr_ {
          fill: rgba(255,255,255,1);
          stroke: rgba(112,112,112,1);
          stroke-width: 1px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
      }
      .phone_number_qr_ {
          position: absolute;
          overflow: visible;
          width: 296px;
          height: 45px;
          left: 0px;
          top: 133px;
          border: 2px solid darkcyan;
          border-radius: 10px;
          padding: 5px;
      }
      #qr_set_password_ {
          fill: rgba(255,255,255,1);
          stroke: rgba(112,112,112,1);
          stroke-width: 1px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
      }
      .qr_set_password_ {
          position: absolute;
          overflow: visible;
          width: 296px;
          height: 45px;
          left: 0px;
          top: 200px;
          border: 2px solid darkcyan;
          border-radius: 10px;
          padding: 5px;
      }
      #register-button {
          position: absolute;
          width: 146px;
          height: 44px;
          left: 116px;
          top: 427px;
          overflow: visible;
      color: white;
      cursor: pointer;
      }
    #login-button {
          position: absolute;
          width: 146px;
          height: 44px;
          left: 116px;
          top: 327px;
          overflow: visible;
      cursor: pointer;
      color: white;
      font-weight: 700;
      }

      #qr_lbl_ql_btn {
          fill: rgba(99,120,253,1);
      }
      .qr_lbl_ql_btn {
          position: absolute;
          overflow: visible;
          width: 146px;
          height: 44px;
          left: 0px;
          top: 0px;
          background-color:  #6378FD;
          border-radius: 10px;
      }

      #Register {
          left: 36px;
          top: 9px;
          position: absolute;
          overflow: visible;
          width: 76px;
          height: 27px;
          text-align: left;
          font-family: Segoe UI;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: rgba(255,255,255,1);
      }
      #Group_51 {
          position: absolute;
          width: 300px;
          height: 27px;
          left: 42px;
          top: 44px;
          overflow: visible;
      }
      #Recruiter {
          left: 215px;
          top: 0px;
          position: absolute;
          overflow: visible;
          width: 86px;
          white-space: nowrap;
          text-align: left;
          font-family: Segoe UI;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          color: rgba(37,37,37,1);
      }
      #I_am_a_ {
          left: 0px;
          top: 0px;
          position: absolute;
          overflow: visible;
          width: 60px;
          white-space: nowrap;
          text-align: left;
          font-family: Segoe UI;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: rgba(37,37,37,1);
      }
      #Rectangle_31 {
          fill: rgba(255,255,255,1);
          stroke: rgba(112,112,112,1);
          stroke-width: 1px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
      }
      .Rectangle_31 {
          position: absolute;
          overflow: visible;
          width: 20px;
          height: 20px;
          left: 63px;
          top: 4px;
          border-radius: 50px;
      }
      #Rectangle_32 {
          fill: rgba(255,255,255,1);
          stroke: rgba(112,112,112,1);
          stroke-width: 1px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
      }
      .Rectangle_32 {
          position: absolute;
          overflow: visible;
          width: 20px;
          height: 20px;
          left: 192px;
          top: 4px;
      }
      #Job_Seeker {
          left: 86px;
          top: 0px;
          position: absolute;
          overflow: visible;
          width: 103px;
          white-space: nowrap;
          text-align: left;
          font-family: Segoe UI;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          color: rgba(37,37,37,1);
      }
      #close {
          position: absolute;
          width: 30px;
          height: 30px;
          left: 334px;
          top: 14px;
          overflow: visible;
      cursor: pointer;
      }
      #Group_top_menu_qr {
          position: absolute;
          width: 347px;
          height: 27px;
          left: 15px;
          top: 84px;
          overflow: visible;
      }
      #I_am_Registered_qr {
          left: 201px;
          top: 0px;
          position: absolute;
          overflow: visible;
          width: 147px;
          white-space: nowrap;
          text-align: left;
          font-family: Segoe UI;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          color: rgba(37,37,37,1);
      }
      #Create_a_Login_qr {
          left: 0px;
          top: 0px;
          position: absolute;
          overflow: visible;
          width: 136px;
          white-space: nowrap;
          text-align: left;
          font-family: Segoe UI;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          color: rgba(37,37,37,1);
      }
      #Rectangle_33 {
          fill: rgba(255,255,255,1);
          stroke: rgba(112,112,112,1);
          stroke-width: 1px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
      }
      .Rectangle_33 {
          position: absolute;
          overflow: visible;
          width: 37px;
          height: 16px;
          left: 149px;
          top: 6px;
      }
      .cssbuttons-io-button {
    background: #6378FD;
    color: white;
    font-family: inherit;
    padding: 0.35em;
    padding-left: 1.2em;
    font-size: 17px;
    font-weight: 500;
    border-radius: 0.9em;
    border: none;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 1.6em -0.6em #6378FD;
    overflow: hidden;
    position: relative;
    height: 2.8em;
    padding-right: 3.3em;
    width: 135px;
      height: 44px;
      left: 116px;
      top: 427px;
      cursor: pointer;
  }
  
  .cssbuttons-io-button .icon {
    background: white;
    margin-left: 1em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.2em;
    border-radius: 0.7em;
    box-shadow: 0.1em 0.1em 0.6em 0.2em #6378FD;
    right: 0.3em;
    transition: all 0.3s;
    
  }
  
  .cssbuttons-io-button:hover .icon {
    width: calc(100% - 0.6em);
  }
  
  .cssbuttons-io-button .icon svg {
    width: 1.1em;
    transition: transform 0.3s;
    color: #6378FD;
  }
  
  .cssbuttons-io-button:hover .icon svg {
    transform: translateX(0.1em);
  }
  
  .cssbuttons-io-button:active .icon {
    transform: scale(0.95);
  }

  .container_qr_ {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    height: 40px;
    margin: auto;
    text-align: center;
    width: 37px;
      height: 16px;
      left: -10px;
      top: -355px;
  }
  
  /** Switch
   -------------------------------------*/
  
   .switch input {
    position: absolute;
    opacity: 0;
  }
  
  /**
   * 1. Adjust this to size
   */
  
  .switch {
    display: inline-block;
    font-size: 20px; /* 1 */
    height: 1em;
    width: 2em;
    background: silver;
    border-radius: 1em;
  
  }
  
  .switch div {
    height: 1em;
    width: 1em;
    border-radius: 1em;
    border: 1px dashed white;
    background: dodgerblue;
    box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3);
    -webkit-transition: all 300ms;
       -moz-transition: all 300ms;
            transition: all 300ms;
  }
  
  .switch input:checked + div {
    -webkit-transform: translate3d(100%, 0, 0);
       -moz-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .fadeInDown {
    animation: fadeInDown 1s;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .fadeIn {
    animation: fadeIn 1s;
  }
  
  
  #Group_login_qr {
          position: absolute;
          width: 296px;
          height: 141px;
          left: 41px;
          top: 165px;
          overflow: visible;
      }
  
    #qr_email_popup_2 {
          fill: rgba(255,255,255,1);
          stroke: rgba(112,112,112,1);
          stroke-width: 1px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
      }
      .qr_email_popup_2 {
          position: absolute;
          overflow: visible;
          width: 296px;
          height: 45px;
          left: 0px;
          top: 0px;
          border: 2px solid darkcyan;
          border-radius: 10px;
          padding: 5px;
      }
      #qr_password_popup_2 {
          fill: rgba(255,255,255,1);
          stroke: rgba(112,112,112,1);
          stroke-width: 1px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
      }
      .qr_password_popup_2 {
          position: absolute;
          overflow: visible;
          width: 296px;
          height: 45px;
          left: 0px;
          top: 67px;
          border: 2px solid darkcyan;
          border-radius: 10px;
          padding: 5px;
      }
      #forgot_Password_qr_popup_2 {
          left: 189px;
          top: 121px;
          position: absolute;
          overflow: visible;
          width: 108px;
          white-space: nowrap;
          text-align: left;
          font-family: Segoe UI;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          color: rgb(96, 56, 243);
          text-decoration: underline;
      }

    .cssbuttons-io-button_popup_2 {
    background: #6378FD;
    color: white;
    font-family: inherit;
    padding: 0.35em;
    padding-left: 25px;
    font-size: 17px;
    font-weight: 500;
    border-radius: 0.9em;
    border: none;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 1.6em -0.6em #6378FD;
    overflow: hidden;
    position: relative;
    height: 2.8em;
    padding-right: 3.3em;
    width: 130px;
      height: 44px;
      left: 116px;
      top: 353px;
  }
  
  .cssbuttons-io-button_popup_2 .icon {
    background: white;
    margin-left: 1em;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.2em;
    width: 2.2em;
    border-radius: 0.7em;
    box-shadow: 0.1em 0.1em 0.6em 0.2em #6378FD;
    right: 0.3em;
    transition: all 0.3s;
    
  }
  
  .cssbuttons-io-button_popup_2:hover .icon {
    width: calc(100% - 0.6em);
  }
  
  .cssbuttons-io-button_popup_2 .icon svg {
    width: 1.1em;
    transition: transform 0.3s;
    color: #6378FD;
  }
  
  .cssbuttons-io-button_popup_2:hover .icon svg {
    transform: translateX(0.1em);
  }
  
  .cssbuttons-io-button_popup_2:active .icon {
    transform: scale(0.95);
  }
 
  .container_popup_2 {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    height: 40px;
    margin: auto;
    text-align: center;
    width: 37px;
      height: 16px;
      left: -10px;
      top: -355px;
  }
  .fadeIn_popup_2 {
    animation: fadeIn 1s;
  }