.mediaViewInfo {
  --web-view-name: schedule interviews;
  --web-view-id: schedule_interviews;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-enable-scale-up: true;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: schedule_interviews;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#schedule_interviews_Modal {
  position: absolute;
  width: 430px;
  height: 2871px;
 
  left: 50%;
  transform: translate(-50%, 0%);
  background-color: rgba(255,255,255,1);
  overflow: hidden;
  --web-view-name: schedule interviews;
  --web-view-id: schedule_interviews;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: width;
  --web-enable-scale-up: true;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
@keyframes fadein {

  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

}
#Update_btn_1 {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Update_btn_1 {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 200px;
  height: 71px;
  left: 121px;
  top: 2778px;
}
#Communication_Rating_txt {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Communication_Rating_txt {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 288px;
  height: 71px;
  left: 90px;
  top: 2230px;
}
#English_Rating_txt {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.English_Rating_txt {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 288px;
  height: 71px;
  left: 90px;
  top: 2161px;
}
#Previous_Feedback_txt {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Previous_Feedback_txt {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 307px;
  height: 112px;
  left: 79px;
  top: 2292px;
}

#ScheduleInterviewBackground {
  fill: rgb(100, 149, 237);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.ScheduleInterviewBackground {
  background-color: cornflowerblue;
  position: absolute;
  overflow: visible;
  width: 539px;
  height: 85px;
  left: -54px;
  top: 0px;
}

#ScheduleTitle_PC {
  fill: url(#ScheduleTitle_PC);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.ScheduleTitle_PC {
  filter: drop-shadow(7px 7px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 400px;
  height: 83px;
  left: 16px;
  top: 42px;
}

#Candidate_Username_ {
  left: 23px;
  top: 61px;
  position: absolute;
  overflow: visible;
  width: 250px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Interview_Rounds_Hr {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Interview_Rounds_Hr {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  /* width: 268px;
  height: 71px; */
  left: 84px;
  top: 231px;
  z-index: 20;
}

.Hr_Rounds_1{
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  height: 60px;
  position:inherit;
  overflow-x: auto;
  top: 221px;
  display: flex;
  margin: 0px 20px 0px 20px;
  width: 390px;
}
.Hr_Rounds_1::-webkit-scrollbar{
  display: none;
}
#Interview_Hr_Names {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Interview_Hr_Names {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 268px;
  height: 71px;
  left: 84px;
  top: 291px;
  z-index: 10;
}

#Interview_Locations {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.Interview_Locations {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 268px;
  height: 45px;
  left: 83px;
  top: 630px;
  z-index: 10;
}

#HR_Name {
  opacity: 0.75;
  left: 169px;
  top: 308px;
  position: absolute;
  overflow: visible;
  width: 69px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}


/* #Line_1 {
  opacity: 0.75;
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_1 {
  overflow: visible;
  position: absolute;
  width: 62px;
  height: 1px;
  left: 172.5px;
  top: 332.5px;
  transform: matrix(1,0,0,1,0,0);
} */

#popUpBackground1 {
  position: absolute;
  width: 1593px;
  height: 2786px;
  left: -1px;
  top: 85px;
  overflow: visible;
}
#popUpBackground2 {
  position: absolute;
  width: 389.968px;
  height: 2680.099px;
  left: 21.016px;
  top: 171.198px;
  overflow: visible;
}


#ScheduleInterviewPointer1 {
  fill: rgba(100, 149, 237);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.ScheduleInterviewPointer1 {
  position: absolute;
  overflow: visible;
  width: 14px;
  height: 14px;
  left: 81px;
  top: 152px;
}
#ScheduleInterviewProgress1 {
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.ScheduleInterviewProgress1 {
  overflow: visible;
  position: absolute;
  width: 234px;
  height: 1px;
  left: 101.5px;
  top: 159px;
  transform: matrix(1,0,0,1,0,0);
}
#ScheduleInterviewApplicantPointer {
  fill: rgba(100, 149, 237);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  transition: all 0.30000001192092896s ease-out;
  --web-animation: fadein 0.30000001192092896s ease-out;
  --web-action-type: page;
  --web-action-target: applicant_details.html;
  cursor: pointer;
}
.ScheduleInterviewApplicantPointer {
  position: absolute;
  overflow: visible;
  width: 14px;
  height: 14px;
  left: 345px;
  top: 152px;
}
#SchedulePath_1 {
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.SchedulePath_1 {
  overflow: visible;
  position: absolute;
  z-index:-1;
  width: 391.969px;
  height: 2678.532px;
  left: 20.016px;
  top: 171.198px;
  transform: matrix(1,0,0,1,0,0);
}
#Interviews_Candidate_City {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Interviews_Candidate_City {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 268px;
  height: 45px;
  left: 75px;
  top: 707px;
}
#Candidate_City_1 {
  opacity: 0.75;
  left: 190px;
  top: 724px;
  position: absolute;
  overflow: visible;
  width: 69px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Line_3 {
  opacity: 0.75;
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_3 {
  overflow: visible;
  position: absolute;
  width: 25px;
  height: 1px;
  left: 189.5px;
  top: 749.07px;
  transform: matrix(1,0,0,1,0,0);
}
#Candidate_Area {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Candidate_Area {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  z-index: 10;
  position: absolute;
  overflow: visible;
  width: 268px;
  height: 71px;
  left: 83px;
  top: 791px;
}
#Candidates_Interview_Area {
  opacity: 0.75;
  left: 190px;
  top: 805.154px;
  position: absolute;
  overflow: visible;
  width: 69px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Line_4 {
  opacity: 0.75;
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_4 {
  overflow: visible;
  position: absolute;
  width: 32px;
  height: 1px;
  left: 189.5px;
  top: 829.846px;
  transform: matrix(1,0,0,1,0,0);
}
#Interview_Location {
  opacity: 0.75;
  left: 151px;
  top: 639.061px;
  position: absolute;
  overflow: visible;
  width: 129px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Line_5 {
  opacity: 0.75;
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_5 {
  overflow: visible;
  position: absolute;
  width: 120px;
  height: 1px;
  left: 154.5px;
  top: 663.846px;
  transform: matrix(1,0,0,1,0,0);
}
#Candidate_Education {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Candidate_Education {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 268px;
  height: 71px;
  left: 50px;
  top: 873px;
}
#Candidate_Education_1 {
  opacity: 0.75;
  left: 169.5px;
  top: 889.442px;
  position: absolute;
  overflow: visible;
  width: 137px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Line_6 {
  opacity: 0.75;
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_6 {
  overflow: visible;
  position: absolute;
  width: 66px;
  height: 1px;
  left: 169.5px;
  top: 911.887px;
  transform: matrix(1,0,0,1,0,0);
}
#Candidate_Languages {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Candidate_Languages {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 350px;
  height: 71px;
  left: 35px;
  top: 954px;
}
#Candidate_Languages_1 {
  opacity: 0.75;
  left: 169.5px;
  top: 969.584px;
  position: absolute;
  overflow: visible;
  width: 137px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Line_7 {
  opacity: 0.75;
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_7 {
  overflow: visible;
  position: absolute;
  width: 72px;
  height: 1px;
  left: 169.5px;
  top: 993.296px;
  transform: matrix(1,0,0,1,0,0);
}
#_Office_location {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 49px;
  top: 578px;
  overflow: visible;
}
#document {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 183px;
  top: 578px;
  overflow: visible;
}
#view {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 286px;
  top: 578px;
  overflow: visible;
}
#Company_Send_location {
  left: 77px;
  top: 583px;
  position: absolute;
  overflow: visible;
  width: 96px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(67,56,175,1);
  text-decoration: underline;
}
#Sending_Company_JD {
  left: 214px;
  top: 583px;
  position: absolute;
  overflow: visible;
  width: 64px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(67,56,175,1);
  text-decoration: underline;
}
#View_Job_bg {
  left: 319px;
  top: 583px;
  position: absolute;
  overflow: visible;
  width: 64px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(67,56,175,1);
  text-decoration: underline;
}

.Resume_Upload_ {
  left: 180px;
  top: 680px;
  position: absolute;
  overflow: visible;
  width: 64px;
  height: 20px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(67,56,175,1);
  text-decoration: underline;
}
#ScheduleInterviewcalendar1 {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 73px;
  top: 117px;
  overflow: visible;
}
#ScheduleInterviewuserPage {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 337px;
  top: 117px;
  overflow: visible;
  transition: all 0.30000001192092896s ease-out;
  --web-animation: fadein 0.30000001192092896s ease-out;
  --web-action-type: page;
  --web-action-target: applicant_details.html;
  cursor: pointer;
}
#Interview_Date_Time {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Interview_Date_Time {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 268px;
  height: 53px;
  left: 78px;
  top: 340px;
}
#Interview_date_and_time {
  opacity: 0.75;
  left: 133.484px;
  top: 373.5px;
  position: absolute;
  overflow: visible;
  width: 161px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Schedule_CompanyName {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Schedule_CompanyName {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 268px;
  height: 71px;
  left: 83px;
  top: 425px;
}
#Schedule_Company_Name {
  opacity: 0.75;
  left: 154px;
  top: 432px;
  position: absolute;
  overflow: visible;
  width: 121px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Line_8 {
  opacity: 0.75;
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_8 {
  overflow: visible;
  position: absolute;
  width: 107px;
  height: 1px;
  left: 154.5px;
  top: 454.5px;
  transform: matrix(1,0,0,1,0,0);
}












#Willing_to_Relocate_Preference {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Willing_to_Relocate_Preference {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  transform: scale(1.7);
  width: 20px;
  height: 20px;
  left: 67px;
  top: 1657px;
}
#Willing_to_Relocate_lbl_ {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  left: 96px;
  top: 1655px;
  position: absolute;
  overflow: visible;
  width: 176px;
  height: 27px;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(28,4,5,1);
}


#English_Rating {
  left: 101px;
  top: 2134px;
  position: absolute;
  overflow: visible;
  width: 150px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Communication_Rating {
  left: 101px;
  top: 2212px;
  position: absolute;
  overflow: visible;
  width: 245px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Previous_Feedback_lbl {
  left: 117px;
  top: 2305px;
  position: absolute;
  overflow: visible;
  width: 195px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Bookmark_Chck {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Bookmark_Chck {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  transform: scale(1.7);
  width: 20px;
  height: 20px;
  left: 56px;
  top: 2541px;
}
#Bookmark_lbl {
  left: 89px;
  top: 2535px;
  position: absolute;
  overflow: visible;
  width: 98px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Schedule_Bookmarkcmts {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Schedule_Bookmarkcmts {
  position: absolute;
  overflow: visible;
  width: 161px;
  height: 65px;
  left: 207px;
  top: 2541px;
}
#Bookmark_Comments {
  left: 215px;
  top: 2543px;
  position: absolute;
  overflow: visible;
  width: 154px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#right-arrow1 {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 323px;
  top: 2136px;
  overflow: visible;
}
#right-arrow1_cw {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 323px;
  top: 2214px;
  overflow: visible;
}
#Open_Next_txt {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Open_Next_txt {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  transform: scale(1.7);
  width: 20px;
  height: 20px;
  left: 56px;
  top: 2637px;
}
#Open_Next_lbl {
  left: 89px;
  top: 2631px;
  position: absolute;
  overflow: visible;
  width: 106px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#WhatsApp_Status_Chck {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.WhatsApp_Status_Chck {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  transform: scale(1.7);
  width: 20px;
  height: 20px;
  left: 56px;
  top: 2685px;
}
#WhatsApp_Status_lbl {
  left: 94px;
  top: 2679px;
  position: absolute;
  overflow: visible;
  width: 180px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Schedule_Available_Job {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Schedule_Available_Job {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 268px;
  height: 71px;
  left: 83px;
  top: 503px;
}
#Company_Available_jobs {
  opacity: 0.75;
  left: 154px;
  top: 520px;
  position: absolute;
  overflow: visible;
  width: 121px;
  height: 20px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: rgba(112,112,112,1);
}
#Line_9 {
  opacity: 0.75;
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_9 {
  overflow: visible;
  position: absolute;
  width: 107px;
  height: 1px;
  left: 154.5px;
  top: 542.973px;
  transform: matrix(1,0,0,1,0,0);
}
#Update_lbl {
  left: 176px;
  top: 2788px;
  position: absolute;
  overflow: visible;
  width: 77px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Refer_Name_txt {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Refer_Name_txt {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 143px;
  height: 71px;
  left: 86px;
  top: 1706px;
}
#Refer_Mobile_txt {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Refer_Mobile_txt {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 143px;
  height: 71px;
  left: 233px;
  top: 1706px;
}
#Refer_Name_lbl {
  left: 92px;
  top: 1719px;
  position: absolute;
  overflow: visible;
  width: 119px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Refer_Mobile_lbl {
  left: 237px;
  top: 1719px;
  position: absolute;
  overflow: visible;
  width: 119px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Candidate_Gender_txt {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Candidate_Gender_txt {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 143px;
  height: 71px;
  left: 86px;
  top: 1778px;
}
#Candidate_Gender_lbl {
  left: 92px;
  top: 1791px;
  position: absolute;
  overflow: visible;
  width: 119px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Add_Referal_Candidate {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Add_Referal_Candidate {
  position: absolute;
  overflow: visible;
  border: 2px solid rgba(112,112,112,1);
  width: 35px;
  height: 49px;
  left: 237px;
  top: 1778px;
}
#Line_10 {
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_10 {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 16px;
  left: 254.5px;
  top: 1794.664px;
  transform: matrix(1,0,0,1,0,0);
}
#Line_11 {
  fill: transparent;
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Line_11 {
  overflow: visible;
  position: absolute;
  width: 16px;
  height: 1px;
  left: 246.5px;
  top: 1802.552px;
  transform: matrix(1,0,0,1,0,0);
}
#Interviewer_Name_txt {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Interviewer_Name_txt {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 288px;
  height: 71px;
  left: 86px;
  top: 1856px;
  z-index: 10;
}
#Interviewer_Name_lbl {
  left: 97px;
  top: 1869px;
  position: absolute;
  overflow: visible;
  width: 181px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Interview_result_txt {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Interview_result_txt {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 288px;
  height: 71px;
  left: 84px;
  top: 1928px;
}
#Applied_lbl {
  left: 95px;
  top: 1942px;
  position: absolute;
  overflow: visible;
  width: 186px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Candidate_Mobile_ {
  left: 280px;
    top: 61px;
    position: absolute;
    overflow: visible;
    width: 124px;
    height: 27px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: rgba(112,112,112,1);
}

#Schedule_CancelBtn {
  fill: rgba(255,255,255,1);
}
.Schedule_CancelBtn {
  position: absolute;
  overflow: visible;
  width: 29px;
  height: 29px;
  left: 398px;
  top: 3px;
}
#Schedule_cancel1 {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 395px;
  top: 0px;
  overflow: visible;
}
#Send_Email_txt {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Send_Email_txt {
  position: absolute;
  overflow: visible;
  width: 108px;
  height: 30px;
  left: 266px;
  top: 2681px;
}
#Send_Email_lbl {
  left: 269px;
  top: 2683px;
  position: absolute;
  overflow: visible;
  width: 104px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Enter_your_feedback_txt {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Enter_your_feedback_txt {
  filter: drop-shadow(5px 3px 6px rgba(0, 0, 0, 0.161));
  position: absolute;
  overflow: visible;
  width: 307px;
  height: 112px;
  left: 69px;
  top: 2408px;
}

#Enter_Your_Feedback_lbl {
  left: 117px;
  top: 2421px;
  position: absolute;
  overflow: visible;
  width: 195px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Next_Round_On_lbl {
  left: 42px;
  top: 1994px;
  position: absolute;
  overflow: visible;
  width: 152px;
  height: 27px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#NextInterviewDateTime {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.NextInterviewDateTime {
  position: absolute;
  overflow: visible;
  width: 164px;
  height: 25px;
  left: 196px;
  top: 1989px;
}
#Suggestion_for_Interview {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Suggestion_for_Interview {
  position: absolute;
  overflow: visible;
  width: 350px;
  height: 25px;
  left: 10%;
  top: 2030px;
}