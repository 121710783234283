.mediaViewInfo {
    --web-view-name: recruiter page;
    --web-view-id: recruiter_page_rec;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: recruiter_page_rec;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#recruiter_page_rec {
    position: absolute;
    width: 909px;
    height: 614px;
    top:60%;
    left:50%;
    transform: translate(-50%,-50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: recruiter page;
    --web-view-id: recruiter_page_rec;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#over_all_bg_rec {
    opacity: 0.68;
    filter: drop-shadow(8px 7px 3px rgba(0, 0, 0, 0.161)) blur(0px);
    position: absolute;
    width: 906.887px;
    height: 615.19px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#BailianStare_pfp_img {
    position: absolute;
    width: 96px;
    height: 100px;
    left: 203.443px;
    top: 2.095px;
    border-radius: 50%;
    overflow: hidden;
}
#detail_bg_rec {
    fill: rgba(255,255,255,1);
}
.detail_bg_rec {
    position: absolute;
    overflow: visible;
    width: 466px;
    height: 391px;
    left: 18.443px;
    top: 112.095px;
}
#name_grp_rec {
    position: absolute;
    width: 205px;
    height: 65px;
    left: 34.577px;
    top: 127.095px;
    overflow: visible;
}
#name_input_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.name_input_rec {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Name_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 46px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#email_grp_rec {
    position: absolute;
    width: 205px;
    height: 65px;
    left: 268.577px;
    top: 127.095px;
    overflow: visible;
}
#email_input_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.email_input_rec {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Email_ID_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#mob_no_grp_rec {
    position: absolute;
    width: 205px;
    height: 65px;
    left: 34.577px;
    top: 202.095px;
    overflow: visible;
}
#mob_input_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.mob_input_rec {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Mobile_Number_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 120px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#gen_grp_rec {
    position: absolute;
    width: 205px;
    height: 65px;
    left: 268.577px;
    top: 202.095px;
    overflow: visible;
}
#gen_input_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.gen_input_rec {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Gender_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 56px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#com_name_grp_rec {
    position: absolute;
    width: 205px;
    height: 65px;
    left: 34.577px;
    top: 277.095px;
    overflow: visible;
}
#com_name_input_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_name_input_rec {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Company_Name_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 122px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#com_state_grp_rec {
    position: absolute;
    width: 205px;
    height: 65px;
    left: 34.577px;
    top: 352.095px;
    overflow: visible;
}
#com_state_input_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_state_input_rec {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Company_State_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 115px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#com_city_grp_rec {
    position: absolute;
    width: 205px;
    height: 66px;
    left: 268.577px;
    top: 351.095px;
    overflow: visible;  
   
}
#com_city_input_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_city_input_rec {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Company_City_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 106px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#com_add_grp_rec {
  
    position: absolute;
    width: 205px;
    height: 65px;
    left: 34.577px;
    top: 427.095px;
    overflow: visible;
}
#com_add_input_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_add_input_rec {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Company_Address_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 137px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#up_and_down_bg_rec {
    fill: rgba(255,255,255,1);
}
.up_and_down_bg_rec {
    position: absolute;
    overflow: visible;
    width: 362px;
    height: 401px;
    left: 520.443px;
    top: 102.095px;
}
#gst_grp {
    position: absolute;
    width: 233.117px;
    height: 66px;
    left: 536.577px;
    top: 197.095px;
    overflow: visible;
}
#GST_No_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#gst_pan_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.gst_pan_input {
    position: absolute;
    overflow: visible;
    width: 233.117px;
    height: 43px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#com_pan_grp {
    position: absolute;
    width: 233.117px;
    height: 66px;
    left: 536.577px;
    top: 274.095px;
    overflow: visible;
}
#Company_PAN_Number_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 176px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#com_pan_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_pan_input {
    position: absolute;
    overflow: visible;
    width: 233.117px;
    height: 43px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#pan_grp {
    position: absolute;
    width: 233.117px;
    height: 66px;
    left: 536.577px;
    top: 351.095px;
    overflow: visible;
}
#User_PAN_Number_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 138px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#pan_number_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.pan_number_input {
    position: absolute;
    overflow: visible;
    width: 233.117px;
    height: 43px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#aadhar_grp_pc {
    position: absolute;
    width: 233.117px;
    height: 65px;
    left: 536.577px;
    top: 427.095px;
    overflow: visible;
}
#User_Aadhaar_Number_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 168px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#aadhar_lbl_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.aadhar_lbl_rec {
    position: absolute;
    overflow: visible;
    width: 233.117px;
    height: 43px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#com_reg_grp_pc {
    position: absolute;
    width: 232px;
    height: 66px;
    left: 537.694px;
    top: 120.095px;
    overflow: visible;
}
#Company_Registration_Number_lb {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 233px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#com_reg_imput {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_reg_imput {
    position: absolute;
    overflow: visible;
    width: 232px;
    height: 43px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#reg_no_upload1 {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 794.694px;
    top: 154.734px;
    overflow: visible;
}
#reg_no_down {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 839.694px;
    top: 154.734px;
    overflow: visible;
}
#gst_up {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 794.694px;
    top: 231.825px;
    overflow: visible;
}
#gst_down {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 839.694px;
    top: 231.825px;
    overflow: visible;
}
#com_pan_up {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 794.694px;
    top: 308.915px;
    overflow: visible;
}
#com_pan_down {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 839.694px;
    top: 308.915px;
    overflow: visible;
}
#pan_up_img {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 794.694px;
    top: 386.005px;
    overflow: visible;
}
#pan_img_rec {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 839.694px;
    top: 386.005px;
    overflow: visible;
}
#upload1_img_rec {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 794.694px;
    top: 463.095px;
    overflow: visible;
}
#download_UAD_img_rec {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 839.694px;
    top: 463.095px;
    overflow: visible;
}
#lbl_rec_btn:hover {
    left: 387.443px;
    top: 526.095px;
}
#lbl_rec_btn {
    position: absolute;
    width: 142px;
    height: 49px;
    left: 382.443px;
    top: 521.095px;
    overflow: visible;
    transition: all .3s ease-out;
}
#Rectangle_10_cb_rec {
    fill: url(#Rectangle_10_cb_rec);
}
.Rectangle_10_cb_rec {
    position: absolute;
    overflow: visible;
    width: 142px;
    height: 49px;
    left: 0px;
    top: 0px;
}
#Update_b {
    left: 47px;
    top: 14.5px;
    position: absolute;
    overflow: visible;
    width: 50px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#pin_grp_pc {
    position: absolute;
    width: 205px;
    height: 65px;
    left: 268.577px;
    top: 427.095px;
    overflow: visible;
}
#Pin_lbl_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Pin_lbl_rec {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Company_PIN_code_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 145px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#com_type_grp_pc {
    position: absolute;
    width: 205px;
    height: 65px;
    left: 268.577px;
    top: 277.095px;
    overflow: visible;
}
#com_typ_lbl_rec {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.com_typ_lbl_rec {
    position: absolute;
    overflow: visible;
    width: 205px;
    height: 43px;
    left: 0px;
    top: 22px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#Company_Type_lbl_rec {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 112px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
