.border{
    background-color: rgb(247, 247, 247);
    margin:15px ;
    border: 1px solid black;
    float: left;
}

.Box12{
    left:30%;
     top: 30px;
}

.BOX1{
    background-color: white;
    left:3%;
} 
.BOX15{
    background-color: white;
    float: right;
} 


.Box14{
    left:0%;
     top: 30px;
}

.Box13{
    bottom: 575px;
    left: 230px;
    height: 30px;
}

.BOXLEFT{
    position: absolute;
    left:150px;
    top:0px;
}

.fnt{
    font-family: 'Times New Roman', Times, serif;
    color:white;
       background-color:cornflowerblue;
       text-align: center;
       /* padding-left:20vh; */
       
    }

    .div1{
        left: 10px;
    }