.mediaViewInfo {
    --web-view-name: activity;
    --web-view-id: activity;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: activity;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#BottmPartInterviewactivity {
    position: absolute;
    width: 100%;
    height: calc(100% - 130px);
    top: 130px;
    background-color: rgba(246,246,246,1);
    overflow: hidden;
    --web-view-name: activity;
    --web-view-id: activity;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
/* #BottmPartInterviewGroup_29 {
    position: absolute;
    width: calc(100% - 35px);
    height: calc(100% - 11px);
    left: 0px;
    top: 10px;
    overflow: visible;
} */
#BottmPartInterviewGroup_36 {
    position: absolute;
    width: 22.937px;
    height: 314px;
    right: 5px;
    top: 31px;
    overflow: visible;
}
#BottmPartInterviewInterview {
    transform: translate(-389.063px, -80px) matrix(1,0,0,1,372,178) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 61px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    text-decoration: underline;
    color:  rgba(7,101,133,1);
}
#BottmPartInterviewVerification {
    transform: translate(-389.063px, -80px) matrix(1,0,0,1,365,275) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
#BottmPartInterviewothers {
    transform: translate(-389.063px, -80px) matrix(1,0,0,1,381,363) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 43px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}

#BottmPartInterviewoth {
    transform: translate(-389.063px, -0px) matrix(1,0,0,1,381,363) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 43px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(37,37,37,1);
}
/* #BottmPartInterviewGroup_35 {
    position: absolute;
    width: 22.937px;
    height: 48.5px;
    left: 0px;
    top: 0px;
    overflow: visible;
} */
#BottmPartInterviewActivity_w {
    transform: translate(-389.063px, -80px) matrix(1,0,0,1,378,94) rotate(90deg);
    transform-origin: center;
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 49px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color:  rgba(37,37,37,1);
}
#BottmPartInterviewLine_1 {
    fill: transparent;
    stroke: rgba(7,101,133,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.BottmPartInterviewLine_1 {
    overflow: visible;
    position: absolute;
    width: 1px;
    height: 48px;
    left: 0px;
    top: 0.5px;
    transform: matrix(1,0,0,1,0,0);
}
#BottmPartInterviewGroup_39 {
    position: absolute;
    width: calc(100% - 35px);
    height: calc(100% - 11px);
    left: 0px;
    top: 10px;
    overflow: visible;
}
#BottmPartInterviewRectangle_46 {
    fill: rgba(255,255,255,1);
    stroke: rgba(7,101,133,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.BottmPartInterviewRectangle_46 {
    position: absolute;
    overflow: visible;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
#BottmPartInterviewGroup_37 {
    position: relative;
    width: 336px;
    height: 24px;
    left: 3px;
    top: 64px;
    overflow: visible;
}
#BottmPartInterviewRoobi {
    left: 0px;
    top: 2px;
    position: absolute;
    overflow: hidden;
    width: 75px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#BottmPartInterviewRectangle_48 {
    fill: rgba(113,122,243,1);
}
.BottmPartInterviewRectangle_48 {
    position: absolute;
    overflow: visible;
    width: 63px;
    height: 24px;
    left: 100px;
    top: 0px;
}
#BottmPartInterviewn_037 {
    left: 184px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#BottmPartInterviewn_510545 {
    left: 240px;
    top: 2px;
    position: absolute;
    overflow: visible;
    width: 87px;
    height: 20px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    color: rgba(0,0,0,1);
}
#BottmPartInterviewMohammed {
    left: 114px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 36px;
    height: 21px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: rgba(0,0,0,1)
}
#BottmPartInterviewspeaker-filled-audio-tool {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 77px;
    top: 2px;
    overflow: visible;
}
#BottmPartInterviewGroup_38 {
    position: absolute;
    width: 225.87px;
    height: 46.5px;
    left: 95.63px;
    top: 7px;
    overflow: visible;
}
#BottmPartInterviewStatus {
    left: 18.37px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 39px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#BottmPartInterviewTime {
    left: 92.37px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 31px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#BottmPartInterviewQueue {
    left: 168.37px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 41px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#BottmPartInterviewLine_2 {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-dasharray: 5;
    stroke-dashoffset: 0;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.BottmPartInterviewLine_2 {
    overflow: visible;
    position: absolute;
    width: 225.87px;
    height: 1px;
    left: 0px;
    top: 46.5px;
    transform: matrix(1,0,0,1,0,0);
}
#BottmPartInterviewn_queue {
    left: 151.37px;
    top: 17px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#BottmPartInterviewn_passed {
    left: 0px;
    top: 17px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
#BottmPartInterviewn_failed {
    left: 72px;
    top: 17px;
    position: absolute;
    overflow: visible;
    width: 75px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    color: rgba(0,0,0,1);
}
