.mediaViewInfo {
  --web-view-name: employee dashboard;
  --web-view-id: employee_dashboard;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: height;
  --web-enable-scale-up: true;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: employee_dashboard;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#employee_dashboard {
  position: absolute;
  width: 430px;
  height: 1175px;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(4,51,67,1) 100%);
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, 0%);
  --web-view-name: employee dashboard;
  --web-view-id: employee_dashboard;
  --web-scale-to-fit: true;
  --web-scale-to-fit-type: height;
  --web-enable-scale-up: true;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
@keyframes fadein {

  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

}
#Welcome_1 {
  left: 25px;
  top: 16px;
  position: absolute;
  overflow: visible;
  width: 206px;
  white-space: nowrap;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 47px;
  color: rgba(37,37,37,1);
}
#dashboard_profile_outer_line {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.dashboard_profile_outer_line {
  position: absolute;
  overflow: visible;
  width: 392px;
  height: 163px;
  left: 28px;
  top: 235px;
}
#call_time_outer_line {
  fill: rgba(255,255,255,1);
  stroke: rgba(146,105,239,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.call_time_outer_line {
  position: absolute;
  overflow: visible;
  width: 163px;
  height: 163px;
  left: 33px;
  top: 429px;
}
#CallIdle_Time_lbl {
  left: 42px;
  top: 497px;
  position: absolute;
  overflow: visible;
  width: 155px;
  height: 28px;
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgba(112,112,112,1);
}
#verified_outer_line {
  fill: rgba(255,255,255,1);
  stroke: rgba(146,105,239,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.verified_outer_line {
  position: absolute;
  overflow: visible;
  width: 163px;
  height: 163px;
  left: 241px;
  top: 429px;
}
#Rectangle_6 {
  fill: rgba(255,255,255,1);
  stroke: rgba(146,105,239,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_6 {
  position: absolute;
  overflow: visible;
  width: 163px;
  height: 163px;
  left: 33px;
  top: 616px;
}
#verification_pending_outer_lin {
  fill: rgba(255,255,255,1);
  stroke: rgba(146,105,239,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.verification_pending_outer_lin {
  position: absolute;
  overflow: visible;
  width: 163px;
  height: 163px;
  left: 241px;
  top: 616px;
}
#Verified_lbl {
  left: 282px;
  top: 497px;
  position: absolute;
  overflow: visible;
  width: 82px;
  height: 28px;
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgba(112,112,112,1);
}
#Verification_Pending_lbl {
  left: 56px;
  top: 673px;
  position: absolute;
  overflow: visible;
  width: 115px;
  height: 55px;
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}
#Rectangle_8 {
  fill: rgba(255,255,255,1);
  stroke: rgba(146,105,239,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_8 {
  position: absolute;
  overflow: visible;
  width: 163px;
  height: 163px;
  left: 33px;
  top: 798px;
}
#Passed_lbl {
  left: 74px;
  top: 863px;
  position: absolute;
  overflow: visible;
  width: 83px;
  height: 28px;
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgba(112,112,112,1);
}
#Rectangle_9 {
  fill: rgba(255,255,255,1);
  stroke: rgba(146,105,239,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_9 {
  position: absolute;
  overflow: visible;
  width: 163px;
  height: 163px;
  left: 241px;
  top: 798px;
}
#Failed_lbl {
  left: 291px;
  top: 866px;
  position: absolute;
  overflow: visible;
  width: 64px;
  height: 28px;
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgba(112,112,112,1);
}
#failed_img {
  position: absolute;
  width: 49px;
  height: 49px;
  left: 299px;
  top: 811px;
  overflow: visible;
}
#passed_img {
  position: absolute;
  width: 49px;
  height: 49px;
  left: 89px;
  top: 811px;
  overflow: visible;
}
#Rectangle_10 {
  fill: rgba(255,255,255,1);
  stroke: rgba(146,105,239,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_10 {
  position: absolute;
  overflow: visible;
  width: 163px;
  height: 163px;
  left: 33px;
  top: 984px;
}
#Called {
  left: 77px;
  top: 1052px;
  position: absolute;
  overflow: visible;
  width: 70px;
  height: 28px;
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgba(112,112,112,1);
}
#Rectangle_11 {
  fill: rgba(255,255,255,1);
  stroke: rgba(146,105,239,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Rectangle_11 {
  position: absolute;
  overflow: visible;
  width: 163px;
  height: 163px;
  left: 241px;
  top: 984px;
}
#Available {
  left: 275px;
  top: 1053px;
  position: absolute;
  overflow: visible;
  width: 98px;
  height: 26px;
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgba(112,112,112,1);
}
#customer-service1 {
  position: absolute;
  width: 49px;
  height: 49px;
  left: 87px;
  top: 1000px;
  overflow: visible;
}
#available {
  position: absolute;
  width: 49px;
  height: 49px;
  left: 298px;
  top: 1000px;
  overflow: visible;
}
#time-call_img {
  position: absolute;
  width: 49px;
  height: 49px;
  left: 90px;
  top: 440px;
  overflow: visible;
}
#verified_img {
  position: absolute;
  width: 49px;
  height: 49px;
  left: 299px;
  top: 440px;
  overflow: visible;
}
#verification_failed_img {
  position: absolute;
  width: 49px;
  height: 49px;
  left: 90px;
  top: 624px;
  overflow: visible;
}
#verification_pending_img {
  position: absolute;
  width: 49px;
  height: 49px;
  left: 299px;
  top: 624px;
  overflow: visible;
}
#Current_Candidate_lbl {
  left: 27px;
    top: 257px;
    position: absolute;
    overflow: visible;
    width: 395px;
    height: 40px;
    text-align: center;
    font-family: Times New Roman;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    color: rgba(37,37,37,1);
}
#chat_btn {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
  transition: all 0.30000001192092896s ease-out;
  --web-animation: fadein 0.30000001192092896s ease-out;
  --web-action-type: page;
  --web-action-target: chat_box.html;
  cursor: pointer;
}
.chat_btn {
  position: absolute;
  overflow: visible;
  width: 62px;
  height: 62px;
  left: 357px;
  top: 15px;
}
#chat_lbl {
  position: absolute;
  width: 35px;
  height: 35px;
  left: 371px;
  top: 29px;
  overflow: visible;
  transition: all 0.30000001192092896s ease-out;
  --web-animation: fadein 0.30000001192092896s ease-out;
  --web-action-type: page;
  --web-action-target: chat_box.html;
  cursor: pointer;
}
#current_candidate_img {
  position: absolute;
  width: 52px;
  height: 52px;
  left: 82px;
  top: 321px;
  overflow: visible;
}
#call_time_val {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.call_time_val {
  position: absolute;
  overflow: visible;
  width: 117px;
  height: 37px;
  left: 56px;
  top: 536px;
}
#verified_val {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.verified_val {
  position: absolute;
  overflow: visible;
  width: 117px;
  height: 37px;
  left: 266px;
  top: 536px;
}
#verification_failed_val {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.verification_failed_val {
  position: absolute;
  overflow: visible;
  width: 117px;
  height: 37px;
  left: 56px;
  top: 729px;
}
#verification_pending_val {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.verification_pending_val {
  position: absolute;
  overflow: visible;
  width: 117px;
  height: 37px;
  left: 266px;
  top: 729px;
}
#passed_val {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.passed_val {
  position: absolute;
  overflow: visible;
  width: 117px;
  height: 37px;
  left: 56px;
  top: 904px;
}
#failed_val {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.failed_val {
  position: absolute;
  overflow: visible;
  width: 117px;
  height: 37px;
  left: 266px;
  top: 904px;
}
#called_val {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.called_val {
  position: absolute;
  overflow: visible;
  width: 117px;
  height: 37px;
  left: 56px;
  top: 1091px;
}
#available_val {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.available_val {
  position: absolute;
  overflow: visible;
  width: 117px;
  height: 37px;
  left: 266px;
  top: 1091px;
}
#user_name_lbl {
  left: 25px;
  top: 79px;
  position: absolute;
  overflow: visible;
  width: 376px;
  height: 63px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 47px;
  color: rgba(37,37,37,1);
}
#call_only_chk {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.call_only_chk {
  position: absolute;
  overflow: visible;
  transform: scale(2);
  width: 45px;
  height: 45px;
  left: 19px;
  top: 180px;
}
#call_only_lbl {
  left: 73px;
  top: 153px;
  position: absolute;
  overflow: visible;
  width: 143px;
  height: 53px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  color: rgba(37,37,37,1);
}
#call_only_dropdown {
  fill: rgba(255,255,255,1);
  stroke: rgba(112,112,112,1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.call_only_dropdown {
  position: absolute;
  overflow: visible;
  width: 197px;
  height: 46px;
  left: 216px;
  top: 165px;
}
#n_ {
  
  left: 74px;
    top: 538px;
    position: absolute;
    overflow: visible;
    width: 84px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(37,37,37,1);
}
#n__bo {
  left: 278px;
  top: 538px;
  position: absolute;
  overflow: visible;
  width: 96px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  color: rgba(37,37,37,1);
}
#n__bp {
  left: 67px;
    top: 731px;
    position: absolute;
    overflow: visible;
    width: 94px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(37,37,37,1);
}
#n__bq {
  left: 279px;
  top: 731px;
  position: absolute;
  overflow: visible;
  width: 94px;
  white-space: nowrap;
  text-align: center;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  color: rgba(37,37,37,1);
}
#n__br {
  left: 69px;
    top: 906px;
    position: absolute;
    overflow: visible;
    width: 94px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(37,37,37,1);
}
#n__bs {
  left: 279px;
    top: 906px;
    position: absolute;
    overflow: visible;
    width: 94px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    color: rgba(37,37,37,1);
}
#n__bt {
  left: 69px;
    top: 1093px;
    position: absolute;
    overflow: visible;
    width: 94px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgba(37,37,37,1);
}
#n__bu {
  left: 275px;
    top: 1093px;
    position: absolute;
    overflow: visible;
    width: 96px;
    white-space: nowrap;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    color: rgba(37,37,37,1);
}
#telephone {
  position: absolute;
  width: auto;
  height: 52px;
  left: 189px;
  top: 321px;
  overflow: visible;
}
.stopComments_ {
  position: absolute;
  width: auto;
  height: 52px;
  left: 189px;
  top: 341px;
  overflow: visible;
}
#whatsapp_Dashboard {
  position: absolute;
  width: 52px;
  height: 52px;
  left: 290px;
  top: 321px;
  overflow: visible;
}
#call_only_lbl_bx {
  left: 244px;
  top: 153px;
  position: absolute;
  overflow: visible;
  width: 146px;
  height: 53px;
  text-align: left;
  font-family: Segoe UI;
  font-style: normal;
  font-weight: normal;
  font-size: 35px;
  color: rgba(37,37,37,1);
}
#Verification_Failed_lbl {
  left: 263px;
  top: 673px;
  position: absolute;
  overflow: visible;
  width: 118px;
  height: 55px;
  text-align: center;
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: rgba(112,112,112,1);
}