.mediaViewInfo {
	--web-view-name: Footer app candi pc;
	--web-view-id: Footer_app_candi_pc;
	--web-scale-on-resize: true;
	--web-enable-deep-linking: true;
}
:root {
	--web-view-ids: Footer_app_candi_pc;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	border: none;
}
#Footer_app_candi_pc {
	position: fixed;
	width: 100%;
	height: 40px;
	bottom: 0;
	z-index: 10;
	background-color: rgba(255,255,255,1);
	overflow:hidden;
	--web-view-name: Footer app candi pc;
	--web-view-id: Footer_app_candi_pc;
	--web-scale-on-resize: true;
	--web-enable-deep-linking: true;
}
#footer_app_pc_candi {
	position: absolute;
	width: 100%;
	height: 64px;
	left: 0px;
	top: 0px;
	overflow: visible;
}
#footer_vacan {
	fill: rgba(255,75,83,1);
}
.footer_vacan {
	position: absolute;
	overflow: visible;
	width: 100%;
	height: 64px;
	left: 0px;
	top: 0px;
}
#All_rights_reserved__2022_Oper {
	left: 7%;
	top: 8px;
	position: absolute;
	overflow: visible;
	width: 491px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	color: rgba(255,255,255,1);
}
#linkedin_vacan {
	position: absolute;
	width: 30px;
	height: 30px;
	left: 87%;
	top: 8px;
	overflow: visible;
}
#whatapp_btn_img_vacan {
	position: absolute;
	width: 28px;
	height: 28px;
	left: 90%;
	top: 8px;
	overflow: visible;
}
#term_and_pol_grp {
	position: absolute;
	width: 300px;
	height: 24.5px;
	left: 53%;
	top: 8px;
	overflow: visible;
}
#Terms_and_Conditions_lbl_vac {
	left: 0px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 173px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	color: rgba(255,255,255,1);
}
#Private_Policy_lbl_vac {
	left: 195px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 106px;
	white-space: nowrap;
	text-align: left;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	color: rgba(255,255,255,1);
}
#Line_2_vacan {
	fill: transparent;
	stroke: rgba(255,255,255,1);
	stroke-width: 1px;
	stroke-linejoin: miter;
	stroke-linecap: butt;
	stroke-miterlimit: 4;
	shape-rendering: auto;
}
.Line_2_vacan {
	overflow: visible;
	position: absolute;
	width: 1px;
	height: 24px;
	left: 183.5px;
	top: 0.5px;
	transform: matrix(1,0,0,1,0,0);
}