.mediaViewInfo {
    --web-view-name: top nav bar rec;
    --web-view-id: top_nav_bar_rec;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: top_nav_bar_rec;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#top_nav_bar_rec {
    position: fixed;
    width: 100%;
    height: 95px;
    top:0px;
    z-index: 1;
    display: flex;
    overflow: visible;
    background-color: rgba(255,255,255,1);
    --web-view-name: top nav bar rec;
    --web-view-id: top_nav_bar_rec;
    --web-scale-to-fit: true;
    --web-scale-to-fit-type: width;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bizibeesjpg_img_app_can_mob {
    position: absolute;
    width: 136px;
    height: 49.854px;
    left: 215px;
    top: 16px;
    overflow: visible;
}
body
{
margin: 0;
padding: 0;

/* make it look decent enough */

font-family: "Avenir Next", "Avenir", sans-serif;
}

#menuToggle
{
display: block;
position: fixed;
width: 40px;
height: 40px;
left: 34px;
top: 25px;

z-index: 1;

-webkit-user-select: none;
user-select: none;
}

#menuToggle li
{
text-decoration: none;
color: #232323;

transition: color 0.3s ease;
}

#menuToggle li:hover
{
color: #ff4f4f;
}


#menuToggle input
{
display: block;
width: 40px;
height: 32px;
position: absolute;
top: -7px;
left: -5px;

cursor: pointer;

opacity: 0; /* hide this */
z-index: 2; /* and place it over the hamburger */

-webkit-touch-callout: none;
}

/*
* Just a quick hamburger
*/
#menuToggle span
{
display: block;
width: 33px;
height: 4px;
margin-bottom: 5px;
position: relative;

background: #6495ED;
border-radius: 3px;

z-index: 1;

transform-origin: 4px 0px;

transition: transform  0.5s cubic-bezier(0.77,0.2,0.05,1.0);
background: 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
opacity: 0.55s ease;
}

#menuToggle span:first-child
{
transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
transform-origin: 0% 100%;
}

/* 
* Transform all the slices of hamburger
* into a crossmark.
*/
#menuToggle input:checked ~ span
{
opacity: 1;
transform: rotate(45deg) translate(-2px, -1px);
background: rgba(255,75,83,1);
}

/*
* But let's hide the middle one.
*/
#menuToggle input:checked ~ span:nth-last-child(3)
{
opacity: 0;
transform: rotate(0deg) scale(0.2, 0.2);
}

/*
* Ohyeah and the last one should go the other direction
*/
#menuToggle input:checked ~ span:nth-last-child(2)
{
transform: rotate(-45deg) translate(0, -1px);
}

/*
* Make this absolute positioned
* at the top left of the screen
*/
#menu
{
position: absolute;
width: 230px;
margin: -100px 0 0 -50px;
padding: 50px;
padding-top: 125px;
height: 100vh;
background: #ededed;
list-style-type: none;
-webkit-font-smoothing: antialiased;
/* to stop flickering of text in safari */
transform-origin: 0% 0%;
transform: translate(-100%, 0);
transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
background: rgba( 255, 255, 255, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
backdrop-filter: blur( 10px );
-webkit-backdrop-filter: blur( 10px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}

#menu li
{
padding: 10px 0;
font-size: 22px;
}
#menuToggle input:checked ~ ul
{
transform: none;
}
