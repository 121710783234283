.mediaViewInfo {
    --web-view-name: Web 1366 – 1;
    --web-view-id: Web_1366__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: Web_1366__1;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#sendEmail {
    position: absolute;
    width: 1366px;
    height: 768px;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: Web 1366 – 1;
    --web-view-id: Web_1366__1;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bizibeesLogo {
    position: absolute;
    width: 225px;
    height: 83px;
    left: 624px;
    top: 75px;
    overflow: visible;
}
#Enter_the_email_address_associ {
    left: 496px;
    top: 246px;
    position: absolute;
    overflow: visible;
    width: 519px;
    height: 54px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Email {
    left: 496px;
    top: 319px;
    position: absolute;
    overflow: visible;
    width: 54px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
/* #Rectangle_1 {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_1 {
    position: absolute;
    overflow: visible;
    width: 480px;
    height: 76px;
    left: 496px;
    top: 368px;
} */
#Rectangle_2_l {
    fill: url(#Rectangle_2_l);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Rectangle_2_l {
    filter: drop-shadow(10px 10px 6px rgba(0, 0, 0, 0.161));
    position: absolute;
    overflow: visible;
    width: 499px;
    height: 26px;
    left: 496px;
    top: 500px;
}
#Dont_have_an_account_Sign_up {
    left: 593px;
    top: 620px;
    position: absolute;
    overflow: visible;
    width: 287px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Continue {
    left: 495px;
    top: 496px;
    position: absolute;
    overflow: visible;
    width: 125px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(112,112,112,1);
}
#Back_Btn {
    left: 880px;
    top: 550px;
    position: absolute;
    overflow: visible;
    width: 125px;
    height: 20px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(112,112,112,1);
}
#Enter_email_address {
    opacity: 0.51;
    left: 499px;
    top: 393px;
    position: absolute;
    overflow: visible;
    width: 175px;
    height: 27px;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(112,112,112,1);
}
#Line_1 {
    opacity: 0.51;
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Line_1 {
    overflow: visible;
    position: absolute;
    width: 174px;
    height: 1px;
    left: 523.5px;
    top: 420.5px;
    transform: matrix(1,0,0,1,0,0);
}
#EmailPath_1 {
    fill: rgba(62,71,202,1);
}
.EmailPath_1 {
    overflow: visible;
    position: absolute;
    width: 260.616px;
    height: 160.165px;
    left: 1105.384px;
    top: 26.94px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_2 {
    fill: rgba(98,181,225,1);
}
.Path_2 {
    overflow: visible;
    position: absolute;
    width: 137.074px;
    height: 83.79px;
    left: 1230.339px;
    top: -8.122px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_3 {
    fill: rgba(62,71,202,1);
}
.Path_3 {
    overflow: visible;
    position: absolute;
    width: 284.045px;
    height: 160.202px;
    left: 0.599px;
    top: 35.025px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_4 {
    fill: rgba(98,181,225,1);
}
.Path_4 {
    overflow: visible;
    position: absolute;
    width: 149.499px;
    height: 84.597px;
    left: -1.31px;
    top: -0.808px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_5 {
    fill: rgba(213,213,213,1);
}
.Path_5 {
    overflow: visible;
    position: absolute;
    width: 95.817px;
    height: 59.109px;
    left: 1277.994px;
    top: 95.944px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_6 {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 0.25px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Path_6 {
    overflow: visible;
    position: absolute;
    width: 129.08px;
    height: 92.838px;
    left: 1171.409px;
    top: 155.054px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_7 {
    fill: rgba(229,228,228,1);
}
.Path_7 {
    overflow: visible;
    position: absolute;
    width: 214.177px;
    height: 131.242px;
    left: 0px;
    top: 102.6px;
    transform: matrix(1,0,0,1,0,0);
}
#Path_8 {
    fill: transparent;
    stroke: rgba(112,112,112,1);
    stroke-width: 0.25px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.Path_8 {
    overflow: visible;
    position: absolute;
    width: 155.51px;
    height: 98.719px;
    left: 142.621px;
    top: 206.739px;
    transform: matrix(1,0,0,1,0,0);
}
#Reset_Password {
    left: 496px;
    top: 175px;
    position: absolute;
    overflow: visible;
    width: 481px;
    height: 40px;
    text-align: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    color: rgba(112,112,112,1);
}

