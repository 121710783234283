table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    /* border: 1px solid #dddddd; */
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    /* background-color: #dddddd; */
  }

  .fnt2{
    font-size: x-large;
    font-family: 'Times New Roman', Times, serif;
    color:white;
    background-color:#14c871;
    height: 35px;
    }

    .content6{

      background-color: white;
      margin: auto;
      position: relative;
      min-width: 325px;
      max-width: 380px;
      height: auto;
  }

  .dashboard_pc_module {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 120px);
    top: 120px;
    
  }

  .dashboard_mob_module {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 60px);
    top: 60px;
    
  }

  .timeout{

    background-color: rgb(240, 240, 240);
    margin: auto;
    position: relative;
    min-width: 375px;
    max-width: 495px;
    height: auto;
    top:0;
    left:0;
}

  .content2{
    background-color: white;
}

.joinChatContainer input {
  width: 60px;
  height: 40px;
  margin: 7px;
  border: 2px solid #43a047;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
}

.chat-window .chat-body .message {
  height: auto;
  padding: 10px;
  display: flex;
  
}

.chat-window .chat-body .message .message-content {
  width: auto;
  height: auto;
  min-height: 40px;
  background-color: #43a047;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 5px;
  padding-left: 5px;
  overflow-wrap: break-word;
  word-break: break-word;
}

#you {
  justify-content: flex-end;
  width: "100%";
}
#you .message{
  width: "100%";
}
#you .message-body{
  width: "100%";
}
#you .message-content {

  justify-content: flex-end; 
  margin: 2px; 
  width: "80%"; 
  padding:0px 10px 0px 10px;
  background-color: #14c871;

}

#you .message-meta {
 
  justify-content: flex-end;
  margin-left: 5px;
 
}

#other {
  justify-content: flex-start;
  width: "100%";
}

#other .message{
  width: "100%";
}
#other .message-body{
  width: "100%";
}
#other .message-content {
  justify-content: flex-start;
  width: "80%";
  margin: 2px;
  padding:0px 10px 0px 10px;
  background-color: cornflowerblue;
}

#other .message-meta {
  justify-content: flex-start;
  margin-right: 5px;
}

.message-meta #author {
  margin-left: 10px;
  font-weight: bold;
}

.chat-window .chat-body .message .message-meta {
  display: flex;
  font-size: 12px;
}

select.dropdown {
  border: 1px solid black;
  padding: 4px;
}

input.inputfield {
  border: 1px solid black;
  padding: 4px;
}