.mediaViewInfo {
    --web-view-name: office location;
    --web-view-id: office_location;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
:root {
    --web-view-ids: office_location;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
}
#office_location {
    position: absolute;
    width: 349px;
    height: 615px;
        border-radius: 20px;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
    background-color: rgba(255,255,255,1);
    overflow: hidden;
    --web-view-name: office location;
    --web-view-id: office_location;
    --web-scale-on-resize: true;
    --web-enable-deep-linking: true;
}
#bdr_design_pfp_1 {
    opacity: 0.53;
    position: absolute;
    width: 349px;
    height: 615px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#bg_design_grp {
    position: absolute;
    width: 349.05px;
    height: 146.684px;
    left: 0px;
    top: 0px;
    overflow: visible;
}
#bg_design_2 {
    fill: rgba(170,207,255,1);
}
.bg_design_2 {
    overflow: visible;
    position: absolute;
    width: 349.05px;
    height: 146.684px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#bg_design_1 {
    fill: rgba(170,207,255,1);
}
.bg_design_1 {
    overflow: visible;
    position: absolute;
    width: 349.05px;
    height: 146.684px;
    left: 0px;
    top: 0px;
    transform: matrix(1,0,0,1,0,0);
}
#pfp_behind_circle {
    fill: rgba(255,255,255,1);
}
.pfp_behind_circle {
    position: absolute;
    overflow: visible;
    width: 100px;
    height: 100px;
    left: 125px;
    top: 26px;
}
#profile_pfp_img {
    position: absolute;
    width: 80px;
    height: 80px;
    left: 134.913px;
    top: 35.004px;
    overflow: hidden;
    box-shadow:   box-shadow;
    background: #ffff;
    border-radius: 50%;
}
#active_lbl {
    left: 175.286px;
    top: 132.996px;
    position: absolute;
    overflow: visible;
    width: 55px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#change_pass_btn_grp:hover {
    top: 539px;
}
#change_pass_btn_grp {
    position: absolute;
    width: 123px;
    height: 41px;
    left: 113px;
    top: 534px;
    overflow: visible;
    border-radius: 20px;
    transition: all .3s ease-out;
}
#passs_bdr_btn {
    fill: rgba(170,207,255,1);
}
.passs_bdr_btn {
    position: absolute;
    overflow: visible;
    width: 123px;
    height: 41px;
    left: 0px;
    top: 0px;
}
#Change_Password_lbl {
    left: 50.914px;
    top: 12.28px;
    position: absolute;
    overflow: visible;
    width: 37px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: rgba(0,0,0,1);
}
#active_chkbx {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.active_chkbx {
    position: absolute;
    overflow: visible;
    width: 38px;
    height: 20px;
    left: 120.714px;
    top: 130.996px;
}
#state_grp_prof {
    position: absolute;
    width: 148px;
    height: 63px;
    left: 23px;
    top: 167.004px;
    overflow: visible;
}
#state_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 39px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#state_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.state_input_pfp {
    position: absolute;
    overflow: visible;
    width: 148px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#city_grp_prof {
    position: absolute;
    width: 148px;
    height: 63px;
    left: 190px;
    top: 166.996px;
    overflow: visible;
}
#city_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 30px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#city_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.city_input_pfp {
    position: absolute;
    overflow: visible;
    width: 148px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#company_grp {
    position: absolute;
    width: 148px;
    height: 63px;
    left: 23px;
    top: 240.004px;
    overflow: visible;
}
#company_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 73px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#company_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.company_input_pfp {
    position: absolute;
    overflow: visible;
    width: 148px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#office_grp {
    position: absolute;
    width: 148px;
    height: 63px;
    left: 190px;
    top: 239.996px;
    overflow: visible;
}
#office_lbl_pfp {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 45px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#office_input_pfp {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.office_input_pfp {
    position: absolute;
    overflow: visible;
    width: 148px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#latitiude_grp {
    position: absolute;
    width: 148px;
    height: 63px;
    left: 23px;
    top: 313.004px;
    overflow: visible;
}
#latitude_lbl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 62px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#latitude_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.latitude_input {
    position: absolute;
    overflow: visible;
    width: 148px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#long_grp {
    position: absolute;
    width: 148px;
    height: 63px;
    left: 190px;
    top: 312.996px;
    overflow: visible;
}
#long_lbl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 77px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#long_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.long_input {
    position: absolute;
    overflow: visible;
    width: 148px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#location_grp {
    position: absolute;
    width: 315px;
    height: 63px;
    left: 23px;
    top: 390px;
    overflow: visible;
}
#location_lbl {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 145px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#location_input {
    fill: rgba(255,255,255,1);
    stroke: rgba(112,112,112,1);
    stroke-width: 1px;
    stroke-linejoin: miter;
    stroke-linecap: butt;
    stroke-miterlimit: 4;
    shape-rendering: auto;
}
.location_input {
    position: absolute;
    overflow: visible;
    width: 315px;
    height: 40px;
    left: 0px;
    top: 23px;
    font-size: 15px;
    border: 2px solid lightblue;
    border-radius: 10px;
    padding: 5px;
}
#close_off_loc_ {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 311px;
    top: 13px;
    cursor: pointer;
    overflow: visible;
}

#location_grp_bn {
    position: absolute;
    width: 149px;
    height: 22px;
    left: 25.5px;
    top: 466px;
    overflow: visible;
}
#location_lbl_bo {
    left: 0px;
    top: 0px;
    position: absolute;
    overflow: visible;
    width: 150px;
    white-space: nowrap;
    text-align: left;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    color: rgba(0,0,0,1);
}
#upload1_img_rec_popup {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 201.5px;
    top: 465px;
    overflow: visible;
}
#download1_img_rec {
    position: absolute;
    width: 25px;
    height: 25px;
    left: 253.5px;
    top: 465px;
    overflow: visible;
}